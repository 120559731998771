import Navigo from 'navigo';

// getElementById wrapper
function getId(id) {
    return document.getElementById(id);
}
// asyncrhonously fetch the html template partial from the file directory, then set its contents to the html of the parent element
function loadHTML(url, id) {
    // alert(url+' / '+id);
    let req = new XMLHttpRequest();
    req.open('GET', url);
    req.send();
    req.onload = () => {
        getId(id).innerHTML = req.responseText;
    };
}
function setPageAfterload (link) {
    console.log(link);
    /*
    // Remove active state to all sidebar nav links then...
    $("#layoutSidenav_nav .sidenav a.nav-link").not('[data-toggle="modal"]').each(function() {
        $(this).removeClass("active");
    });
    // Add active state to sidebar nav links
    const targetAnchors = document.body.querySelectorAll('[href="/' + link + '"].nav-link');
    targetAnchors.forEach(targetAnchor => {
        let parentNode = targetAnchor.parentNode;
        while (parentNode !== null && parentNode !== document.documentElement) {
            if (parentNode.classList.contains('collapse')) {
                parentNode.classList.add('show');
                const parentNavLink = document.body.querySelector(
                    '[data-bs-target="#' + parentNode.id + '"]'
                );
                parentNavLink.classList.remove('collapsed');
                parentNavLink.classList.add('active');
            }
            parentNode = parentNode.parentNode;
        }
        targetAnchor.classList.add('active');
    });
    if(link=='index.html') $('.nav-link-def-route').addClass('active').closest('.collapse').addClass('show').prev('.nav-link').removeClass('collapsed').addClass('active');
    // Now doing things like binding UI Actcions for this page...
    // Connected or not
    if ($.localStorage.getItem('pass') == "OK") {
        $('#header-not-yet-connected').hide();
        $('#header-connected').show();
        if ($.localStorage.getItem('admin') == "true") {
            $('#header-connected-admin').show();
        }
        else {
            switch ($.localStorage.getItem('type')) {
                case '0':
                    $('#header-connected-transporteurs').show();
                    break;
                case '1':
                    $('#header-connected-pilotes').show();
                    break;
                case '2':
                    $('#header-connected-stores').show();
                    break;
                default:
                    alert("Connecté sans type !!");
                    App.logMeOut();
            }
        }
    } else {
        $('#header-connected').hide();
        $('#header-not-yet-connected').show();
    }
    */
    $.sessionStorage.setItem('myPage', link); // Change current page to session
    App.dotNavScroll(); // Dot Navigation with Scroll...
    App.refreshSmoothScroll(); // Smooth Scroll to div...
    // Add the following code if you want the name of the file appear on select
    $(".custom-file-input").on("change", function(e) {
        //const fileName = $(this).val().split("\\").pop();
        let fileName = "";
        //e.target.files.forEach(function(item){ fileName += '"'+item.name+'"';});
        //alert(e.target.files[0].name);
        for (var i = 0; i < e.target.files.length; i++) {
            fileName += '"'+e.target.files[i].name+'" ';
        }
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });
}
// Now the router...
const myDomain = window.location.origin; // https://my.domain
const rootUrl = (myDomain.indexOf('localhost')!==-1) ? '/' : '/'; // if online version is in a subfolder
const router = new Navigo(rootUrl); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
// use #! to hash
// const router = new Navigo(null, true, '#!'); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
router.hooks({
    // Ensure new data-navigo links work.
    after: function() {
        router.updatePageLinks();
    },
});
const routerContainer = document.getElementById('routerContainer');
const routerContainerExist = (routerContainer) ? true : false;
if(!routerContainerExist) document.location.href = './';
// Direct To Some Page...
// const goTo = App.urlParam('goto', document.URL); // Where are we going
// if(goTo == 'page') router.navigate("/page");

router.on({
    // 'routerContainer' is the id of the div element inside which we render the HTML
    '/': () => {
        fetch('/home.html').then(function (response) {
            return response.text();
        }).then(function (html) {
            routerContainer.innerHTML = html;
            setPageAfterload('home');
            App.setHomePage();
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/home': () => {
        fetch('/home.html').then(function (response) {
            return response.text();
        }).then(function (html) {
            routerContainer.innerHTML = html;
            setPageAfterload('home');
            App.setHomePage();
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/bourse': () => {
        console.warn('IN Router Bourse !');
        fetch('/bourse.html').then(function (response) {
            return response.text();
        }).then(function (html) {
            routerContainer.innerHTML = html;
            setPageAfterload('bourse');
            App.setBoursePage();
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/manager': () => {
        fetch('/manager.html').then(function (response) {
            return response.text();
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setManagerPage();
            setPageAfterload('manager');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/agenda': () => {
        fetch('/agenda.html').then(function (response) {
            return response.text();
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setCalendarPage();
            setPageAfterload('agenda');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/admin': () => {
        fetch('/manager.html').then(function (response) {
            return response.text();
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setManagerPage();
            setPageAfterload('manager');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    /*
    '/clients/:id': ({data}) => {
        fetch('/client.html').then(function (response) {
            return response.text();
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setClientPage(data.id);
            setPageAfterload('clients');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    */
    '/dc': () => {
        App.logMeOut();
        // loadHTML('/logout.html', 'routerContainer');
    },
});

router.on(() => {
    fetch('/home.html').then(function (response) {
        return response.text();
    }).then(function (html) {
        routerContainer.innerHTML = html;
        App.setHomePage();
        setPageAfterload('index.html');
        // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
    }).catch(function (err) {
        console.warn('Something went wrong.', err);
    });
});

// set the 404 route
router.notFound((query) => {
    console.warn('Route not found');
    console.warn(query);
    fetch('/home.html').then(function (response) {
        return response.text();
    }).then(function (html) {
        routerContainer.innerHTML = html;
        setPageAfterload('home');
        App.setHomePage();
        router.updatePageLinks(); // In case there are data-navigo links on the loaded page
    }).catch(function (err) {
        console.warn('Something went wrong.', err);
    });
});

router.resolve();
/*
fetch('http://example.org').then(function(response) {
    if (response.ok) {
        return response.json()
    } else {
        throw ("Error " + response.status);
    }
}).then(function(data) {
    // do what you have to do with data
}).catch(function(err) {
    console.log(err);
});
*/