// Import CSS
// import '../css/gijgo.min.css';
// import '../css/theme.default.min.css';
// import '../css/theme.dark.min.css';
// import '../css/theme.bootstrap_4.min.css';
// import './addons/pager/jquery.tablesorter.pager.css';
import '..//bootstrap/css/bootstrap.min.css';
import '../fonts/fontawesome.all.min.css';
import '../css/-Login-form-Page-BS4-.css';
import '../css/best-carousel-slide.css';
import '../css/Contact-Form-v2-Modal--Full-with-Google-Map.css';
import '../css/Customizable-Carousel-swipe-enabled.css';
import '../css/Dot-nav-scroll-with-label-1.css';
import '../css/Dot-nav-scroll-with-label.css';
import '../css/animate.min.css';
// import 'animate.css';
import '../css/Team-with-rotating-cards.css';
import '../css/Pretty-Footer.css';
import '../css/Pricing-Tables-1.css';
import '../css/Pricing-Tables.css';
import '../css/Paralax-Hero-Banner.css';
import '../css/Pretty-Header.css';
import '../css/jquery-ui.min.css';
// import '../css/jquery-ui-timepicker-addon.css';
import '../css/theme.default.min.css';
import '../css/theme.dark.min.css';
import '../css/theme.bootstrap_4.min.css';
import './addons/pager/jquery.tablesorter.pager.min.css';
import '../css/dragtable.css';
import 'leaflet/dist/leaflet.css';
import '../css/lightbox.rotate.img.css';
import '../css/styles.css';
// Import JS
// import * as bootstrap from 'bootstrap';
import '../bootstrap/js/bootstrap.bundle.min.js';
import './Customizable-Carousel-swipe-enabled.js';
import './Contact-Form-v2-Modal--Full-with-Google-Map.js';
// import './Dot-nav-scroll-with-label.js';
import './Paralax-Hero-Banner.js';
// import './Pricing-Tables.js';
// import './Pricing-Tables-1.js';
import WOW from 'wow.js';
// import './smooth.scroll.anchor.js';
import './jquery.html5storage.min.js';
import './leaflet.js';
import './jquery-ui.min.js';
// import './jquery-ui-timepicker-addon.js';
import './jquery.ui.datepicker-fr.min.js';
import './jquery.tablesorter.min.js';
import './jquery.tablesorter.widgets.min.js';
import './addons/pager/jquery.tablesorter.pager.min.js';
import L from "leaflet";
import './jquery.dragtable.js';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import './lightbox.rotate.img';

var globals, map, locationSelect, markers_id = [],
markers, isMobile = false,
App = {
    settings: {
        pass: $.localStorage.getItem('pass'),
        admin: $.localStorage.getItem('admin'),
        defLatLng: [48.86, 2.33],
        defZoom: 6,
        bottomScrolled: false,
        getLeadsFilter: false,
        getLeadsEvent: 'loadEvent',
        getLeadsRefresh: false,
        serverAddress: "https://db.boursoconvois.com/in_station_calls.php",
        year: (new Date).getFullYear(),
        login: $.localStorage.getItem('login'),
        pwd: $.localStorage.getItem('pwd'),
        id: $.localStorage.getItem('id'),
        nom: $.localStorage.getItem('nom'),
        tel: $.localStorage.getItem('tel'),
        company: $.localStorage.getItem('company'),
        addr: $.localStorage.getItem('addr'),
        cp: $.localStorage.getItem('cp'),
        city: $.localStorage.getItem('city'),
        siret: $.localStorage.getItem('siret'),
        naf: $.localStorage.getItem('naf'),
        tva: $.localStorage.getItem('tva'),
        contacts: $.localStorage.getItem('contacts'),
        email: $.localStorage.getItem('email'),
        type: $.localStorage.getItem('type'),
        group: $.localStorage.getItem('group'),
        operator: $.localStorage.getItem('operator'),
        pilote_comp: $.localStorage.getItem('pilote_comp'),
        imat: $.localStorage.getItem('imat'),
        vpgm: $.localStorage.getItem('vpgm'),
        pilotes: $.localStorage.getItem('pilotes'),
        LeafIcon: L.Icon.extend({
            options: {
                shadowUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41],
                shadowAnchor: [12, 41]
            }
        }),

        //, greenIcon: new LeafIcon({iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-green.png'}), redIcon: new LeafIcon({iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-red.png'}), orangeIcon: new LeafIcon({iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-orange.png'})

    },
    targetTime: new Date('2022-12-31 00:00:00'), // Date cible du compte à rebours (00:00:00)
    displayElement: { // Elements HTML où sont affichés les informations
        day: null,
        hour: null,
        min: null,
        sec: null
    },
    //var greenIcon = new LeafIcon({iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-green.png'}), redIcon = new LeafIcon({iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-red.png'}), orangeIcon = new LeafIcon({iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-orange.png'});
    refreshGlobals: function(data) {
        globals.pass = data.pass;
        globals.admin = data.ad;
        globals.login = data.login;
        globals.pwd = data.pwd;
        globals.id = data.id;
        globals.nom = data.nom;
        globals.tel = data.tel;
        globals.company = data.company;
        globals.addr = data.addr;
        globals.cp = data.cp;
        globals.city = data.city;
        globals.siret = data.siret;
        globals.naf = data.naf;
        globals.tva = data.tva;
        globals.contacts = data.contacts;
        globals.email = data.email;
        globals.type = data.type;
        globals.group = data.group;
        globals.operator = data.operator;
        globals.pilote_comp = data.pilote_comp;
        globals.imat = data.imat;
        globals.vpgm = data.vpgm;
        globals.pilotes = data.pilotes;
    },
    init: function() {
        // kick things off
        globals = this.settings;
        this.bindUIActions();
        //$("#now-date").append(globals.year);
    },
    logMeIn: function(myParentDiv) {
        $(myParentDiv + ' #sender').attr("disabled", true);
        let credLogin = $(myParentDiv + ' #login').val();
        let credPass = $(myParentDiv + ' #pass').val();
        // let credType = $(myParentDiv + ' #businessType').val();
        // $.post(globals.serverAddress, { req: 'login', login: credLogin, pass: credPass, type: credType }, function(data) {
        $.post(globals.serverAddress, { req: 'login', login: credLogin, pass: credPass }, function(data) {
            if (data.pass == "OK") {
                $.localStorage.setItem('pass', data.pass);
                $.localStorage.setItem('login', data.login);
                $.localStorage.setItem('pwd', data.pwd);
                $.localStorage.setItem('id', data.id);
                $.localStorage.setItem('admin', data.ad);
                $.localStorage.setItem('nom', data.nom);
                $.localStorage.setItem('tel', data.tel);
                $.localStorage.setItem('company', data.nom);
                $.localStorage.setItem('addr', data.addr);
                $.localStorage.setItem('cp', data.cp);
                $.localStorage.setItem('city', data.city);
                $.localStorage.setItem('siret', data.siret);
                $.localStorage.setItem('naf', data.naf);
                $.localStorage.setItem('tva', data.tva);
                $.localStorage.setItem('contacts', data.contacts);
                $.localStorage.setItem('email', data.email);
                $.localStorage.setItem('type', data.type);
                $.localStorage.setItem('group', data.id);
                $.localStorage.setItem('operator', data.id);
                $.localStorage.setItem('pilote_comp', data.company);
                $.localStorage.setItem('imat', data.imat);
                $.localStorage.setItem('vpgm', data.vpgm);
                $.localStorage.setItem('pilotes', data.pilotes);
                $('#header-not-yet-connected').hide();
                if (data.type == 0)
                    $('#header-connected-transporteurs').show();
                else if (data.type == 1)
                    $('#header-connected-pilotes').show();
                else
                    $('#header-connected-stores').show();
                setTimeout(function() {
                    App.refreshGlobals(data);
                }, 500);
                setTimeout(function() {
                    if (data.station) document.location.href = '/?p=manager';
                    else document.location.href = '/?p=bourse';
                }, 1000);
                //jsonPilotes = JSON.parse(data.pilotes);
                //alert(jsonPilotes.data[0].id+' '+jsonPilotes.data[0].name+' '+jsonPilotes.data[0].imat+' '+jsonPilotes.data[0].type+' '+jsonPilotes.data[0].tel+' '+jsonPilotes.data[0].mail);
            }
            else if(data.error != '') {
                alert(data.error);
            }
            else {
                alert("Identifiant ou mot de passe erroné !");
            }
        }, "json").always(function() {
            $(myParentDiv + ' #sender').attr("disabled", false);
        });
    },
    logMeOut: function() {
        $.localStorage.setItem('pass', false);
        $.localStorage.setItem('admin', false);
        $.localStorage.setItem('login', '');
        $.localStorage.setItem('pwd', '');
        $.localStorage.setItem('id', '');
        $.localStorage.setItem('nom', '');
        $.localStorage.setItem('tel', '');
        $.localStorage.setItem('company', '');
        $.localStorage.setItem('addr', '');
        $.localStorage.setItem('cp', '');
        $.localStorage.setItem('city', '');
        $.localStorage.setItem('siret', '');
        $.localStorage.setItem('naf', '');
        $.localStorage.setItem('tva', '');
        $.localStorage.setItem('contacts', '');
        $.localStorage.setItem('email', '');
        $.localStorage.setItem('type', '');
        $.localStorage.setItem('group', '');
        $.localStorage.setItem('operator', '');
        $.localStorage.setItem('pilote_comp', '');
        $.localStorage.setItem('imat', '');
        $.localStorage.setItem('vpgm', '');
        $.localStorage.setItem('pilotes', '');
        setTimeout(function() {
            App.refreshGlobals('');
        }, 500);
        setTimeout(function() {
            document.location.href = '/';
        }, 1000);
    },
    unmask: function(thisBtn, event) {
        event.stopPropagation(); // Prevents DropDown Menu to collapse
        const pwdInput = $(thisBtn).prev('input');
        const pwdInputType = $(pwdInput).attr('type');
        console.log(pwdInputType);
        if(pwdInputType == 'password') $(pwdInput).attr("type", "text").removeClass("fas fa-eye").addClass("fas fa-eye-slash");
        else $(pwdInput).attr("type", "password").removeClass("fas fa-eye-slash").addClass("fas fa-eye");
    },
    forgottenPwd: function(myFormDiv) {
        $('#sender-forgotten').attr("disabled", true);
        let forgotEmail = $(myFormDiv+' #forgotEmail').val();
        // let forgotType = $('#forgotType').val();
        $.post(globals.serverAddress, { req: 'forgottenPwd', login: forgotEmail }, function(data) {
            if (data.ok == "ok")
                $(myFormDiv+' #successfail').html('<div class="alert alert-success">Votre mot de passe vous a été envoyé par email</div>');
            else
                $(myFormDiv+' #successfail').html('<div class="alert alert-danger">Cette adresse email ne correspond à aucun identifiant !</div>');
        }, "json").always(function() {
            $('#sender-forgotten').attr("disabled", false);
        });
    },
    subContact: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "contact";
        query = query + "&req=" + req;
        let returns = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok")
                returns = '<div class="alert alert-success" role="alert"><b>Votre message a bien été envoyé.</b></div>';
            else
                returns = '<div class="alert alert-danger" role="alert"><b>Votre message n\'a pas été envoyé.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
            $(myFormDiv + ' #successfail').empty().append(returns);
        });
    },
    subManagement: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "subscribe";
        query = query + "&req=" + req;
        let returns = "";
        let myModalId = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if(data.ok == "ok") {
                returns = '<div class="alert alert-success" role="alert"><b>Nous avons procédé à votre inscription, votre compte sera validé sous peu.</b></div>';
                switch (myFormDiv) {
                    case "#subFormTransporteurs":
                        myModalId = "#modal_subscribe_transporteurs";
                        break;
                    case "#subFormPilotes":
                        myModalId = "#modal_subscribe_pilotes";
                        break;
                    default:
                        myModalId = "";
                }
                if(myModalId != "") $(myModalId).modal('hide');
                alert("Nous avons pris en compte votre demande d’inscription et vous allez recevoir un mail de confirmation dans les meilleurs délais.");
            }
            else if(data.already) alert("Un compte existe déjà avec cette adresse email !\r\nVeuillez vous connecter, un email vous a été envoyé ou vous sera envoyé avec vos identifiants lors de la validation de votre compte.\r\nSi votre compte n'a pas encore été validé, veuillez patienter ou nous contacter.\r\nSi besoin vous pouvez utiliser la fonction mot de passe oublié.");
            else
                returns = '<div class="alert alert-danger" role="alert"><b>Nous n\'avons pas procédé à votre inscription suite à un problème technique.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
            $(myFormDiv + ' #successfail').empty().append(returns);
        });
    },
    modManagement: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "modSubscribe";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        let returns = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                returns = '<div class="alert alert-success" role="alert"><b>Votre compte a bien été modifié.</b></div>';
                $.localStorage.setItem('login', data.login);
                $.localStorage.setItem('pwd', data.pwd);
                $.localStorage.setItem('id', data.id);
                $.localStorage.setItem('nom', data.nom);
                $.localStorage.setItem('tel', data.tel);
                $.localStorage.setItem('company', data.nom);
                $.localStorage.setItem('addr', data.addr);
                $.localStorage.setItem('cp', data.cp);
                $.localStorage.setItem('city', data.city);
                $.localStorage.setItem('siret', data.siret);
                $.localStorage.setItem('naf', data.naf);
                $.localStorage.setItem('tva', data.tva);
                $.localStorage.setItem('contacts', data.contacts);
                $.localStorage.setItem('email', data.email);
                $.localStorage.setItem('type', data.type);
                $.localStorage.setItem('group', data.id);
                $.localStorage.setItem('operator', data.id);
                setTimeout(function() {
                    App.refreshGlobals(data);
                }, 1000);
            } else
                returns = '<div class="alert alert-danger" role="alert"><b>Votre compte n\'a pas été modifié.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
            $(myFormDiv + ' #successfail').empty().append(returns);
        });
    },
    // Met à jour le compte à rebours (tic d'horloge)
    tick: function() {
        // Instant présent
        let timeNow = new Date();
        // On s'assure que le temps restant ne soit jamais négatif (ce qui est le cas dans le futur de targetTime)
        if (timeNow > this.targetTime) {
            timeNow = this.targetTime;
        }
        // Calcul du temps restant
        let diff = this.dateDiff(timeNow, this.targetTime);
        this.displayElement.day.text(diff.day);
        this.displayElement.hour.text(diff.hour);
        this.displayElement.min.text(diff.min);
        this.displayElement.sec.text(diff.sec);
    },
    // Calcul la différence entre 2 dates, en jour/heure/minute/seconde
    dateDiff: function(date1, date2) {
        let diff = {}; // Initialisation du retour
        let tmp = date2 - date1;
        tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
        diff.sec = tmp % 60; // Extraction du nombre de secondes
        tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
        diff.min = tmp % 60; // Extraction du nombre de minutes
        tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
        diff.hour = tmp % 24; // Extraction du nombre d'heures
        tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
        diff.day = tmp;
        return diff;
    },
    locateMe: function(myEvent) {
        switch (myEvent) {
            case "load":
                if (navigator.geolocation) {
                    if (navigator.userAgent.toLowerCase().match(/android/)) {
                        navigator.geolocation.getCurrentPosition(App.codeLatLng, App.showError, { enableHighAccuracy: false, maximumAge: 0, timeout: 9000 });
                    } else {
                        navigator.geolocation.getCurrentPosition(App.codeLatLng, App.showError, { enableHighAccuracy: true, maximumAge: 0, timeout: 9000 });
                    }
                } else {
                    alert("Localisation impossible, veuillez v&eacute;rifier l'&eacute;tat de votre connection ainsi que la disponibilit&eacute; des services de localisation dans les réglages de votre appareil.");
                }
                break;
            default:
                if (navigator.geolocation) {
                    if (navigator.userAgent.toLowerCase().match(/android/)) {
                        navigator.geolocation.getCurrentPosition(App.codeLatLng, App.showError, { enableHighAccuracy: false, maximumAge: 0, timeout: 9000 });
                    } else {
                        navigator.geolocation.getCurrentPosition(App.codeLatLng, App.showError, { enableHighAccuracy: true, maximumAge: 0, timeout: 9000 });
                    }
                } else {
                    alert("Localisation impossible, veuillez v&eacute;rifier l'&eacute;tat de votre connection ainsi que la disponibilit&eacute; des services de localisation dans les réglages de votre appareil.");
                }
        }
    },
    codeLatLng: function(position) {
        let lat = parseFloat(position.coords.latitude);
        let lng = parseFloat(position.coords.longitude);
        let latlng = [lat, lng];
        //alert(latlng);
        globals.defLatLng = latlng;
        //map.setView(L.LatLng(lat,lng), globals.defZoom);
        map.setView(latlng, globals.defZoom);
        L.marker(globals.defLatLng).addTo(map)
            .bindPopup('<p><b>Vous Êtes<br> ICI !!!</b></p>')
            .openPopup();
        let circle = L.circle(globals.defLatLng, {
            color: '#0CF',
            fillColor: '#008595',
            fillOpacity: 0.2,
            radius: 100000
        }).addTo(map);
        /*
        // Using https://adresse.data.gouv.fr
        //https://api-adresse.data.gouv.fr/reverse/?lon=2.37&lat=48.357&type=street
        $.get('https://api-adresse.data.gouv.fr/reverse/', {lat: lat, lon: lng, type: ''}, function(data) {
            //alert(data.features[0].properties.label+' - '+data.features[0].properties.postcode);
            if(data.features[0].properties.label!='baninfo') {
                // Reverse geocoding is OK...
                $('#addressInput').val(data.features[0].properties.label);
                $('#addressInput2').val(data.features[0].properties.label);
                $('#addressInputPrices').val(data.features[0].properties.label);
                insee = data.features[0].properties.citycode;
                zip = data.features[0].properties.postcode;
                dep = zip.substring(0, 2);
                $.sessionStorage.setItem('dep', dep);
            }
            else {
                alert('Adresse inconnue !! Veuillez la saisir manuellement SVP.');
            }
        }, "json").done(function() {
        });
        */
    },
    showError: function(error) {
        let geoAlert = "";
        switch (error.code) {
            case error.PERMISSION_DENIED:
                geoAlert = "Vous avez refusé l'accès à la Géolocalisation, vous pouvez modifier cela dans les réglages.";
                break;
            case error.POSITION_UNAVAILABLE:
                geoAlert = "Géolocalisation indisponible, veuillez regarder dans l'aide ou activer le service dans les reglages de votre appareil.";
                break;
            case error.TIMEOUT:
                geoAlert = "La demande de Géolocalisation a expiré, veuillez vérifier l'état de votre connection ainsi que la disponibilité des services de localisation (user location request timed out).";
                break;
            case error.UNKNOWN_ERROR:
                geoAlert = "Erreur inconnue de Géolocalisation (unknown error occurred).";
                break;
            default:
                geoAlert = "Erreur de Géolocalisation, libre à vous d'activer le service de géolocalisation pour cette app dans les réglages.";
        }
        if (error.code == error.TIMEOUT || error.code == error.POSITION_UNAVAILABLE) {
            // Fall back to low accuracy and any cached position available...
            navigator.geolocation.getCurrentPosition(App.codeLatLng, function() {
                alert(geoAlert);
            }, { enableHighAccuracy: false, maximumAge: Infinity, timeout: 6000 });
        } else {
            alert(geoAlert);
        }
    },
    searchLocations: function() {
        App.clearMap();
        let app;
        let blackIcon = new globals.LeafIcon({ iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-black.png' }),
            greyIcon = new globals.LeafIcon({ iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-grey.png' });
        let rdv = $('#addressInput').val();
        let dest = $('#DestAddress').val();
        let rdvLng;
        let rdvLat;
        let destLng;
        let destLat;
        if (rdv != '' && dest != '') {
            // Using https://adresse.data.gouv.fr
            //https://api-adresse.data.gouv.fr/search/?q=8 bd du port&type=street
            $.get('https://api-adresse.data.gouv.fr/search/', { q: rdv, type: '' }, function(data) {
                rdvLng = data.features[0].geometry.coordinates[0];
                rdvLat = data.features[0].geometry.coordinates[1];
                //zipPrices = data.features[0].properties.postcode;
                //depPrices = zipPrices.substring(0, 2);
            }, "json").done(function(data) {
                $.get('https://api-adresse.data.gouv.fr/search/', { q: dest, type: '' }, function(data) {
                    destLng = data.features[0].geometry.coordinates[0];
                    destLat = data.features[0].geometry.coordinates[1];
                }, "json").done(function(data) {
                    let lat = parseFloat(rdvLat);
                    let lng = parseFloat(rdvLng);
                    let dLat = parseFloat(destLat);
                    let dLng = parseFloat(destLng);
                    let latlng = [lat, lng];
                    let dlatlng = [dLat, dLng];
                    //alert(latlng);
                    globals.defLatLng = latlng;
                    globals.defdestLatLng = dlatlng;
                    //map.setView(L.LatLng(lat,lng), globals.defZoom);
                    map.setView(latlng, globals.defZoom);
                    L.marker(globals.defLatLng, { icon: greyIcon }).addTo(map)
                        .bindPopup('<p><b>Départ: </b>' + rdv + '</p>', { autoClose: false })
                        .openPopup();
                    L.marker(globals.defdestLatLng, { icon: blackIcon }).addTo(map)
                        .bindPopup('<p><b>Arrivée: </b>' + dest + '</p>')
                        .openPopup();
                    let circle = L.circle(globals.defLatLng, {
                        color: '#FFAB00',
                        fillColor: '#FFBF00',
                        fillOpacity: 0.2,
                        radius: 100000
                    }).addTo(map);
                    const mapBounds = L.latLngBounds(latlng, dlatlng);
                    map.fitBounds(mapBounds);
                });
            });
        } else {
            alert("Vous devez renseigner les adresses de Départ et d'arrivée.");
        }
    },
    showLeadOnMap: function(auto_l, addr_l, addr_comp_l, codePostal_l, city_l, addr_dest_l, addr_dest_comp_l, codePostal_dest_l, city_dest_l, cat_l, num_arr_pref, name_l, tel_l, datedeb_l, datefin_l, long_l, large_l, height_l, weight_l, est_time, est_km, vp_av, vp_ar, guides, imat_tr, imat_sr, lat_p, lng_p, timestamp_p, page) {
        if (page == 'bourse') App.popMapFollowLeads();
        let greenIcon = new globals.LeafIcon({ iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-green.png' }),
            redIcon = new globals.LeafIcon({ iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-red.png' }),
            orangeIcon = new globals.LeafIcon({ iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-orange.png' });
        App.clearMap('bourse');
        let rdv = (addr_l != "") ? addr_l + ", " + codePostal_l + " " + city_l : city_l;
        let dest = (addr_dest_l != "") ? addr_dest_l + ", " + codePostal_dest_l + " " + city_dest_l : city_dest_l;
        let rdvLng;
        let rdvLat;
        let destLng;
        let destLat;
        if (rdv != '' && dest != '') {
            // Using https://adresse.data.gouv.fr
            //https://api-adresse.data.gouv.fr/search/?q=8 bd du port&type=street
            $.get('https://api-adresse.data.gouv.fr/search/', { q: rdv, type: '' }, function(data) {
                rdvLng = data.features[0].geometry.coordinates[0];
                rdvLat = data.features[0].geometry.coordinates[1];
                //zipPrices = data.features[0].properties.postcode;
                //depPrices = zipPrices.substring(0, 2);
            }, "json").done(function(data) {
                $.get('https://api-adresse.data.gouv.fr/search/', { q: dest, type: '' }, function(data) {
                    destLng = data.features[0].geometry.coordinates[0];
                    destLat = data.features[0].geometry.coordinates[1];
                }, "json").done(function(data) {
                    let lat = parseFloat(rdvLat);
                    let lng = parseFloat(rdvLng);
                    let dLat = parseFloat(destLat);
                    let dLng = parseFloat(destLng);
                    let latlng = [lat, lng];
                    let dlatlng = [dLat, dLng];
                    let latlng_p = [parseFloat(lat_p), parseFloat(lng_p)];
                    globals.defLatLng = latlng;
                    globals.defdestLatLng = dlatlng;
                    map.setView(latlng, globals.defZoom);
                    L.marker(globals.defLatLng, { icon: greenIcon }).addTo(map)
                        .bindPopup('<p><b>N&deg; de convoi: ' + auto_l + '<br>Départ:<br>' + datedeb_l + '</b><br>' + rdv + '<br>' + addr_comp_l + '</p>', { autoClose: false })
                        .openPopup();
                    L.marker(globals.defdestLatLng, { icon: redIcon }).addTo(map)
                        .bindPopup('<p><b>N&deg; de convoi: ' + auto_l + '<br>Arrivée:<br>' + datefin_l + '</b><br>' + dest + '<br>' + addr_dest_comp_l + '</p>', { autoClose: false })
                        .openPopup();
                    if (lat_p != 0 && lng_p != 0) {
                        L.marker(latlng_p, { icon: orangeIcon }).addTo(map)
                            .bindPopup('<p><b>Convois positionné le:<br>' + timestamp_p + '</b></p>', { autoClose: false })
                            .openPopup();
                        let circle = L.circle(latlng_p, {
                            color: '#FFAB00',
                            fillColor: '#FFBF00',
                            fillOpacity: 0.2,
                            radius: 100000
                        }).addTo(map);
                    }
                    const mapBounds = L.latLngBounds(latlng, dlatlng);
                    map.fitBounds(mapBounds);
                });
            });
        } else {
            alert("Vous devez renseigner les adresses de Départ et d'arrivée.");
        }
        if (page!='bourse') {
            // Filling info fields
            $('#addressInput').val(addr_l);
            $('#DestAddress').val(addr_dest_l);
            $('#etd').val(datedeb_l);
            $('#eta').val(datefin_l);
            $('#jobCat').val(cat_l);
            $('#Accurate').val(name_l);
            $('#cellular').val(tel_l);
            $('#depDep').val(codePostal_l);
            $('#depArr').val(codePostal_dest_l);
            $('#coms').val("Longueur: " + long_l + ", Largeur: " + large_l + ", Hauteur: " + height_l + "et MTR: " + weight_l + "\n" + "Estimation Durée(heures)/Km: " + est_time + " / " + est_km + "\n" + "Immatriculations TR/SR: " + imat_tr + " / " + imat_sr + "\n" + "Postes: VP AV = " + vp_av + " / VP AR = " + vp_ar + " / Guideur(s) = " + guides + "\n");
        }
    },
    showDriverOnMap: function(lat_p, lng_p, timestamp_p, page) {
        // App.popMapFollowLeads();
        $('#localisateModal').modal("show").on('shown.bs.modal', function() {
            // Map to follow the lead if not already initialized...
            if (map == undefined || map == null) {
                // Defining defaults baseLayer and overlay
                const defaultOverlay = L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
                    layers: "SECUROUTE.TE.2TE48",
                    format: 'image/png',
                    transparent: true
                });
                const defaultBaseLayer = L.tileLayer('https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=b38726e53811414b88dd7dc694d70755', {
                    attribution: '&copy; <a href="https://title.thunderfotrest.com/copyright">OpenStreetMap</a> contributors'
                });
                // Instantiate map with defaults base and overlay
                map = L.map('mapLeads', {
                    center: globals.defLatLng,
                    zoom: globals.defZoom,
                    // layers: [defaultBaseLayer, defaultOverlay]
                });
                const overlays = {
                    "1ère catégorie": L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
                        layers: "SECUROUTE.TE.1TE",
                        format: 'image/png',
                        transparent: true
                    }),
                    "2ème catégorie": defaultOverlay,
                    "72 tonnes": L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
                        layers: "SECUROUTE.TE.TE72",
                        format: 'image/png',
                        transparent: true
                    }),
                    "94 tonnes": L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
                        layers: "SECUROUTE.TE.TE94",
                        format: 'image/png',
                        transparent: true
                    }),
                    "120 tonnes": L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
                        layers: "SECUROUTE.TE.TE120",
                        format: 'image/png',
                        transparent: true
                    }),
                    "Franchissement": L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
                        layers: "SECUROUTE.TE.ALL",
                        format: 'image/png',
                        transparent: true
                    })
                };
                // Création des couches de maps
                const baselayers = {
                    Transport: defaultBaseLayer,
                    Classique: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    })
                };
                // Select les fonds de cartes, positionné
                L.control.layers(baselayers, overlays, {position: 'topleft'}).addTo(map);
                defaultBaseLayer.addTo(map);
                defaultOverlay.addTo(map);
            }
            setTimeout(function() {
                map.invalidateSize();
                // Make sure defaults layers are respected
                $('.leaflet-control-layers-overlays .leaflet-control-layers-selector:eq(1)').trigger('click');
                $('.leaflet-control-layers-base .leaflet-control-layers-selector:eq(0)').trigger('click');
            }, 100);
            let greenIcon = new globals.LeafIcon({ iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-green.png' }),
                redIcon = new globals.LeafIcon({ iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-red.png' }),
                orangeIcon = new globals.LeafIcon({ iconUrl: 'https://boursoconvois.com/assets/img/leaflet/marker-icon-2x-orange.png' });
            App.clearMap('bourse');
            let latlng_p = [parseFloat(lat_p), parseFloat(lng_p)];
            map.setView(latlng_p, 12);
            // map.panTo(latlng_p);
            if (lat_p!=0 && lng_p!=0) {
                L.marker(latlng_p, { icon: orangeIcon }).addTo(map)
                    .bindPopup('<p><b>VP / Guideur positionné le:<br>' + timestamp_p + '</b></p>')
                    .openPopup();
                var circle = L.circle(latlng_p, {
                    color: '#FFAB00',
                    fillColor: '#FFBF00',
                    fillOpacity: 0.2,
                    radius: 100000
                }).addTo(map);
                $('#hidePanel').trigger('click'); // hidePanel
            }
            else alert("Ce VP / Guideur n'a jamais été géolocalisé !!");
        });
    },
    clearMap: function(page) {
        //markers.clearLayers();
        map.eachLayer(function(layer) {
            //layer.bindPopup('Hello');
            map.removeLayer(layer);
        });
        if (page != 'bourse') {
            locationSelect.innerHTML = "";
            let option = document.createElement("option");
            option.value = "none";
            option.innerHTML = "Voir tous les r&eacute;sultats :";
            locationSelect.appendChild(option);
        }
    },
    clearFormFields: function(myFormDiv) {
		$(myFormDiv).find("input, textarea, select").val('');
		$(myFormDiv).find("input[type=checkbox]").prop("checked", false);
		$(myFormDiv).find("input[type=radio]").prop("checked", false);
		$(myFormDiv).removeClass('was-validated');
		$(myFormDiv+' #successfail').empty();
    },
    addWasValidatedClass: function(myFormDiv) {
        $(myFormDiv).addClass('was-validated');
        let wrongFields = "Les champs suivants sont mal renseignés: \n";
        let wrongPop = false;
        $(myFormDiv).find('.form-control:invalid').each(function() {
            //alert("IN");
            wrongPop = true;
            $(this).closest('.form-group').find('label').each(function() {
                wrongFields += $(this).text() + "\n";
            });
        });
        if (wrongPop) {
            alert(wrongFields);
            if (myFormDiv == '#addFormCommands') {
                $('#collapseTwoAdd').collapse('show');
                // $('#collapseTwoAddBtn').trigger('click');
            }
        }
        return !wrongPop;
    },
    desaboSp: function(auto_sp) {
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            $.post(globals.serverAddress, { req: 'desaboSp', id: globals.id, admin: globals.admin, pwd: globals.pwd, auto_sp: auto_sp }, function(data) {
                console.log(data.ok);
                if (data.ok == 'ok') {
                    App.tableSp();
                    alert('L\'abonnement de la société pilote a été retiré');
                } else alert('Aucune société pilote n\'a été abonnée .');
            }, "json").done(function(data) {}).always(function(data) {});
        }
    },
    aboSp: function(auto_sp) {
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            $.post(globals.serverAddress, { req: 'aboSp', id: globals.id, admin: globals.admin, pwd: globals.pwd, auto_sp: auto_sp }, function(data) {
                console.log(data.ok);
                if (data.ok == 'ok') {
                    App.tableSp();
                    alert('La société pilote a été abonnée');
                } else alert('Aucune société pilote n\'a été abonnée .');
            }, "json").done(function(data) {}).always(function(data) {});
        }
    },
    deleteSp: function(auto_sp) {
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            $.post(globals.serverAddress, { req: 'deleteSp', id: globals.id, admin: globals.admin, pwd: globals.pwd, auto_sp: auto_sp }, function(data) {
                console.log(data.ok);
                if (data.ok == 'ok') {
                    App.tableSp();
                    alert('La société pilote a bien été désactivée');
                } else alert('Aucune société pilote n\'a été désactivée .');
            }, "json").done(function(data) {}).always(function(data) {});
        }
    },
    desaboSt: function(auto_st) {
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            $.post(globals.serverAddress, { req: 'desaboSt', id: globals.id, admin: globals.admin, pwd: globals.pwd, auto_st: auto_st }, function(data) {
                console.log(data.ok);
                if (data.ok == 'ok') {
                    App.tableSt();
                    alert('L\'abonnement de la société transporteur a été retiré');
                } else alert('Aucune société transporteur n\'a été abonnée .');
            }, "json").done(function(data) {}).always(function(data) {});
        }
    },
    aboSt: function(auto_st) {
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            $.post(globals.serverAddress, { req: 'aboSt', id: globals.id, admin: globals.admin, pwd: globals.pwd, auto_st: auto_st }, function(data) {
                console.log(data.ok);
                if (data.ok == 'ok') {
                    App.tableSt();
                    alert('La société transporteur a été abonnée');
                } else alert('Aucune société transporteur n\'a été abonnée .');
            }, "json").done(function(data) {}).always(function(data) {});
        }
    },
    deleteSt: function(auto_st) {
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            $.post(globals.serverAddress, { req: 'deleteSt', id: globals.id, admin: globals.admin, pwd: globals.pwd, auto_st: auto_st }, function(data) {
                console.log(data.ok);
                if (data.ok == 'ok') {
                    App.tableSt();
                    alert('Le transporteur a bien été désactivée');
                } else alert('Aucune transporteur n\'a été désactivée .');
            }, "json").done(function(data) {}).always(function(data) {});
        }
    },
    activateSp: function(auto_sp) {
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            $.post(globals.serverAddress, { req: 'activateSp', id: globals.id, admin: globals.admin, pwd: globals.pwd, auto_sp: auto_sp }, function(data) {
                console.log(data.ok);
                if (data.ok == 'ok') {
                    App.tableSp();
                    alert('La société pilote a bien été activée');
                } else alert('Aucune société pilote a été activée .');
            }, "json").done(function(data) {}).always(function(data) {});
        }
    },
    activateSt: function(auto_st) {
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            $.post(globals.serverAddress, { req: 'activateSt', id: globals.id, admin: globals.admin, pwd: globals.pwd, auto_st: auto_st }, function(data) {
                console.log(data.ok);
                if (data.ok == 'ok') {
                    App.tableSt();
                    alert('Le transporteur a bien été activée');
                } else alert('Aucune transporteur n\'a été activée .');
            }, "json").done(function(data) {}).always(function(data) {});
        }
    },
    fillUpdateSpForm: function(myFormDiv, auto_sp, name_sp, tel_sp, mail_sp, addr_sp, pwd_sp, city_sp, cp_sp, siret_sp, naf_sp, tva_sp) {
        $(myFormDiv + ' #auto_sp').val(auto_sp);
        $(myFormDiv + ' #name_sp').val(name_sp);
        $(myFormDiv + ' #tel_sp').val(tel_sp);
        $(myFormDiv + ' #mail_sp').val(mail_sp);
        $(myFormDiv + ' #addr_sp').val(addr_sp);
        $(myFormDiv + ' #pwd_sp').val(pwd_sp);
        $(myFormDiv + ' #city_sp').val(city_sp);
        $(myFormDiv + ' #cp_sp').val(cp_sp);
        $(myFormDiv + ' #siret_sp').val(siret_sp);
        $(myFormDiv + ' #naf_sp').val(naf_sp);
        $(myFormDiv + ' #tva_sp').val(tva_sp);
    },

    fillUpdateStForm: function(myFormDiv, auto_st, name_st, tel_st, mail_st, addr_st, pwd_st, city_st, cp_st, siret_st, naf_st, tva_st) {
        $(myFormDiv + ' #auto_st').val(auto_st);
        $(myFormDiv + ' #name_st').val(name_st);
        $(myFormDiv + ' #tel_st').val(tel_st);
        $(myFormDiv + ' #mail_st').val(mail_st);
        $(myFormDiv + ' #addr_st').val(addr_st);
        $(myFormDiv + ' #pwd_st').val(pwd_st);
        $(myFormDiv + ' #city_st').val(city_st);
        $(myFormDiv + ' #cp_st').val(cp_st);
        $(myFormDiv + ' #siret_st').val(siret_st);
        $(myFormDiv + ' #naf_st').val(naf_st);
        $(myFormDiv + ' #tva_st').val(tva_st);
    },

    updateSp: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = 'updateSp';
        query = query + "&id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == 'ok') {
                App.tableSp();
                alert("le champ a bien été modifié");
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i> La société pilote a bien été modifiée</b></div>');
                setTimeout(function() {
                    $('#updateSpModal').modal('hide');
                    App.clearFormFields(myFormDiv);
                }, 3000);
            } else {
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> Une erreur s\'est produite: ' + data.textAlert + '</b></div>');
            }
            /*else $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> La tranche n\'a pas été modifiée car une erreur s\'est produite ' + $textAlert +'</b></div>');*/
        }, "json").done(function(data) {}).always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
        });
    },
    updateSt: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = 'updateSt';
        query = query + "&id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == 'ok') {
                App.tableSt();
                // alert("le champ a bien été modifié");
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i> Le transporteur a bien été modifié</b></div>');
                setTimeout(function() {
                    $('#updateStModal').modal('hide');
                    App.clearFormFields(myFormDiv);
                }, 3000);
            } else {
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> Une erreur s\'est produite: ' + data.textAlert + '</b></div>');
            }
            /*else $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> La tranche n\'a pas été modifiée car une erreur s\'est produite ' + $textAlert +'</b></div>');*/
        }, "json").done(function(data) {}).always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
        });
    },
    getLeads: function(myEvent, refresh) {
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, type: globals.type, pwd: globals.pwd, req: 'getLeads', filter: myEvent }, function(data) {
            if (data.ok == "ok" && data.nbResult > 0 || globals.type == 0) {
                $('#btnFilter').empty().append(data.btnFilter);
                $('#leadsCont').empty().append(data.leads);
            } else {
                let returns = '';
                if (myEvent != 'loadEvent')
                    returns = '<div class="alert alert-danger" role="alert"><b>Désolé mais nous n\'avons aucun convoi correspondant à ces critères.</b></div>';
                else
                    returns = '<div class="alert alert-info" role="alert"><b>Toutes les propositions d\'escorte et/ou guidage ont été satisfaites.</b></div>';
                $('#leadsCont').empty().append(returns);
                $('#leadsCont').empty().append(returns);
            }
        }, "json").done(function(data) {
            if (data.ok == 'ok') {
                $('#tableLeads').tablesorter({
                    theme: "bootstrap", // dark or bootstrap are cool here.
                    widthFixed: true,
                    dateFormat: "ddmmyyyy", // set the default date format
                    // widget code contained in the jquery.tablesorter.widgets.js file
                    // use the zebra stripe widget if you plan on hiding any rows (filter widget)
                    // the uitheme widget is NOT REQUIRED!
                    widgets: ["filter"],
                    ignoreCase: true,
                    // sort on the first column and second column in ascending order
                    sortList: [[7,0], [3,1]],
                    widgetOptions: {
                        // using the default zebra striping class name, so it actually isn't included in the theme variable above
                        // this is ONLY needed for bootstrap theming if you are using the filter widget, because rows are hidden
                        // zebra : ["even", "odd"],
                        // reset filters button
                        filter_reset: ".reset",
                        filter_childRows: false,
                        filter_columnFilters: true,
                        filter_cellFilter: '',
                        filter_defaultFilter: {},
                        filter_excludeFilter: {},
                        filter_external: '',
                        filter_filteredRow: 'filtered',
                        filter_formatter: null,
                        filter_functions: null,
                        filter_hideEmpty: true,
                        filter_hideFilters: false,
                        filter_ignoreCase: true,
                        filter_liveSearch: true,
                        filter_onlyAvail: 'filter-onlyAvail',
                        filter_placeholder: { search: 'Filtre', select: '' },
                        filter_saveFilters: false,
                        filter_searchDelay: 300,
                        filter_searchFiltered: true,
                        filter_selectSource: null,
                        filter_serversideFiltering: false,
                        filter_startsWith: false,
                        filter_useParsedData: false,
                        filter_defaultAttrib: 'data-value',
                        filter_selectSourceSeparator: '|',
                        // extra css class name (string or array) added to the filter element (input or select)
                        filter_cssFilter: [
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                        ],
                    }
                }).tablesorterPager({
                    // target the pager markup - see the HTML block below
                    container: $('#leadsPager'),
                    // target the pager page select dropdown - choose a page
                    cssGoto: ".pagenum",
                    // remove rows from the table to speed up the sort of large tables.
                    // setting this to false, only hides the non-visible rows; needed if you plan to add/remove rows with the pager enabled.
                    removeRows: false,
                    // output string - default is '{page}/{totalPages}';
                    // possible variables: {page}, {totalPages}, {filteredPages}, {startRow}, {endRow}, {filteredRows} and {totalRows}
                    output: '{startRow} - {endRow} / {filteredRows} ({totalRows})',
                    size: 50
                });
            }
        }).always(function(data) {
            // console.warn(refresh+' - '+globals.admin);
            // if(refresh && globals.admin!='true') setTimeout("App.getLeads('" + myEvent + "', true)", 60000);
            if(refresh && !globals.getLeadsRefresh) globals.getLeadsRefresh = setInterval(() => {App.getLeads(myEvent, true)}, 60000);
        });
    },
    getRelaisDO: function() {

        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, type: globals.type, pwd: globals.pwd, req: 'getRelaisDO' }, function(data) {
            if (data.ok == "ok") {
                // $('#leadsCont') to be filled
                $('#leadsCont').empty().append(data.leads);
            } else {
                var returns = '';
                returns = '<div class="alert alert-info" role="alert"><b>Vous n\'avez déposé aucun relai</b></div>';
                $('#leadsCont').empty().append(returns);
            }
        }, "json").done(function(data) {
            if (data.ok == 'ok') {
                $('#tableRelais').tablesorter({
                    theme: "bootstrap", // dark or bootstrap are cool here.
                    widthFixed: true,
                    dateFormat: "ddmmyyyy", // set the default date format
                    // widget code contained in the jquery.tablesorter.widgets.js file
                    // use the zebra stripe widget if you plan on hiding any rows (filter widget)
                    // the uitheme widget is NOT REQUIRED!
                    widgets: ["filter"],
                    ignoreCase: true,
                    // sort on the first column and second column in ascending order
                    sortList: [
                        [7, 0],
                        [3, 1]
                    ],
                    widgetOptions: {
                        // using the default zebra striping class name, so it actually isn't included in the theme variable above
                        // this is ONLY needed for bootstrap theming if you are using the filter widget, because rows are hidden
                        // zebra : ["even", "odd"],
                        // reset filters button
                        filter_reset: ".reset",
                        filter_childRows: false,
                        filter_columnFilters: true,
                        filter_cellFilter: '',
                        filter_defaultFilter: {},
                        filter_excludeFilter: {},
                        filter_external: '',
                        filter_filteredRow: 'filtered',
                        filter_formatter: null,
                        filter_functions: null,
                        filter_hideEmpty: true,
                        filter_hideFilters: false,
                        filter_ignoreCase: true,
                        filter_liveSearch: true,
                        filter_onlyAvail: 'filter-onlyAvail',
                        filter_placeholder: { search: 'Filtre', select: '' },
                        filter_saveFilters: false,
                        filter_searchDelay: 300,
                        filter_searchFiltered: true,
                        filter_selectSource: null,
                        filter_serversideFiltering: false,
                        filter_startsWith: false,
                        filter_useParsedData: false,
                        filter_defaultAttrib: 'data-value',
                        filter_selectSourceSeparator: '|',
                        // extra css class name (string or array) added to the filter element (input or select)
                        filter_cssFilter: [
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                        ],
                    }
                }).tablesorterPager({
                    // target the pager markup - see the HTML block below
                    container: $('#leadsPager'),
                    // target the pager page select dropdown - choose a page
                    cssGoto: ".pagenum",
                    // remove rows from the table to speed up the sort of large tables.
                    // setting this to false, only hides the non-visible rows; needed if you plan to add/remove rows with the pager enabled.
                    removeRows: false,
                    // output string - default is '{page}/{totalPages}';
                    // possible variables: {page}, {totalPages}, {filteredPages}, {startRow}, {endRow}, {filteredRows} and {totalRows}
                    output: '{startRow} - {endRow} / {filteredRows} ({totalRows})',
                    size: 50
                });
            }
        });

        //alert(myEvent+" - "+globals.getLeadsEvent+" - "+refresh);
    },

    getRelaisST: function() {

        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, type: globals.type, pwd: globals.pwd, req: 'getRelaisST' }, function(data) {
            if (data.ok == "ok" && data.nbResult > 0) {
                // $('#leadsCont') to be filled
                $('#leadsCont').empty().append(data.leads);
            } else {
                var returns = '';
                returns = '<div class="alert alert-info" role="alert"><b>Toutes les propositions d\'escorte et/ou guidage ont été satisfaites </b></div>';
                $('#leadsCont').empty().append(returns);
            }
        }, "json").done(function(data) {
            if (data.ok == 'ok') {
                $('#tableRelais').tablesorter({
                    theme: "bootstrap", // dark or bootstrap are cool here.
                    widthFixed: true,
                    dateFormat: "ddmmyyyy", // set the default date format
                    // widget code contained in the jquery.tablesorter.widgets.js file
                    // use the zebra stripe widget if you plan on hiding any rows (filter widget)
                    // the uitheme widget is NOT REQUIRED!
                    widgets: ["filter"],
                    ignoreCase: true,
                    // sort on the first column and second column in ascending order
                    sortList: [
                        [7, 0],
                        [3, 1]
                    ],
                    widgetOptions: {
                        // using the default zebra striping class name, so it actually isn't included in the theme variable above
                        // this is ONLY needed for bootstrap theming if you are using the filter widget, because rows are hidden
                        // zebra : ["even", "odd"],
                        // reset filters button
                        filter_reset: ".reset",
                        filter_childRows: false,
                        filter_columnFilters: true,
                        filter_cellFilter: '',
                        filter_defaultFilter: {},
                        filter_excludeFilter: {},
                        filter_external: '',
                        filter_filteredRow: 'filtered',
                        filter_formatter: null,
                        filter_functions: null,
                        filter_hideEmpty: true,
                        filter_hideFilters: false,
                        filter_ignoreCase: true,
                        filter_liveSearch: true,
                        filter_onlyAvail: 'filter-onlyAvail',
                        filter_placeholder: { search: 'Filtre', select: '' },
                        filter_saveFilters: false,
                        filter_searchDelay: 300,
                        filter_searchFiltered: true,
                        filter_selectSource: null,
                        filter_serversideFiltering: false,
                        filter_startsWith: false,
                        filter_useParsedData: false,
                        filter_defaultAttrib: 'data-value',
                        filter_selectSourceSeparator: '|',
                        // extra css class name (string or array) added to the filter element (input or select)
                        filter_cssFilter: [
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                        ],
                    }
                }).tablesorterPager({
                    // target the pager markup - see the HTML block below
                    container: $('#leadsPager'),
                    // target the pager page select dropdown - choose a page
                    cssGoto: ".pagenum",
                    // remove rows from the table to speed up the sort of large tables.
                    // setting this to false, only hides the non-visible rows; needed if you plan to add/remove rows with the pager enabled.
                    removeRows: false,
                    // output string - default is '{page}/{totalPages}';
                    // possible variables: {page}, {totalPages}, {filteredPages}, {startRow}, {endRow}, {filteredRows} and {totalRows}
                    output: '{startRow} - {endRow} / {filteredRows} ({totalRows})',
                    size: 50
                });
            }
        }).always(function(data) {
            //if(!globals.getLeadsFilter) setTimeout("App.getLeads('"+myEvent+"')", 10000);
            if (!globals.getLeadsFilter) setTimeout("App.getLeads('" + globals.getLeadsEvent + "', true)", 60000);
        });

        //alert(myEvent+" - "+globals.getLeadsEvent+" - "+refresh);
    },
    getLeadsHistoryST: function(type) {
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, type: type, req: 'getLeadsHistoryST' }, function(data) {
            if (data.ok == "ok") {
                // $('#leadsCont') to be filled

                $('#leadsCont').empty().append(data.leads);
            } else {
                const returns = '<div class="alert alert-info" role="alert"><b>vous n\'avez effectué aucun convoi...</b></div>';
                $('#leadsCont').empty().append(returns);
            }
        }, "json").done(function(data) {
            if (data.ok == 'ok') {
                $('#tableLeads').tablesorter({
                    theme: "bootstrap", // dark or bootstrap are cool here.
                    widthFixed: true,
                    dateFormat: "ddmmyyyy", // set the default date format
                    // widget code contained in the jquery.tablesorter.widgets.js file
                    // use the zebra stripe widget if you plan on hiding any rows (filter widget)
                    // the uitheme widget is NOT REQUIRED!
                    widgets: ["filter"],
                    ignoreCase: true,
                    // sort on the first column and second column in ascending order
                    sortList: [
                        [7, 0],
                        [3, 1]
                    ],
                    widgetOptions: {
                        // using the default zebra striping class name, so it actually isn't included in the theme variable above
                        // this is ONLY needed for bootstrap theming if you are using the filter widget, because rows are hidden
                        // zebra : ["even", "odd"],
                        // reset filters button
                        filter_reset: ".reset",
                        filter_childRows: false,
                        filter_columnFilters: true,
                        filter_cellFilter: '',
                        filter_defaultFilter: {},
                        filter_excludeFilter: {},
                        filter_external: '',
                        filter_filteredRow: 'filtered',
                        filter_formatter: null,
                        filter_functions: null,
                        filter_hideEmpty: true,
                        filter_hideFilters: false,
                        filter_ignoreCase: true,
                        filter_liveSearch: true,
                        filter_onlyAvail: 'filter-onlyAvail',
                        filter_placeholder: { search: 'Filtre', select: '' },
                        filter_saveFilters: false,
                        filter_searchDelay: 300,
                        filter_searchFiltered: true,
                        filter_selectSource: null,
                        filter_serversideFiltering: false,
                        filter_startsWith: false,
                        filter_useParsedData: false,
                        filter_defaultAttrib: 'data-value',
                        filter_selectSourceSeparator: '|',
                        // extra css class name (string or array) added to the filter element (input or select)
                        filter_cssFilter: [
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                            'form-control',
                        ],
                    }
                }).tablesorterPager({
                    // target the pager markup - see the HTML block below
                    container: $('#leadsPager'),
                    // target the pager page select dropdown - choose a page
                    cssGoto: ".pagenum",
                    // remove rows from the table to speed up the sort of large tables.
                    // setting this to false, only hides the non-visible rows; needed if you plan to add/remove rows with the pager enabled.
                    removeRows: false,
                    // output string - default is '{page}/{totalPages}';
                    // possible variables: {page}, {totalPages}, {filteredPages}, {startRow}, {endRow}, {filteredRows} and {totalRows}
                    output: '{startRow} - {endRow} / {filteredRows} ({totalRows})',
                    size: 50
                });
            }
        });
    },

    getLeadsVPGuideur: function(type, pilote) {
        if (pilote == 2) {
            $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, type: type, req: 'getLeadsGuideur' }, function(data) {
                if (data.nbResult > 0) {
                    // $('#leadsCont') to be filled

                    $('#leadsCont').empty().append(data.leads);
                } else if (data.nbResult == 0) {
                    const returns = '<div class="alert alert-info" role="alert"><b>Toutes les propositions d\'escorte et/ou guidage ont été satisfaites.</b></div>';
                    $('#leadsCont').empty().append(returns);
                }
            }, "json").done(function(data) {
                if (data.ok == 'ok') {
                    $('#tableLeads').tablesorter({
                        theme: "bootstrap", // dark or bootstrap are cool here.
                        widthFixed: true,
                        dateFormat: "ddmmyyyy", // set the default date format
                        // widget code contained in the jquery.tablesorter.widgets.js file
                        // use the zebra stripe widget if you plan on hiding any rows (filter widget)
                        // the uitheme widget is NOT REQUIRED!
                        widgets: ["filter"],
                        ignoreCase: true,
                        // sort on the first column and second column in ascending order
                        sortList: [
                            [7, 0],
                            [3, 1]
                        ],
                        widgetOptions: {
                            // using the default zebra striping class name, so it actually isn't included in the theme variable above
                            // this is ONLY needed for bootstrap theming if you are using the filter widget, because rows are hidden
                            // zebra : ["even", "odd"],
                            // reset filters button
                            filter_reset: ".reset",
                            filter_childRows: false,
                            filter_columnFilters: true,
                            filter_cellFilter: '',
                            filter_defaultFilter: {},
                            filter_excludeFilter: {},
                            filter_external: '',
                            filter_filteredRow: 'filtered',
                            filter_formatter: null,
                            filter_functions: null,
                            filter_hideEmpty: true,
                            filter_hideFilters: false,
                            filter_ignoreCase: true,
                            filter_liveSearch: true,
                            filter_onlyAvail: 'filter-onlyAvail',
                            filter_placeholder: { search: 'Filtre', select: '' },
                            filter_saveFilters: false,
                            filter_searchDelay: 300,
                            filter_searchFiltered: true,
                            filter_selectSource: null,
                            filter_serversideFiltering: false,
                            filter_startsWith: false,
                            filter_useParsedData: false,
                            filter_defaultAttrib: 'data-value',
                            filter_selectSourceSeparator: '|',
                            // extra css class name (string or array) added to the filter element (input or select)
                            filter_cssFilter: [
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                            ],
                        }
                    }).tablesorterPager({
                        // target the pager markup - see the HTML block below
                        container: $('#leadsPager'),
                        // target the pager page select dropdown - choose a page
                        cssGoto: ".pagenum",
                        // remove rows from the table to speed up the sort of large tables.
                        // setting this to false, only hides the non-visible rows; needed if you plan to add/remove rows with the pager enabled.
                        removeRows: false,
                        // output string - default is '{page}/{totalPages}';
                        // possible variables: {page}, {totalPages}, {filteredPages}, {startRow}, {endRow}, {filteredRows} and {totalRows}
                        output: '{startRow} - {endRow} / {filteredRows} ({totalRows})',
                        size: 50
                    });
                }
            });
        }
        else if (pilote == 1) {
            $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, type: type, req: 'getLeadsVP' }, function(data) {
                if (data.nbResult > 0) {
                    // $('#leadsCont') to be filled
                    $('#leadsCont').empty().append(data.leads);
                } else if (data.nbResult == 0) {
                    const returns = '<div class="alert alert-info" role="alert"><b>Toutes les propositions d\'escorte et/ou guidage ont été satisfaites.</b></div>';
                    $('#leadsCont').empty().append(returns);
                }
            }, "json").done(function(data) {
                if (data.ok == 'ok') {
                    $('#tableLeads').tablesorter({
                        theme: "bootstrap", // dark or bootstrap are cool here.
                        widthFixed: true,
                        dateFormat: "ddmmyyyy", // set the default date format
                        // widget code contained in the jquery.tablesorter.widgets.js file
                        // use the zebra stripe widget if you plan on hiding any rows (filter widget)
                        // the uitheme widget is NOT REQUIRED!
                        widgets: ["filter"],
                        ignoreCase: true,
                        // sort on the first column and second column in ascending order
                        sortList: [
                            [7, 0],
                            [3, 1]
                        ],
                        widgetOptions: {
                            // using the default zebra striping class name, so it actually isn't included in the theme variable above
                            // this is ONLY needed for bootstrap theming if you are using the filter widget, because rows are hidden
                            // zebra : ["even", "odd"],
                            // reset filters button
                            filter_reset: ".reset",
                            filter_childRows: false,
                            filter_columnFilters: true,
                            filter_cellFilter: '',
                            filter_defaultFilter: {},
                            filter_excludeFilter: {},
                            filter_external: '',
                            filter_filteredRow: 'filtered',
                            filter_formatter: null,
                            filter_functions: null,
                            filter_hideEmpty: true,
                            filter_hideFilters: false,
                            filter_ignoreCase: true,
                            filter_liveSearch: true,
                            filter_onlyAvail: 'filter-onlyAvail',
                            filter_placeholder: { search: 'Filtre', select: '' },
                            filter_saveFilters: false,
                            filter_searchDelay: 300,
                            filter_searchFiltered: true,
                            filter_selectSource: null,
                            filter_serversideFiltering: false,
                            filter_startsWith: false,
                            filter_useParsedData: false,
                            filter_defaultAttrib: 'data-value',
                            filter_selectSourceSeparator: '|',
                            // extra css class name (string or array) added to the filter element (input or select)
                            filter_cssFilter: [
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                                'form-control',
                            ],
                        }
                    }).tablesorterPager({
                        // target the pager markup - see the HTML block below
                        container: $('#leadsPager'),
                        // target the pager page select dropdown - choose a page
                        cssGoto: ".pagenum",
                        // remove rows from the table to speed up the sort of large tables.
                        // setting this to false, only hides the non-visible rows; needed if you plan to add/remove rows with the pager enabled.
                        removeRows: false,
                        // output string - default is '{page}/{totalPages}';
                        // possible variables: {page}, {totalPages}, {filteredPages}, {startRow}, {endRow}, {filteredRows} and {totalRows}
                        output: '{startRow} - {endRow} / {filteredRows} ({totalRows})',
                        size: 50
                    });
                }
            });
        }
    },
    addLeads: function(myFormDiv) {
        let nb_vp_av = $(myFormDiv + ' #vp_av').val();
        let nb_vp_ar = $(myFormDiv + ' #vp_ar').val();
        let nb_guides = $(myFormDiv + ' #guides').val();
        let isAnyDriver = (nb_vp_av != 0 || nb_vp_ar != 0 || nb_guides != 0) ? true : false;
        //alert(isAnyDriver+" - "+nb_vp_av+" - "+nb_vp_ar+" - "+nb_guides);
        if (isAnyDriver) {
            $(myFormDiv + ' #sender').attr("disabled", true);
            let query = $(myFormDiv).serialize();
            let req = "addLeads";
            query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
            let returns = "";
            //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
            $.post(globals.serverAddress, query, function(data) {
                if (data.ok == "ok") {
                    returns = '<div class="alert alert-success" role="alert"><b>Votre demande de VP/Guideur a bien été prise en compte. Vous serez averti par mail dès qu’un VP/Guideur se sera positionné sur votre convoi.</b></div>';
                    App.getLeads('loadEvent', true);
                    setTimeout(function() {
                        App.clearFormFields(myFormDiv);
                        $(myFormDiv).closest('.modal').modal('hide');
                    }, 3000);
                }
                else returns = '<div class="alert alert-danger" role="alert"><b>Votre demande de VP/Guideur n\'a pas été prise en compte suite à un problème technique.</b></div>';
            }, "json").always(function(data) {
                $(myFormDiv + ' #sender').attr("disabled", false);
                $(myFormDiv + ' #successfail').empty().append(returns);
            });
        } else alert("Votre demande de convoi doit requérir au moins un pilote ou guideur !");
    },
    fillJobDocument: function(lead_id, addr_l, addr_comp_l, codePostal_l, city_l, addr_dest_l, addr_dest_comp_l, codePostal_dest_l, city_dest_l, cat_l, num_arr_pref, name_l, tel_l, datedeb_l, datefin_l, long_l, large_l, height_l, weight_l, est_time, est_km, vp_av, vp_ar, guides, imat_tr, imat_sr, name_st, marchandise_l) {
        // First time filling it...
        $('#formJobDocument #lead_id').val(lead_id);
        $('#formJobDocument #number_bdt').val(lead_id).attr('readonly', true);
        $('#formJobDocument #date_cv').val(datedeb_l);
        $('#formJobDocument #transp_name').val(name_st);
        $('#formJobDocument #nat_charge').val(marchandise_l);
        $('#formJobDocument #imat_tr').val(imat_tr);
        $('#formJobDocument #imat_sr').val(imat_sr);
        $('#formJobDocument #imat_vp').val(globals.imat);
        $('#formJobDocument #large').val(large_l);
        $('#formJobDocument #long').val(long_l);
        $('#formJobDocument #height').val(height_l);
        $('#formJobDocument #weight').val(weight_l);
        $('#formJobDocument #categorie').val(cat_l);
        $('#formJobDocument #num_pref').val(num_arr_pref);
        $('#formJobDocument #name_drv').val(name_l);
        $('#formJobDocument #name_vp').val(globals.nom);
    },
    fillJobDocumentRelais: function(lead_id, addr_r, addr_comp_r, codePostal_r, city_r, addr_dest_r, addr_dest_comp_r, codePostal_dest_r, city_dest_r, cat_r, num_arr_pref, name_r, tel_r, datedeb_r, datefin_r, long_r, large_r, height_r, weight_r, est_time, est_km, vp_av, vp_ar, guides, imat_tr, imat_sr, name_st, marchandise_r) {
        // First time filling it...
        $('#formJobDocumentRelais #lead_id').val(lead_id);
        $('#formJobDocumentRelais #number_bdt').val(lead_id).attr('readonly', true);
        $('#formJobDocumentRelais #date_cv').val(datedeb_r);
        $('#formJobDocumentRelais #transp_name').val(name_st);
        $('#formJobDocumentRelais #nat_charge').val(marchandise_r);
        $('#formJobDocumentRelais #imat_tr').val(imat_tr);
        $('#formJobDocumentRelais #imat_sr').val(imat_sr);
        $('#formJobDocumentRelais #imat_vp').val(globals.imat);
        $('#formJobDocumentRelais #large').val(large_r);
        $('#formJobDocumentRelais #long').val(long_r);
        $('#formJobDocumentRelais #height').val(height_r);
        $('#formJobDocumentRelais #weight').val(weight_r);
        $('#formJobDocumentRelais #categorie').val(cat_r);
        $('#formJobDocumentRelais #num_pref').val(num_arr_pref);
        $('#formJobDocumentRelais #name_drv').val(name_r);
        $('#formJobDocumentRelais #name_vp').val(globals.nom);
    },
    InsertJobDocument: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true).html('<i class="fa fa-spinner"></i>&nbsp;Veuillez patienter');
        let query = $(myFormDiv).serialize();
        let req = "insertJobDocument";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        var returns = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                returns = '<div class="alert alert-success" role="alert"><b>Le bon de transport à été sauvegardé.</b></div>';
                $('#fillJobDocument #getPdfCont').empty().append('<button class="btn btn-danger btn-block" id="getPdfBtn" onclick="App.getPdf(\'' + data.auto_ld + '\', false, this)"><i class="fa fa-file-pdf-o"></i> Télécharger</button>');
                setTimeout(function() { App.clearFormFields(myFormDiv); }, 3000);
            } else returns = '<div class="alert alert-danger" role="alert"><b>Le bon de transport n\'a pas été sauvegardé suite à un problème technique.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false).html('<i class="fa fa-check-circle"></i>&nbsp;Enregistrer');
            $(myFormDiv + ' #successfail').empty().append(returns);
        });
    },
    InsertJobDocumentRelais: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true).html('<i class="fa fa-spinner"></i>&nbsp;Veuillez patienter');
        let query = $(myFormDiv).serialize();
        let req = "insertJobDocumentRelais";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        var returns = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                returns = '<div class="alert alert-success" role="alert"><b>Le bon de transport à été sauvegardé.</b></div>';
                $('#fillJobDocumentRelais #getPdfCont').empty().append('<button class="btn btn-danger btn-block" id="getPdfBtn" onclick="App.getPdf(\'' + data.auto_ld + '\', false, this)"><i class="fa fa-file-pdf-o"></i> Télécharger</button>');
                setTimeout(function() { App.clearFormFields(myFormDiv); }, 3000);
            } else returns = '<div class="alert alert-danger" role="alert"><b>Le bon de transport n\'a pas été sauvegardé suite à un problème technique.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false).html('<i class="fa fa-check-circle"></i>&nbsp;Enregistrer');
            $(myFormDiv + ' #successfail').empty().append(returns);
        });
    },
    fillRelais: function(myFormDiv, auto_l, transp_l, ack_l, addr_l, addr_comp_l, codePostal_l, city_l, addr_dest_l, addr_dest_comp_l, codePostal_dest_l, city_dest_l, cat_l, num_arr_pref, name_l, tel_l, datedeb_l, heuredeb_l, datefin_l, heurefin_l, long, large, height, weight, marchandise_l, est_time, est_km, vp_av, vp_ar, guides, imat_tr, imat_sr) {
        $('#relaiModal').modal('show');
        $(myFormDiv + ' #auto_l').val(auto_l);
        $(myFormDiv + ' #transp_l').val(transp_l);
        $(myFormDiv + ' #ack_l').val(ack_l);
        $(myFormDiv + ' #addr_l').val(addr_l);
        $(myFormDiv + ' #addr_comp_l').val(addr_comp_l);
        $(myFormDiv + ' #codePostal_l').val(codePostal_l);
        $(myFormDiv + ' #city_l').val(city_l);
        $(myFormDiv + ' #addr_dest_l').val(addr_dest_l);
        $(myFormDiv + ' #addr_dest_comp_l').val(addr_dest_comp_l);
        $(myFormDiv + ' #codePostal_dest_l').val(codePostal_dest_l);
        $(myFormDiv + ' #city_dest_l').val(city_dest_l);
        $(myFormDiv + ' #cat_l').val(cat_l);
        $(myFormDiv + ' #num_arr_pref').val(num_arr_pref);
        $(myFormDiv + ' #name_l').val(name_l);
        $(myFormDiv + ' #tel_l').val(tel_l);
        $(myFormDiv + ' #datedeb_l').val(datedeb_l);
        $(myFormDiv + ' #heuredeb_l').val(heuredeb_l);
        $(myFormDiv + ' #datefin_l').val(datefin_l);
        $(myFormDiv + ' #heurefin_l').val(heurefin_l);
        $(myFormDiv + ' #long').val(long);
        $(myFormDiv + ' #large').val(large);
        $(myFormDiv + ' #height').val(height);
        $(myFormDiv + ' #weight').val(weight);
        $(myFormDiv + ' #marchandise_l').val(marchandise_l);
        $(myFormDiv + ' #est_time').val(est_time);
        $(myFormDiv + ' #est_km').val(est_km);
        $(myFormDiv + ' #vp_av').val(vp_av);
        $(myFormDiv + ' #vp_ar').val(vp_ar);
        $(myFormDiv + ' #guides').val(guides);
        $(myFormDiv + ' #imat_tr').val(imat_tr);
        $(myFormDiv + ' #imat_sr').val(imat_sr);

    },
    activateRelay: function(myFormDiv) {

        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "activateRelay";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == 'ok') {
                alert("Vous avez bien activé un relais")
            } else {
                alert("Le relais que vous souhaitez activez a rencontrer un problème technique")
            }
            App.tableFlow();
        }, "json").always(function() {});

    },
    activateButton: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", false);
    },
    desactivateButton: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
    },
    recupDate: function(idDateDep, idDateFin) {
        let dateDepart = $(idDateDep).val();
        let tab = dateDepart.split("/", 3);
        dateDepart = tab[2] + "/" + tab[1] + "/" + tab[0];
        dateDepart = new Date(dateDepart);
        $(idDateFin).datepicker("option", "minDate", dateDepart);
    },
    fillContact: function(myFormDiv, name_drv, phone_drv, imat_tr, imat_sr) {
        $(myFormDiv + ' #name_drv').val(name_drv);
        $(myFormDiv + ' #phone_drv').val(phone_drv);
        $(myFormDiv + ' #imat_tr').val(imat_tr);
        $(myFormDiv + ' #imat_sr').val(imat_sr);
    },
    recupNom: function(myFormDiv) {
        let query = $(myFormDiv).serialize();
        let req = "recupNom";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                App.fillContact(myFormDiv, data.name, data.tel, data.imat_tr, data.imat_sr);
            } else {}
        }, "json");
    },
    recupVilleDep: function(myFormDiv, zipId, cityId) {
        const req = "recupVilleDep";
        const codePostal = $(zipId).val();
        const query = "id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req + "&myFormDiv=" + myFormDiv + "&cityId=" + cityId + "&codePostal=" + codePostal;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                $(myFormDiv + " " + cityId).val(data.ville);
            } else if (data.ok == "popup") {
                $("#villeToCPDiv").empty().append(data.snippet);
                $('#villeToCP').modal('show');
            } else {}
        }, "json");
    },
    choixVille: function(myFormDiv, cityId, selectBox) {
        const villeChoisie = $(selectBox).val();
        $(myFormDiv + " " + cityId).val(villeChoisie);
        $(document).ready(function() {
            $('.modal').on("hidden.bs.modal", function(e) { //fire on closing modal box
                if ($('.modal:visible').length) { // check whether parent modal is opend after child modal close
                    $('body').addClass('modal-open'); // if open mean length is 1 then add a bootstrap css class to body of the page
                }
            });
        });
        $('#villeToCP').modal('hide');
    },
    recupCpVille: function(myFormDiv, cityId, zipId) {
        const req = "recupCpVille";
        const ville = $(cityId).val();
        const query = "id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req + "&myFormDiv=" + myFormDiv + "&ville=" + ville + "&zipId=" + zipId;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                $(myFormDiv + " " + zipId).val(data.codePostal);
            }
        }, "json");
    },
    changePilote: function(myFormDiv) {
        //(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "changePilote";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                $(myFormDiv + ' #successfail').append('<div class="alert alert-success" role="alert"><b>La modification a bien été effectué</b></div>');
                App.tableFlow();

            } else {
                $(myFormDiv + ' #successfail').append('<div class="alert alert-danger" role="alert"><b>La modification a rencontré un problème</b></div>');
            }
        }, "json");
    },
    ajoutPilote: function(myFormDiv) {
        let query = $(myFormDiv).serialize();
        let req = "ajoutPilote";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                $(myFormDiv + ' #successfail').append('<div class="alert alert-success" role="alert"><b>L\'ajout a bien été effectué</b></div>');
                App.tableFlow();

            } else {
                $(myFormDiv + ' #successfail').append('<div class="alert alert-danger" role="alert"><b>L\'ajout a rencontré un problème</b></div>');
            }
        }, "json");
    },
    ajoutPiloteRelais: function(myFormDiv) {
        let query = $(myFormDiv).serialize();
        let req = "ajoutPiloteRelais";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                $(myFormDiv + ' #successfail').append('<div class="alert alert-success" role="alert"><b>L\'ajout a bien été effectué</b></div>');
                App.tableFlow();

            } else {
                $(myFormDiv + ' #successfail').append('<div class="alert alert-danger" role="alert"><b>L\'ajout a rencontré un problème</b></div>');
            }
        }, "json");
    },
    selectPilote: function(lead_id, auto_lf, cond, onglet, number_relais) {
        const req = "selectPilote";
        const query = "id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&lead_id=" + lead_id + "&auto_lf=" + auto_lf + "&cond=" + cond + "&onglet=" + onglet + "&number_relais=" + number_relais + "&req=" + req
        $.post(globals.serverAddress, query, function(data) {
            $('#changePiloteDiv').empty().append(data.snippet);
            $('#changePiloteModal').modal('show');
        }, "json");
    },
    DelPiloteOnLead: function(lead_id, auto_lf, auto_p, onglet, number_relais) {
        const req = "DelPiloteOnLead";
        const query = "id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&lead_id=" + lead_id + "&auto_lf=" + auto_lf + "&auto_p=" + auto_p + "&onglet=" + onglet + "&number_relais=" + number_relais + "&req=" + req
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                alert("le pilote a bien été enlevé du convoi")
            } else {
                alert("une erreur est survenue, si cela se reproduit veuillez contacter le service client")
            }
            if (data.onglet == "lead") {
                App.tableFlow();
            } else {
                App.tableRelais();
            }
        }, "json");

    },
    infoLead: function(lead_id) {
        const req = "infoLead";
        const query = "id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&lead_id=" + lead_id + "&req=" + req
        $.post(globals.serverAddress, query, function(data) {
            $('#infoLead').empty().append(data.snippet);
            $('#infoLeadModal').modal('show');
        }, "json");

    },
    switchModal: function(modalOffDiv, modalOnDiv) {
        $(modalOffDiv).modal('hide');
        setTimeout(function() {
            $(modalOnDiv).modal('show');
        }, 500);
    },
    modLeads: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "modLeads";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        let returns = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                returns = '<div class="alert alert-success" role="alert"><b>Votre demande de convoi a bien été modifiée.</b></div>';
                App.getLeads('loadEvent', true);
                setTimeout(function() {
                    App.clearFormFields(myFormDiv);
                    $(myFormDiv).closest('.modal').modal('hide');
                }, 3000);
            } else returns = '<div class="alert alert-danger" role="alert"><b>Votre demande de convoi n\'a pas été modifiée suite à un problème technique.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
            $(myFormDiv + ' #successfail').empty().append(returns);
        });
    },
    fillModLeads: function(auto_l, addr_l, addr_comp_l, codePostal_l, city_l, addr_dest_l, addr_dest_comp_l, codePostal_dest_l, city_dest_l, cat_l, num_arr_pref, name_l, tel_l, datedeb_l, heuredeb_l, datefin_l, heurefin_l, long_l, large_l, height_l, weight_l, marchandise_l, est_time, est_km, vp_av, vp_ar, guides, imat_tr, imat_sr) {
        $('#modFormLeads #auto_l').val(auto_l);
        $('#modFormLeads #addressAutoMod').val(addr_l);
        $('#modFormLeads #address_comp').val(addr_comp_l);
        $('#modFormLeads #codePostalMod').val(codePostal_l);
        $('#modFormLeads #cityDepMod').val(city_l);
        $('#modFormLeads #addressDestAutoMod').val(addr_dest_l);
        $('#modFormLeads #address_dest_comp').val(addr_dest_comp_l);
        $('#modFormLeads #codePostalDestMod').val(codePostal_dest_l);
        $('#modFormLeads #cityDestMod').val(city_dest_l);
        $('#modFormLeads #dateDepMod').val(datedeb_l);
        $('#modFormLeads #heureDepMod').val(heuredeb_l);
        $('#modFormLeads #dateFinMod').val(datefin_l);
        $('#modFormLeads #heureFinMod').val(heurefin_l);
        $('#modFormLeads #categorie').val(cat_l);
        $('#modFormLeads #long').val(long_l);
        $('#modFormLeads #large').val(large_l);
        $('#modFormLeads #height').val(height_l);
        $('#modFormLeads #weight').val(weight_l);
        $('#modFormLeads #marchandise_l').val(marchandise_l);
        $('#modFormLeads #est_time').val(est_time);
        $('#modFormLeads #est_km').val(est_km);
        $('#modFormLeads #num_pref').val(num_arr_pref);
        $('#modFormLeads #vp_av').val(vp_av);
        $('#modFormLeads #vp_ar').val(vp_ar);
        $('#modFormLeads #guides').val(guides);
        $('#modFormLeads #name_drv').val(name_l);
        $('#modFormLeads #phone_drv').val(tel_l);
        $('#modFormLeads #imat_tr').val(imat_tr);
        $('#modFormLeads #imat_sr').val(imat_sr);
        // Fill the delLeadCont with detLead Button (auto_l)
        let delLeadBtn = '<a href="#modal-header" class="btn btn-danger btn-block" id="delLeadBtn" onClick="App.delLeads(\'' + auto_l + '\')"><i class="fa fa-eraser"></i> Supprimer</a>';
        $('#modFormLeads #delLeadCont').empty().append(delLeadBtn);
    },
    modRelais: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "modRelais";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        let returns = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                returns = '<div class="alert alert-success" role="alert"><b>Votre demande de relais a bien été modifiée.</b></div>';
                App.tableRelais();
                setTimeout(function() {
                    App.clearFormFields(myFormDiv);
                    $(myFormDiv).closest('.modal').modal('hide');
                }, 3000);
            } else returns = '<div class="alert alert-danger" role="alert"><b>Votre demande de relais n\'a pas été modifiée suite à un problème technique.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
            $(myFormDiv + ' #successfail').empty().append(returns);
        });
    },

    fillModRelais: function(auto_r, lead_r, addr_r, addr_comp_r, codePostal_r, city_r, addr_dest_r, addr_dest_comp_r, codePostal_dest_r, city_dest_r, cat_r, num_arr_pref, name_r, tel_r, datedeb_r, heuredeb_r, datefin_r, heurefin_r, long_r, large_r, height_r, weight_r, marchandise_r, est_time, est_km, vp_av, vp_ar, guides, imat_tr, imat_sr) {
        $('#modFormRelais #auto_r').val(auto_r);
        $('#modFormRelais #lead_r').val(lead_r);
        $('#modFormRelais #addressAutoMod').val(addr_r);
        $('#modFormRelais #address_comp').val(addr_comp_r);
        $('#modFormRelais #codePostalMod').val(codePostal_r);
        $('#modFormRelais #cityDepMod').val(city_r);
        $('#modFormRelais #addressDestAutoMod').val(addr_dest_r);
        $('#modFormRelais #address_dest_comp').val(addr_dest_comp_r);
        $('#modFormRelais #codePostalDestMod').val(codePostal_dest_r);
        $('#modFormRelais #cityDestMod').val(city_dest_r);
        $('#modFormRelais #dateDepMod').val(datedeb_r);
        $('#modFormRelais #heureDepMod').val(heuredeb_r);
        $('#modFormRelais #dateFinMod').val(datefin_r);
        $('#modFormRelais #heureFinMod').val(heurefin_r);
        $('#modFormRelais #categorie').val(cat_r);
        $('#modFormRelais #long').val(long_r);
        $('#modFormRelais #large').val(large_r);
        $('#modFormRelais #height').val(height_r);
        $('#modFormRelais #weight').val(weight_r);
        $('#modFormRelais #marchandise_l').val(marchandise_r);
        $('#modFormRelais #est_time').val(est_time);
        $('#modFormRelais #est_km').val(est_km);
        $('#modFormRelais #num_pref').val(num_arr_pref);
        $('#modFormRelais #vp_av').val(vp_av);
        $('#modFormRelais #vp_ar').val(vp_ar);
        $('#modFormRelais #guides').val(guides);
        $('#modFormRelais #name_drv').val(name_r);
        $('#modFormRelais #phone_drv').val(tel_r);
        $('#modFormRelais #imat_tr').val(imat_tr);
        $('#modFormRelais #imat_sr').val(imat_sr);
        // Fill the delLeadCont with detLead Button (auto_l)
        let delRelaiBtn = '<a href="#modal-header" class="btn btn-danger btn-block" id="delRelaiBtn" onClick="App.delRelais(\'' + auto_r + '\')"><i class="fa fa-eraser"></i> Supprimer</a>';
        $('#modFormRelais #delRelaiCont').empty().append(delRelaiBtn);
    },
    delLeads: function(auto_l) {
        $('#delLeadBtn').attr("disabled", true);
        let confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer cette demande de convoi ?");
        if (confirmDeletion) {
            let req = "delLeads";
            let query = "&id=" + globals.id + "&pwd=" + globals.pwd + "&auto_l=" + auto_l + "&req=" + req;
            $.post(globals.serverAddress, query, function(data) {
                if (data.ok == "ok") {
                    alert('Cette demande de convoi a bien été supprimée');
                    $('#modLeadModal').modal('toggle');
                    App.getLeads('loadEvent', true);
                } else
                    alert("Suite à un problème technique, cette demande de convoi n'a pas été supprimée.");
            }, "json").always(function(data) {
                $('#delLeadBtn').attr("disabled", false);
            });
        } else $('#delLeadBtn').attr("disabled", false);
    },
    delRelais: function(auto_r) {
        $('#delRelaiBtn').attr("disabled", true);
        const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer cette demande de relai ?");
        if (confirmDeletion) {
            let req = "delRelais";
            let query = "&id=" + globals.id + "&pwd=" + globals.pwd + "&auto_r=" + auto_r + "&req=" + req;
            $.post(globals.serverAddress, query, function(data) {
                if (data.ok == "ok") {
                    alert('Cette demande de convoi a bien été supprimée');
                    $('#modRelaiModal').modal('toggle');
                    App.getLeads('loadEvent', true);
                } else
                    alert("Suite à un problème technique, cette demande de relai n'a pas été supprimée.");
            }, "json").always(function(data) {
                $('#delRelaiBtn').attr("disabled", false);
            });
        } else $('#delRelaiBtn').attr("disabled", false);
    },
    answerLeads: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "answerLeads";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&mail_sp=" + globals.email + "&req=" + req;
        let returns = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                returns = '<div class="alert alert-success" role="alert"><b>Cette participation à ce convoi a bien été prise en compte.</b></div>';
                setTimeout(function() {
                    $(myFormDiv).closest('.modal').modal('hide');
                    App.clearFormFields(myFormDiv);
                }, 3000);
            }
            else if (data.control < 0)
                returns = '<div class="alert alert-danger" role="alert"><b>Cette participation à ce convoi n\'a pas été prise en compte car vous avez sélectionné trop de pilotes / guides !</b></div>';
            else if (data.is_this_lead_full < 0)
                returns = '<div class="alert alert-danger" role="alert"><b>Cette participation à ce convoi n\'a pas été prise en compte car cette demande de convois a été satisfaite entre temps !</b></div>';
            else
                returns = '<div class="alert alert-danger" role="alert"><b>Cette participation à ce convoi n\'a pas été prise en compte suite à un problème technique.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
            $(myFormDiv + ' #successfail').empty().append(returns);
            //Reset Select boxes & empty #listDrivers
            $(myFormDiv + ' #vp_av').val(0);
            $(myFormDiv + ' #vp_ar').val(0);
            $(myFormDiv + ' #guides').val(0);
            $('#listDrivers').empty();
            App.getLeads('loadEvent', true); // Refresh leads list anyway !
        });
    },
    answerRelais: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "answerRelais";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&mail_sp=" + globals.email + "&req=" + req;
        var returns = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                returns = '<div class="alert alert-success" role="alert"><b>Cette participation à ce convoi a bien été prise en compte.</b></div>';
                App.getRelaisST();
                setTimeout(function() {
                    $(myFormDiv).closest('.modal').modal('hide');
                    App.clearFormFields(myFormDiv);
                }, 3000);
            } else if (data.control < 0)
                returns = '<div class="alert alert-danger" role="alert"><b>Cette participation à ce convoi n\'a pas été prise en compte car vous avez sélectionné trop de pilotes / guides !</b></div>';
            else
                returns = '<div class="alert alert-danger" role="alert"><b>Cette participation à ce convoi n\'a pas été prise en compte suite à un problème technique.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
            $(myFormDiv + ' #successfail').empty().append(returns);
            //Reset Select boxes & empty #listDrivers
            $(myFormDiv + ' #vp_av').val(0);
            $(myFormDiv + ' #vp_ar').val(0);
            $(myFormDiv + ' #guides').val(0);
            $('#listDrivers').empty();
        });
    },
    fillAnswerLeads: function(auto_l, addr_l, addr_comp_l, codePostal_l, city_l, addr_dest_l, addr_dest_comp_l, codePostal_dest_l, city_dest_l, cat_l, num_arr_pref, name_l, tel_l, datedeb_l, datefin_l, long_l, large_l, height_l, weight_l, marchandise_l, est_time, est_km, vp_av, vp_ar, guides, imat_tr, imat_sr) {
        // Searching leads_full to look for vacant jobs in the lead...
        let req = "vacantJobsInLead";
        let query = "&auto_l=" + auto_l + "&vp_av=" + vp_av + "&vp_ar=" + vp_ar + "&guides=" + guides + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        let vacantAv = vp_av; // vacants equal needed for now.
        let vacantAr = vp_ar;
        let vacantGm = guides;
        let alreadyPosted = "";
        if (addr_l == '') addr_l = city_l;
        if (addr_dest_l == '') addr_dest_l = city_dest_l;
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            vacantAv = data.vp_av;
            vacantAr = data.vp_ar;
            vacantGm = data.guides;
            alreadyPosted = JSON.parse(data.posted);
        }, "json").always(function(data) {
            // Generate lead's details popup and form
            let snippet = '<form action="javascript:App.answerLeads(\'#answerFormLeads\');" method="get" id="answerFormLeads" class="accordion-color">';
            snippet += '<input type="hidden" name="auto_l" id="auto_l" value="' + auto_l + '">';
            snippet += '<div class="card text-center w-auto border-warning accordion-color">';
            snippet += '<h4 class="card-header border-warning colorClair">Identifiant de Convoi<span> #' + auto_l + '</span></h4>';
            snippet += '<div class="card-body"><h5 class="card-title colorClair"><span>Prévu du </span>' + datedeb_l + '<span style="color:#FA0;"> au </span>' + datefin_l + '</h5></div>';
            snippet += '<ul class="list-group list-group-flush">';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Départ : </span>' + addr_l + ' (Dep.' + codePostal_l + ') - ' + addr_comp_l + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Arrivée : </span>' + addr_dest_l + ' (Dep.' + codePostal_dest_l + ') - ' + addr_dest_comp_l + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Besoin de: </span>' + vp_av + ' VP avant(s), ' + vp_ar + ' VP arrière(s), ' + guides + ' Guideur(s)</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:red;">Postes vacants: ' + vacantAv + ' VP avant(s), ' + vacantAr + ' VP arrière(s), ' + vacantGm + ' Guideur(s)</span></b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Convoi de catégorie : </span>' + cat_l + '<span style="color:#FA0;"> | N&deg;Arrêté préfectoral : </span>' + num_arr_pref + '</b></li>';
            // snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Durée et kilométrage estimatif du convoi : </span>' + est_time + ' pour ' + est_km + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Marchandise transportée : </span>' + marchandise_l + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Longueur : </span>' + long_l + '<span style="color:#FA0;"> x Largeur : </span>' + large_l + '<span style="color:#FA0;"> x Hauteur : </span>' + height_l + '<span style="color:#FA0;"> et MTR : </span>' + weight_l + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Immatriculation: TR (Tracteur) </span>' + imat_tr + '<span style="color:#FA0;"> | SR (Remorque) </span>' + imat_sr + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Contact conducteur : </span>' + name_l + ' / ' + tel_l + '</b></li>';
            // Show drivers already affected to it.
            //alert(jsonPilotes.data[0].id+' '+jsonPilotes.data[0].name+' '+jsonPilotes.data[0].imat+' '+jsonPilotes.data[0].type+' '+jsonPilotes.data[0].tel+' '+jsonPilotes.data[0].mail);
            if (alreadyPosted.data) {
                snippet += '<li class="list-group-item"><b><span style="color:#09c527;">Vos pilotes en poste sur ce convois: <br>';
                for (let i = 0; i < alreadyPosted.data.length; i++) {
                    //driverChecks += alreadyPosted.data[i].id+' '+alreadyPosted.data[i].imat+' '+alreadyPosted.data[i].poste;
                    snippet += 'N&deg' + alreadyPosted.data[i].id + ' - ' + alreadyPosted.data[i].imat + ' au poste de ' + alreadyPosted.data[i].poste + ' | ';
                }
                snippet += '</span></b></li>';
            }
            snippet += '</ul>';
            snippet += '<div class="card-body">';
            snippet += '<div class="row">';
            // adding drivers management to fill the lead
            snippet += '<div class="col-12 col-sm-12 col-md-12 col-lg-4"><div class="form-group"><label for="vp_av">VP Avant(s)</label><div class="input-group"><div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-truck"></i></span></div><select class="form-control" name="vp_av" id="vp_av" onchange="App.listDrivers(\'VP\', \'#vp_ar\', \'#guides\')"';
            snippet += (vacantAv > 0) ? '>' : ' disabled>';
            // <option value="0" selected>0</option><option value="1">1</option><option value="2">2</option><option value="3">3</option>
            for (let i = 0; i <= vacantAv; i++) {
                snippet += '<option value="' + i + '">' + i + '</option>';
            }
            snippet += '</select></div></div></div>';
            snippet += '<div class="col-12 col-sm-12 col-md-12 col-lg-4"><div class="form-group"><label for="vp_ar">VP Arrière(s)</label><div class="input-group"><div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-truck"></i></span></div><select class="form-control" name="vp_ar" id="vp_ar" onchange="App.listDrivers(\'VP\', \'#vp_av\', \'#guides\')"';
            snippet += (vacantAr > 0) ? '>' : ' disabled>';
            // <option value="0" selected>0</option><option value="1">1</option><option value="2">2</option><option value="3">3</option>
            for (let j = 0; j <= vacantAr; j++) {
                snippet += '<option value="' + j + '">' + j + '</option>';
            }
            snippet += '</select></div></div></div>';
            snippet += '<div class="col-12 col-sm-12 col-md-12 col-lg-4"><div class="form-group"><label for="guides">Guideur(s)</label><div class="input-group"><div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-motorcycle"></i></span></div><select class="form-control" name="guides" id="guides" onchange="App.listDrivers(\'GM\', \'#vp_av\', \'#vp_ar\')"';
            snippet += (vacantGm > 0) ? '>' : ' disabled>';
            // <option value="0" selected>0</option><option value="1">1</option><option value="2">2</option><option value="3">3</option>
            for (let k = 0; k <= vacantGm; k++) {
                snippet += '<option value="' + k + '">' + k + '</option>';
            }
            snippet += '</select></div></div></div>';
            snippet += '</div>'; // End row
            snippet += '<div id="listDrivers"></div>';
            //snippet += '<button class="btn btn-warning btn-block" id="sender" onclick="App.addWasValidatedClass(\'#answerFormLeads\')" type="submit">Valider <i class="fa fa-check-circle"></i></button>';
            snippet += '<div id="successfail"></div>';
            snippet += '</div>'; // End card-body
            snippet += '</form>';
            snippet += '<div class="card-footer border-warning"><h4><i class="fa fa-chevron-circle-up"></i> Positionnez-vous <i class="fa fa-chevron-circle-up"></i></h4></div>';
            $('#answerLeadsCont').empty().append(snippet);
        });
    },
    fillAnswerRelais: function(lead_r, number_relais, addr_r, addr_comp_r, codePostal_r, city_r, addr_dest_r, addr_dest_comp_r, codePostal_dest_r, city_dest_r, cat_r, num_arr_pref, name_r, tel_r, datedeb_r, datefin_r, long_r, large_r, height_r, weight_r, marchandise_r, est_time, est_km, vp_av, vp_ar, guides, imat_tr, imat_sr) {
        // Searching leads_full to look for vacant jobs in the lead...
        let req = "vacantJobsInRelai";
        let query = "&lead_r=" + lead_r + "&number_relais=" + number_relais + "&vp_av=" + vp_av + "&vp_ar=" + vp_ar + "&guides=" + guides + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        var vacantAv = vp_av; // vacants equal needed for now.
        var vacantAr = vp_ar;
        var vacantGm = guides;
        var alreadyPosted = "";
        if (addr_r == '') addr_r = city_r;
        if (addr_dest_r == '') addr_dest_r = city_dest_r;
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            vacantAv = data.vp_av;
            vacantAr = data.vp_ar;
            vacantGm = data.guides;
            alreadyPosted = JSON.parse(data.posted);
        }, "json").always(function(data) {
            // Generate lead's details popup and form
            let snippet = '<form action="javascript:App.answerRelais(\'#answerFormRelais\');" method="get" id="answerFormRelais" class="accordion-color">';
            snippet += '<input type="hidden" name="lead_r" id="lead_r" value="' + lead_r + '">';
            snippet += '<input type="hidden" name="number_relais" id="number_relais" value="' + number_relais + '">';
            snippet += '<div class="card text-center w-auto border-warning accordion-color">';
            snippet += '<h4 class="card-header border-warning colorClair">Identifiant de Convoi<span> #' + lead_r + '</span></h4>';
            snippet += '<div class="card-body"><h5 class="card-title colorClair"><span>Prévu du </span>' + datedeb_r + '<span style="color:#FA0;"> au </span>' + datefin_r + '</h5></div>';
            snippet += '<ul class="list-group list-group-flush">';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Départ : </span>' + addr_r + ' (Dep.' + codePostal_r + ') - ' + addr_comp_r + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Arrivée : </span>' + addr_dest_r + ' (Dep.' + codePostal_dest_r + ') - ' + addr_dest_comp_r + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Besoin de: </span>' + vp_av + ' VP avant(s), ' + vp_ar + ' VP arrière(s), ' + guides + ' Guideur(s)</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:red;">Postes vacants: ' + vacantAv + ' VP avant(s), ' + vacantAr + ' VP arrière(s), ' + vacantGm + ' Guideur(s)</span></b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Convoi de catégorie : </span>' + cat_r + '<span style="color:#FA0;"> | N&deg;Arrêté préfectoral : </span>' + num_arr_pref + '</b></li>';
            // snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Durée et kilométrage estimatif du convoi : </span>' + est_time + ' pour ' + est_km + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Marchandise transportée : </span>' + marchandise_r + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Longueur : </span>' + long_r + '<span style="color:#FA0;"> x Largeur : </span>' + large_r + '<span style="color:#FA0;"> x Hauteur : </span>' + height_r + '<span style="color:#FA0;"> et MTR : </span>' + weight_r + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Immatriculation: TR (Tracteur) </span>' + imat_tr + '<span style="color:#FA0;"> | SR (Remorque) </span>' + imat_sr + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Contact conducteur : </span>' + name_r + ' / ' + tel_r + '</b></li>';
            // Show drivers already affected to it.
            //alert(jsonPilotes.data[0].id+' '+jsonPilotes.data[0].name+' '+jsonPilotes.data[0].imat+' '+jsonPilotes.data[0].type+' '+jsonPilotes.data[0].tel+' '+jsonPilotes.data[0].mail);
            if (alreadyPosted.data) {
                snippet += '<li class="list-group-item"><b><span style="color:#09c527;">Vos pilotes en poste sur ce convois: <br>';
                for (var i = 0; i < alreadyPosted.data.length; i++) {
                    //driverChecks += alreadyPosted.data[i].id+' '+alreadyPosted.data[i].imat+' '+alreadyPosted.data[i].poste;
                    snippet += 'N&deg' + alreadyPosted.data[i].id + ' - ' + alreadyPosted.data[i].imat + ' au poste de ' + alreadyPosted.data[i].poste + ' | ';
                }
                snippet += '</span></b></li>';
            }
            snippet += '</ul>';
            snippet += '<div class="card-body">';
            snippet += '<div class="row">';
            // adding drivers management to fill the lead
            snippet += '<div class="col-12 col-sm-12 col-md-12 col-lg-4"><div class="form-group"><label for="vp_av">VP Avant(s)</label><div class="input-group"><div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-truck"></i></span></div><select class="form-control" name="vp_av" id="vp_av" onchange="App.listDrivers(\'VP\', \'#vp_ar\', \'#guides\')"';
            snippet += (vacantAv > 0) ? '>' : ' disabled>';
            // <option value="0" selected>0</option><option value="1">1</option><option value="2">2</option><option value="3">3</option>
            for (let i = 0; i <= vacantAv; i++) {
                snippet += '<option value="' + i + '">' + i + '</option>';
            }
            snippet += '</select></div></div></div>';
            snippet += '<div class="col-12 col-sm-12 col-md-12 col-lg-4"><div class="form-group"><label for="vp_ar">VP Arrière(s)</label><div class="input-group"><div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-truck"></i></span></div><select class="form-control" name="vp_ar" id="vp_ar" onchange="App.listDrivers(\'VP\', \'#vp_av\', \'#guides\')"';
            snippet += (vacantAr > 0) ? '>' : ' disabled>';
            // <option value="0" selected>0</option><option value="1">1</option><option value="2">2</option><option value="3">3</option>
            for (let j = 0; j <= vacantAr; j++) {
                snippet += '<option value="' + j + '">' + j + '</option>';
            }
            snippet += '</select></div></div></div>';
            snippet += '<div class="col-12 col-sm-12 col-md-12 col-lg-4"><div class="form-group"><label for="guides">Guideur(s)</label><div class="input-group"><div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-motorcycle"></i></span></div><select class="form-control" name="guides" id="guides" onchange="App.listDrivers(\'GM\', \'#vp_av\', \'#vp_ar\')"';
            snippet += (vacantGm > 0) ? '>' : ' disabled>';
            // <option value="0" selected>0</option><option value="1">1</option><option value="2">2</option><option value="3">3</option>
            for (let k = 0; k <= vacantGm; k++) {
                snippet += '<option value="' + k + '">' + k + '</option>';
            }
            snippet += '</select></div></div></div>';
            snippet += '</div>'; // End row
            snippet += '<div id="listDrivers"></div>';
            //snippet += '<button class="btn btn-warning btn-block" id="sender" onclick="App.addWasValidatedClass(\'#answerFormLeads\')" type="submit">Valider <i class="fa fa-check-circle"></i></button>';
            snippet += '<div id="successfail"></div>';
            snippet += '</div>'; // End card-body
            snippet += '</form>';
            snippet += '<div class="card-footer border-warning"><h4><i class="fa fa-chevron-circle-up"></i> Positionnez-vous <i class="fa fa-chevron-circle-up"></i></h4></div>';
            $('#answerRelaisCont').empty().append(snippet);
        });
    },
    showLeadsInfo: function(auto_l, addr_l, addr_comp_l, codePostal_l, city_l, addr_dest_l, addr_dest_comp_l, codePostal_dest_l, city_dest_l, cat_l, num_arr_pref, name_l, tel_l, datedeb_l, datefin_l, long_l, large_l, height_l, weight_l, marchandise_l, est_time, est_km, vp_av, vp_ar, guides, imat_tr, imat_sr) {
        // Searching leads_full to look for vacant jobs in the lead...
        let req = "vacantJobsInLead";
        let query = "&auto_l=" + auto_l + "&vp_av=" + vp_av + "&vp_ar=" + vp_ar + "&guides=" + guides + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        let vacantAv = vp_av; // vacants equal needed for now.
        let vacantAr = vp_ar;
        let vacantGm = guides;
        let alreadyPosted = "";
        if (addr_l == '') addr_l = city_l;
        if (addr_dest_l == '') addr_dest_l = city_dest_l;
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            vacantAv = data.vp_av;
            vacantAr = data.vp_ar;
            vacantGm = data.guides;
            alreadyPosted = JSON.parse(data.posted);
        }, "json").always(function(data) {
            // Generate lead's details popup and form
            let snippet = '<form action="javascript:App.answerLeads(\'#answerFormLeads\');" method="get" id="answerFormLeads" class="accordion-color">';
            snippet += '<input type="hidden" name="auto_l" id="auto_l" value="' + auto_l + '">';
            snippet += '<div class="card text-center w-auto border-warning accordion-color">';
            snippet += '<h4 class="card-header border-warning colorClair">Identifiant de Convoi<span> #' + auto_l + '</span></h4>';
            snippet += '<div class="card-body"><h5 class="card-title colorClair"><span>Prévu du </span>' + datedeb_l + '<span style="color:#FA0;"> au </span>' + datefin_l + '</h5></div>';
            snippet += '<ul class="list-group list-group-flush">';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Départ : </span>' + addr_l + ' (Dep.' + codePostal_l + ') - ' + addr_comp_l + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Arrivée : </span>' + addr_dest_l + ' (Dep.' + codePostal_dest_l + ') - ' + addr_dest_comp_l + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Besoin de: </span>' + vp_av + ' VP avant(s), ' + vp_ar + ' VP arrière(s), ' + guides + ' Guideur(s)</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:red;">Postes vacants: ' + vacantAv + ' VP avant(s), ' + vacantAr + ' VP arrière(s), ' + vacantGm + ' Guideur(s)</span></b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Convoi de catégorie : </span>' + cat_l + '<span style="color:#FA0;"> | N&deg;Arrêté préfectoral : </span>' + num_arr_pref + '</b></li>';
            // snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Durée et kilométrage estimatif du convoi : </span>' + est_time + ' pour ' + est_km + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Marchandise transportée : </span>' + marchandise_l + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Longueur : </span>' + long_l + '<span style="color:#FA0;"> x Largeur : </span>' + large_l + '<span style="color:#FA0;"> x Hauteur : </span>' + height_l + '<span style="color:#FA0;"> et MTR : </span>' + weight_l + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Immatriculation: TR (Tracteur) </span>' + imat_tr + '<span style="color:#FA0;"> | SR (Remorque) </span>' + imat_sr + '</b></li>';
            snippet += '<li class="list-group-item accordion-color"><b><span style="color:#FA0;">Contact conducteur : </span>' + name_l + ' / ' + tel_l + '</b></li>';
            // Show drivers already affected to it.
            //alert(jsonPilotes.data[0].id+' '+jsonPilotes.data[0].name+' '+jsonPilotes.data[0].imat+' '+jsonPilotes.data[0].type+' '+jsonPilotes.data[0].tel+' '+jsonPilotes.data[0].mail);
            if (alreadyPosted.data) {
                snippet += '<li class="list-group-item"><b><span style="color:#09c527;">Vos pilotes en poste sur ce convois: <br>';
                for (let i = 0; i < alreadyPosted.data.length; i++) {
                    //driverChecks += alreadyPosted.data[i].id+' '+alreadyPosted.data[i].imat+' '+alreadyPosted.data[i].poste;
                    snippet += 'N&deg' + alreadyPosted.data[i].id + ' - ' + alreadyPosted.data[i].imat + ' au poste de ' + alreadyPosted.data[i].poste + ' | ';
                }
                snippet += '</span></b></li>';
            }
            snippet += '</ul>';
            $('#showLeadsInfoCont').empty().append(snippet);
        });
    },
    listDrivers: function(driverType, reset1, reset2) {
        // Reset the other selectBoxes, see answerLeads...
        $('#answerLeadsCont ' + reset1).val(0);
        $('#answerLeadsCont ' + reset2).val(0);
        let driverToShow = 0;
        let driverChecks = '<div class="form-row"><div class="col">';
        let havePilotes = false;
        let jsonPilotes = App.safeJsonParse(globals.pilotes);
        // alert(jsonPilotes.data[0].id+' '+jsonPilotes.data[0].name+' '+jsonPilotes.data[0].imat+' '+jsonPilotes.data[0].type+' '+jsonPilotes.data[0].tel+' '+jsonPilotes.data[0].mail);
        if (jsonPilotes !== undefined) {
            for (let i = 0; i < jsonPilotes.data.length; i++) {
                //driverChecks += jsonPilotes.data[i].id+' '+jsonPilotes.data[i].name+' '+jsonPilotes.data[i].imat+' '+jsonPilotes.data[i].type+' '+jsonPilotes.data[i].tel+' '+jsonPilotes.data[i].mail
                if (driverType == jsonPilotes.data[i].type) {
                    driverChecks += '<div class="custom-control custom-checkbox form-control-lg">';
                    driverChecks += '<input type="checkbox" class="custom-control-input" name="drivers[]" value="' + jsonPilotes.data[i].id + '" id="driver_' + jsonPilotes.data[i].id + '">';
                    driverChecks += '<label class="custom-control-label" for="driver_' + jsonPilotes.data[i].id + '">';
                    driverChecks += '<b>' + jsonPilotes.data[i].name + ' ' + jsonPilotes.data[i].imat + '</b>';
                    driverChecks += '</label></div>'; // Ends label & custom-checkbox
                    driverToShow++;
                }
            }
            havePilotes = true;
        } else havePilotes = false;
        driverChecks += '</div></div>'; // Ends col & row
        if (driverToShow > 0) driverChecks += '<button class="btn btn-warning btn-block" id="sender" onclick="App.addWasValidatedClass(\'#answerFormLeads\')" type="submit">Valider <i class="fa fa-check-circle"></i></button>';
        else driverChecks = "<p><b>Aucun de vos pilotes ou guides ne correspond à ce critère.</b></p>";
        if (!havePilotes) driverChecks += '<p><b>Pour inscrire un VP ou guideur <a href="#drivers">rendez-vous ici</a></b></p>';
        $('#listDrivers').empty().append(driverChecks);
    },
    getDriversList: function() {
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: 'getDriversList' }, function(data) {
            $("#myDriversCont").empty().append(data.snippet);
            $.localStorage.setItem('pilotes', data.pilotes);
            globals.pilotes = data.pilotes;
            if(data.modal == "show") $('#modalPilote').modal('show');
            else $('#modalPilote').modal('hide');
        }, "json").done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterCabs').tablesorter({
                theme: 'dark',
                dateFormat: "ddmmyyyy", // set the default date format
                // hidden filter input/selects will resize the columns, so try to minimize the change
                widthFixed: true,
                // initialize zebra striping and filter widgets
                widgets: ["zebra", "filter"],
                ignoreCase: false,
                widgetOptions: {
                    // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                    filter_childRows: false,
                    filter_columnFilters: true,
                    filter_cellFilter: '',
                    filter_cssFilter: '', // or []
                    filter_defaultFilter: {},
                    filter_excludeFilter: {},
                    filter_external: '',
                    filter_filteredRow: 'filtered',
                    filter_formatter: null,
                    filter_functions: null,
                    filter_hideEmpty: true,
                    filter_hideFilters: false,
                    filter_ignoreCase: true,
                    filter_liveSearch: true,
                    filter_onlyAvail: 'filter-onlyAvail',
                    filter_placeholder: { search: 'Filtre', select: '' },
                    filter_reset: 'button.reset',
                    filter_saveFilters: false,
                    filter_searchDelay: 300,
                    filter_searchFiltered: true,
                    filter_selectSource: null,
                    filter_serversideFiltering: false,
                    filter_startsWith: false,
                    filter_useParsedData: false,
                    filter_defaultAttrib: 'data-value',
                    filter_selectSourceSeparator: '|'
                }
            }).tablesorterPager({
                container: $('#mngPager'),
                cssGoto: ".pagenum",
                size: 10
            });
        });
    },
    refusToggle: function(button) {
        $(button).next('div').slideToggle();
    },
    LoadVP: function(auto_l, vp_av, vp_ar, guides, type) {
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, auto_l: auto_l, vp_av: vp_av, vp_ar: vp_ar, guides: guides, type: type, req: 'infoVpGuideurs' }, function(data) {
            $("#infoVpGuideursCont").empty().append(data.snippet);
            $('#infoVpGuideurs').modal('show');
        }, "json").always(function() {});
    },
    LoadVPRelais: function(lead_r, vp_av, vp_ar, guides, type, number_relais) {
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, lead_r: lead_r, vp_av: vp_av, vp_ar: vp_ar, guides: guides, type: type, number_relais: number_relais, req: 'infoVpGuideursRelais' }, function(data) {
            $("#infoVpGuideursCont").empty().append(data.snippet);
            $('#infoVpGuideurs').modal('show');
        }, "json").always(function() {});
    },
    refusValidation: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "refusConvoi";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        let returns = "";
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                alert("La modification a été effectuée");
            } else {
                alert("La modification n'a pas été effectuée suite a un problème technique");
            }
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
            $(myFormDiv + '#successfail').empty().append(returns);
        }).done(function(data) {
            setTimeout(function() {
                $('#infoVpGuideurs').modal('hide');
            }, 500);
        });
    },
    infoVpGuideurs: function(auto_l, thisBtn, vp_av, vp_ar, guides, type) {
        $(thisBtn).attr('disabled', true);
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, auto_l: auto_l, vp_av: vp_av, vp_ar: vp_ar, guides: guides, type: type, req: 'infoVpGuideurs' }, function(data) {
            $("#infoVpGuideursCont").empty().append(data.snippet);
            $('#infoVpGuideurs').modal('show');
        }, "json").always(function() {
            $(thisBtn).attr('disabled', false);
        });
    },
    infoVpGuideursRelais: function(lead_r, thisBtn, vp_av, vp_ar, guides, type, number_relais) {
        App.LoadVPRelais(lead_r, vp_av, vp_ar, guides, type, number_relais);
    },
    addDriver: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "addDriver";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        let returns = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                returns = '<div class="alert alert-success" role="alert"><b>Ce véhicule pilote / guideur a bien été ajouté à votre personnel.</b></div>';
                App.getDriversList();
                setTimeout(function() {
                    App.clearFormFields(myFormDiv);
                    $('#collapseAddDrivers').collapse('hide'); // Closing collapse
                }, 1600);
            } else
                returns = '<div class="alert alert-danger" role="alert"><b>Ce véhicule pilote / guideur n\'a pas été ajouté, à votre personnel, suite à un problème technique.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
            $(myFormDiv + ' #successfail').empty().append(returns);
        });
    },
    showAddDriverForm: function() {
        $('#collapseAddDrivers').collapse('show');
    },
    modDriver: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = "modDriver";
        query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
        let returns = "";
        //$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == "ok") {
                returns = '<div class="alert alert-success" role="alert"><b>Ce véhicule pilote / guideur a bien été modifié.</b></div>';
                App.getDriversList();
                setTimeout(function() {
                    App.clearFormFields(myFormDiv);
                    $('#modDriverModal').modal('hide');
                }, 3000);
            } else
                returns = '<div class="alert alert-danger" role="alert"><b>Ce véhicule pilote / guideur n\'a pas été modifié suite à un problème technique.</b></div>';
        }, "json").always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
            $(myFormDiv + ' #successfail').empty().append(returns);
        });
    },
    fillModDriver: function(auto_p, name_p, pwd_p, tel_p, mail_p, imat_p, type_p, flag_p) {
        $('#modFormDrivers #auto_p').val(auto_p);
        $('#modFormDrivers #name_drv').val(name_p);
        $('#modFormDrivers #pwd_drv').val(pwd_p);
        $('#modFormDrivers #phone_drv').val(tel_p);
        $('#modFormDrivers #mail_drv').val(mail_p);
        $('#modFormDrivers #imat_drv').val(imat_p);
        $('#modFormDrivers #type_drv').val(type_p);
        $('#modFormDrivers #active_drv').val(flag_p);
        if (document.URL.indexOf('manager') !== -1) {
            // $('#mngForm').show();
            // $('#popupmin').fadeIn();
            $('#updateFicheVpModal').modal('show');
        }
    },
    showDriverDocuments: function(auto_p, thisBtn) {
        thisBtn.setAttribute('disabled', true);
        $.post(globals.serverAddress, {'id': globals.id, 'pwd': globals.pwd, 'auto_p': auto_p, 'req': 'showDriverDocuments'}, function(data) {
            if (data.ok == "ok") {
                $("#showDriverDocumentsCont").empty().append(data.snippet);
                $('#driverDocumentsModal').modal('show');
            }
            else alert("Ce véhicule pilote / guideur n'a pas envoyé de justificatifs !");
        }, "json").always(() => {
            thisBtn.removeAttribute('disabled');
        });
    },
    blockDriver: function(auto_p, callBack = 'tableCabs', thisBtn) {
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            thisBtn.setAttribute('disabled', true);
            $.post(globals.serverAddress, {'id': globals.id, 'pwd': globals.pwd, 'auto_p': auto_p, 'req': 'blockDriver'}, function(data) {
                if (data.ok == "ok") {
                    alert("Action réalisée avec succés.");
                    if(callBack=='tableCabs') App.tableCabs();
                    else App.tableJust();
                }
                else alert("Ça a merdé !");
            }, "json").always(() => {
                thisBtn.removeAttribute('disabled');
            });
        }
    },
    unBlockDriver: function(auto_p, callBack = 'tableCabs', thisBtn) {
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            thisBtn.setAttribute('disabled', true);
            $.post(globals.serverAddress, {'id': globals.id, 'pwd': globals.pwd, 'auto_p': auto_p, 'req': 'unBlockDriver'}, function(data) {
                if (data.ok == "ok") {
                    alert("Action réalisée avec succés.");
                    if(callBack=='tableCabs') App.tableCabs();
                    else App.tableJust();
                }
                else alert("Ça a merdé !");
            }, "json").always(() => {
                thisBtn.removeAttribute('disabled');
            });
        }
    },
    deleteDriver: function(myFormDiv = '#modFormDrivers', thisBtn, event) {
        event.preventDefault();
        const confirmation = confirm("Êtes-vous certain de vouloir effectuer cette action ?");
        if (confirmation) {
            thisBtn.setAttribute('disabled', true);
            const auto_p = $(myFormDiv+' #auto_p').val();
            $.post(globals.serverAddress, {'id': globals.id, 'pwd': globals.pwd, 'auto_p': auto_p, 'req': 'deleteDriver'}, function(data) {
                if (data.ok == "ok") {
                    $(myFormDiv+' #successfail').html('<div class="alert alert-success">Action réalisée avec succés.</div>');
                    const myModal = $(myFormDiv).closest('.modal');
                    setTimeout(() => {$(myModal).modal('hide')}, 2000);
                    App.getDriversList();
                }
                else $(myFormDiv+' #successfail').html('<div class="alert alert-success">l\'action a échouée suite à un problème technique.</div>');
            }, "json").always(() => {
                thisBtn.removeAttribute('disabled');
            });
        }
    },
    validDocumentFromLightBox: function(thisDocName, thisBtn) {
        $(thisBtn).attr('disabled', true).html('<i class="fa fa-spinner fa-2x fa-pulse"></i>');
        $.post(globals.serverAddress, {'id': globals.id, 'pwd': globals.pwd, 'file_ad': thisDocName, 'req': 'validDriverDocument'}, function(data) {
            if (data.ok == "ok") {
                alert("Action réalisée avec succés.");
                $('#gestionTabList').find('a').removeClass('tab-nav-active');
                $('section#gestion').find('.tab').removeClass('tab-active');
                $('a[data-tab="GestOnglet4"]').trigger('click').addClass('tab-nav-active'); // App.tableJust(); // Refresh this list in case we came from there
                $('#gestionTabList4').addClass('tab-active');
                $('#driverDocumentsModal').modal('hide'); // Hide this modal in case we came from there too => Won't refresh this one for now.
            }
            else alert("Ça a merdé !");
        }, "json").always(() => {
            $(thisBtn).attr('disabled', false).html('<i class="fa fa-2x fa-check-circle"></i>');
        });
    },
    onglet: function() {
        $('.tab-nav a').on('click', function(e) {
            e.preventDefault();
            const tab = $(this).data('tab');
            $('.tab').removeClass('tab-active');
            /* On ajoute la classe tab-active à l'onglet qui doit être visible */
            $('#' + tab).addClass('tab-active');
            $('.tab-nav a').removeClass('tab-nav-active');
            $(this).addClass('tab-nav-active');
        });
    },
    updatePiloteTarifConst: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = 'updatePiloteTarifConst';
        query = query + "&id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == 'ok') {
                // alert("les tarifs ont bien été modifié");
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i> Les tarifs ont bien été modifié</b></div>');
                App.grilleTarifaireConst();
                setTimeout(function() {
                    $('#modifPilotGrilleTarifConst').modal('hide');
                    App.clearFormFields(myFormDiv);
                }, 3000);
            } else {
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> Une erreur s\'est produite: ' + data.textAlert + '</b></div>');
            }
            /*else $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> La tranche n\'a pas été modifiée car une erreur s\'est produite ' + $textAlert +'</b></div>');*/
        }, "json").done(function(data) {}).always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
        });
    },
    fillModGrilleTarifConstPilote: function(
        pilote_prixJourDOAbo, pilote_prixJourDONonAbo, pilote_prixJourSTAbo, pilote_prixJourSTNonAbo,
        pilote_prixDemiJourDOAbo, pilote_prixDemiJourDONonAbo, pilote_prixDemiJourSTAbo, pilote_prixDemiJourSTNonAbo,
        pilote_prixSoirDOAbo, pilote_prixSoirDONonAbo, pilote_prixSoirSTAbo, pilote_prixSoirSTNonAbo,
        pilote_prixBasculeDOAbo, pilote_prixBasculeDONonAbo, pilote_prixBasculeSTAbo, pilote_prixBasculeSTNonAbo,
        pilote_prixKmDOAbo, pilote_prixKmDONonAbo, pilote_prixKmSTAbo, pilote_prixKmSTNonAbo,
        pilote_prixImmobilVenLun, pilote_prixImmobilSamLun, seuilKmPilote) {
        $('#updatePiloteTarifConstForm #pilote_prixJourDOAbo').val(pilote_prixJourDOAbo);
        $('#updatePiloteTarifConstForm #pilote_prixJourDONonAbo').val(pilote_prixJourDONonAbo);
        $('#updatePiloteTarifConstForm #pilote_prixJourSTAbo').val(pilote_prixJourSTAbo);
        $('#updatePiloteTarifConstForm #pilote_prixJourSTNonAbo').val(pilote_prixJourSTNonAbo);
        $('#updatePiloteTarifConstForm #pilote_prixDemiJourDOAbo').val(pilote_prixDemiJourDOAbo);
        $('#updatePiloteTarifConstForm #pilote_prixDemiJourDONonAbo').val(pilote_prixDemiJourDONonAbo);
        $('#updatePiloteTarifConstForm #pilote_prixDemiJourSTAbo').val(pilote_prixDemiJourSTAbo);
        $('#updatePiloteTarifConstForm #pilote_prixDemiJourSTNonAbo').val(pilote_prixDemiJourSTNonAbo);
        $('#updatePiloteTarifConstForm #pilote_prixSoirDOAbo').val(pilote_prixSoirDOAbo);
        $('#updatePiloteTarifConstForm #pilote_prixSoirDONonAbo').val(pilote_prixSoirDONonAbo);
        $('#updatePiloteTarifConstForm #pilote_prixSoirSTAbo').val(pilote_prixSoirSTAbo);
        $('#updatePiloteTarifConstForm #pilote_prixSoirSTNonAbo').val(pilote_prixSoirSTNonAbo);
        $('#updatePiloteTarifConstForm #pilote_prixBasculeDOAbo').val(pilote_prixBasculeDOAbo);
        $('#updatePiloteTarifConstForm #pilote_prixBasculeDONonAbo').val(pilote_prixBasculeDONonAbo);
        $('#updatePiloteTarifConstForm #pilote_prixBasculeSTAbo').val(pilote_prixBasculeSTAbo);
        $('#updatePiloteTarifConstForm #pilote_prixBasculeSTNonAbo').val(pilote_prixBasculeSTNonAbo);
        $('#updatePiloteTarifConstForm #pilote_prixKmDOAbo').val(pilote_prixKmDOAbo);
        $('#updatePiloteTarifConstForm #pilote_prixKmDONonAbo').val(pilote_prixKmDONonAbo);
        $('#updatePiloteTarifConstForm #pilote_prixKmSTAbo').val(pilote_prixKmSTAbo);
        $('#updatePiloteTarifConstForm #pilote_prixKmSTNonAbo').val(pilote_prixKmSTNonAbo);
        $('#updatePiloteTarifConstForm #pilote_prixImmobilVenLun').val(pilote_prixImmobilVenLun);
        $('#updatePiloteTarifConstForm #pilote_prixImmobilSamLun').val(pilote_prixImmobilSamLun);
        $('#updatePiloteTarifConstForm #seuilKmPilote').val(seuilKmPilote);

    },
    updateGuideurTarifConst: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = 'updateGuideurTarifConst';
        query = query + "&id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == 'ok') {
                // alert("les tarifs ont bien été modifié");
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i> Les tarifs ont bien été modifié</b></div>');
                App.grilleTarifaireConst();
                setTimeout(function() {
                    $('#modifGuideurGrilleTarifConst').modal('hide');
                    App.clearFormFields(myFormDiv);
                }, 3000);
            } else {
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> Une erreur s\'est produite: ' + data.textAlert + '</b></div>');
            }
            /*else $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> La tranche n\'a pas été modifiée car une erreur s\'est produite ' + $textAlert +'</b></div>');*/
        }, "json").done(function(data) {}).always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
        });
    },
    fillModGrilleTarifConstGuideur: function(
        guideur_prixJourDOAbo, guideur_prixJourDONonAbo, guideur_prixJourSTAbo, guideur_prixJourSTNonAbo,
        guideur_prixDemiJourDOAbo, guideur_prixDemiJourDONonAbo, guideur_prixDemiJourSTAbo, guideur_prixDemiJourSTNonAbo,
        guideur_prixSoirDOAbo, guideur_prixSoirDONonAbo, guideur_prixSoirSTAbo, guideur_prixSoirSTNonAbo,
        guideur_prixBasculeDOAbo, guideur_prixBasculeDONonAbo, guideur_prixBasculeSTAbo, guideur_prixBasculeSTNonAbo,
        guideur_prixKmDOAbo, guideur_prixKmDONonAbo, guideur_prixKmSTAbo, guideur_prixKmSTNonAbo,
        guideur_prixImmobilVenLun, guideur_prixImmobilSamLun, seuilKmGuideur) {
        $('#updateGuideurTarifConstForm #guideur_prixJourDOAbo').val(guideur_prixJourDOAbo);
        $('#updateGuideurTarifConstForm #guideur_prixJourDONonAbo').val(guideur_prixJourDONonAbo);
        $('#updateGuideurTarifConstForm #guideur_prixJourSTAbo').val(guideur_prixJourSTAbo);
        $('#updateGuideurTarifConstForm #guideur_prixJourSTNonAbo').val(guideur_prixJourSTNonAbo);
        $('#updateGuideurTarifConstForm #guideur_prixDemiJourDOAbo').val(guideur_prixDemiJourDOAbo);
        $('#updateGuideurTarifConstForm #guideur_prixDemiJourDONonAbo').val(guideur_prixDemiJourDONonAbo);
        $('#updateGuideurTarifConstForm #guideur_prixDemiJourSTAbo').val(guideur_prixDemiJourSTAbo);
        $('#updateGuideurTarifConstForm #guideur_prixDemiJourSTNonAbo').val(guideur_prixDemiJourSTNonAbo);
        $('#updateGuideurTarifConstForm #guideur_prixSoirDOAbo').val(guideur_prixSoirDOAbo);
        $('#updateGuideurTarifConstForm #guideur_prixSoirDONonAbo').val(guideur_prixSoirDONonAbo);
        $('#updateGuideurTarifConstForm #guideur_prixSoirSTAbo').val(guideur_prixSoirSTAbo);
        $('#updateGuideurTarifConstForm #guideur_prixSoirSTNonAbo').val(guideur_prixSoirSTNonAbo);
        $('#updateGuideurTarifConstForm #guideur_prixBasculeDOAbo').val(guideur_prixBasculeDOAbo);
        $('#updateGuideurTarifConstForm #guideur_prixBasculeDONonAbo').val(guideur_prixBasculeDONonAbo);
        $('#updateGuideurTarifConstForm #guideur_prixBasculeSTAbo').val(guideur_prixBasculeSTAbo);
        $('#updateGuideurTarifConstForm #guideur_prixBasculeSTNonAbo').val(guideur_prixBasculeSTNonAbo);
        $('#updateGuideurTarifConstForm #guideur_prixKmDOAbo').val(guideur_prixKmDOAbo);
        $('#updateGuideurTarifConstForm #guideur_prixKmDONonAbo').val(guideur_prixKmDONonAbo);
        $('#updateGuideurTarifConstForm #guideur_prixKmSTAbo').val(guideur_prixKmSTAbo);
        $('#updateGuideurTarifConstForm #guideur_prixKmSTNonAbo').val(guideur_prixKmSTNonAbo);
        $('#updateGuideurTarifConstForm #guideur_prixImmobilVenLun').val(guideur_prixImmobilVenLun);
        $('#updateGuideurTarifConstForm #guideur_prixImmobilSamLun').val(guideur_prixImmobilSamLun);
        $('#updateGuideurTarifConstForm #seuilKmGuideur').val(seuilKmGuideur);
    },
    updatePiloteTarifDegre: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = 'updatePiloteTarifDegre';
        query = query + "&id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == 'ok') {
                // alert("les tarifs ont bien été modifié");
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i> Les tarifs ont bien été modifié</b></div>');
                App.grilleTarifaireDegrePilote();
                setTimeout(function() {
                    $('#modifPiloteGrilleTarifDegre').modal('hide');
                    App.clearFormFields(myFormDiv);
                }, 3000);
            } else {
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> Une erreur s\'est produite: ' + data.textAlert + '</b></div>');
            }
            /*else $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> La tranche n\'a pas été modifiée car une erreur s\'est produite ' + $textAlert +'</b></div>');*/
        }, "json").done(function(data) {}).always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
        });
    },
    fillModGrilleTarifDegrePilote: function(
        pilote_prixJour24DOAbo, pilote_prixJour48DOAbo, pilote_prixJour72DOAbo, pilote_prixJour24DONonAbo, pilote_prixJour48DONonAbo, pilote_prixJour72DONonAbo, pilote_prixJour24STAbo, pilote_prixJour48STAbo, pilote_prixJour72STAbo, pilote_prixJour24STNonAbo, pilote_prixJour48STNonAbo, pilote_prixJour72STNonAbo, pilote_prixDemiJour24DOAbo, pilote_prixDemiJour48DOAbo, pilote_prixDemiJour72DOAbo, pilote_prixDemiJour24DONonAbo, pilote_prixDemiJour48DONonAbo, pilote_prixDemiJour72DONonAbo, pilote_prixDemiJour24STAbo, pilote_prixDemiJour48STAbo, pilote_prixDemiJour72STAbo, pilote_prixDemiJour24STNonAbo, pilote_prixDemiJour48STNonAbo, pilote_prixDemiJour72STNonAbo, pilote_prixSoir24DOAbo, pilote_prixSoir48DOAbo, pilote_prixSoir72DOAbo, pilote_prixSoir24DONonAbo, pilote_prixSoir48DONonAbo, pilote_prixSoir72DONonAbo, pilote_prixSoir24STAbo, pilote_prixSoir48STAbo, pilote_prixSoir72STAbo, pilote_prixSoir24STNonAbo, pilote_prixSoir48STNonAbo, pilote_prixSoir72STNonAbo, pilote_prixBasculeDOAbo, pilote_prixBasculeDONonAbo, pilote_prixBasculeSTAbo, pilote_prixBasculeSTNonAbo, pilote_prixKmDOAbo, pilote_prixKmDONonAbo, pilote_prixKmSTAbo, pilote_prixKmSTNonAbo, seuilKmPilote) {
        $('#updatePiloteTarifDegreForm #pilote_prixJour24DOAbo').val(pilote_prixJour24DOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour48DOAbo').val(pilote_prixJour48DOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour72DOAbo').val(pilote_prixJour72DOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour24DONonAbo').val(pilote_prixJour24DONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour48DONonAbo').val(pilote_prixJour48DONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour72DONonAbo').val(pilote_prixJour72DONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour24STAbo').val(pilote_prixJour24STAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour48STAbo').val(pilote_prixJour48STAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour72STAbo').val(pilote_prixJour72STAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour24STNonAbo').val(pilote_prixJour24STNonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour48STNonAbo').val(pilote_prixJour48STNonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixJour72STNonAbo').val(pilote_prixJour72STNonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour24DOAbo').val(pilote_prixDemiJour24DOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour48DOAbo').val(pilote_prixDemiJour48DOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour72DOAbo').val(pilote_prixDemiJour72DOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour24DONonAbo').val(pilote_prixDemiJour24DONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour48DONonAbo').val(pilote_prixDemiJour48DONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour72DONonAbo').val(pilote_prixDemiJour72DONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour24STAbo').val(pilote_prixDemiJour24STAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour48STAbo').val(pilote_prixDemiJour48STAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour72STAbo').val(pilote_prixDemiJour72STAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour24STNonAbo').val(pilote_prixDemiJour24STNonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour48STNonAbo').val(pilote_prixDemiJour48STNonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixDemiJour72STNonAbo').val(pilote_prixDemiJour72STNonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir24DOAbo').val(pilote_prixSoir24DOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir48DOAbo').val(pilote_prixSoir48DOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir72DOAbo').val(pilote_prixSoir72DOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir24DONonAbo').val(pilote_prixSoir24DONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir48DONonAbo').val(pilote_prixSoir48DONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir72DONonAbo').val(pilote_prixSoir72DONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir24STAbo').val(pilote_prixSoir24STAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir48STAbo').val(pilote_prixSoir48STAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir72STAbo').val(pilote_prixSoir72STAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir24STNonAbo').val(pilote_prixSoir24STNonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir48STNonAbo').val(pilote_prixSoir48STNonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixSoir72STNonAbo').val(pilote_prixSoir72STNonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixBasculeDOAbo').val(pilote_prixBasculeDOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixBasculeDONonAbo').val(pilote_prixBasculeDONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixBasculeSTAbo').val(pilote_prixBasculeSTAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixBasculeSTNonAbo').val(pilote_prixBasculeSTNonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixKmDOAbo').val(pilote_prixKmDOAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixKmDONonAbo').val(pilote_prixKmDONonAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixKmSTAbo').val(pilote_prixKmSTAbo);
        $('#updatePiloteTarifDegreForm #pilote_prixKmSTNonAbo').val(pilote_prixKmSTNonAbo);
        $('#updatePiloteTarifDegreForm #seuilKmPilote').val(seuilKmPilote);
    },
    updateGuideurTarifDegre: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = 'updateGuideurTarifDegre';
        query = query + "&id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == 'ok') {
                // alert("les tarifs ont bien été modifié");
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i> Les tarifs ont bien été modifié</b></div>');
                App.grilleTarifaireDegreGuideur();
                setTimeout(function() {
                    $('#modifGuideurGrilleTarifDegre').modal('hide');
                    App.clearFormFields(myFormDiv);
                }, 3000);
            } else {
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> Une erreur s\'est produite: ' + data.textAlert + '</b></div>');
            }
            /*else $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> La tranche n\'a pas été modifiée car une erreur s\'est produite ' + $textAlert +'</b></div>');*/
        }, "json").done(function(data) {}).always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
        });
    },
    fillModGrilleTarifDegreGuideur: function(
        guideur_prixJour24DOAbo, guideur_prixJour48DOAbo, guideur_prixJour72DOAbo, guideur_prixJour24DONonAbo, guideur_prixJour48DONonAbo, guideur_prixJour72DONonAbo, guideur_prixJour24STAbo, guideur_prixJour48STAbo, guideur_prixJour72STAbo, guideur_prixJour24STNonAbo, guideur_prixJour48STNonAbo, guideur_prixJour72STNonAbo, guideur_prixDemiJour24DOAbo, guideur_prixDemiJour48DOAbo, guideur_prixDemiJour72DOAbo, guideur_prixDemiJour24DONonAbo, guideur_prixDemiJour48DONonAbo, guideur_prixDemiJour72DONonAbo, guideur_prixDemiJour24STAbo, guideur_prixDemiJour48STAbo, guideur_prixDemiJour72STAbo, guideur_prixDemiJour24STNonAbo, guideur_prixDemiJour48STNonAbo, guideur_prixDemiJour72STNonAbo, guideur_prixSoir24DOAbo, guideur_prixSoir48DOAbo, guideur_prixSoir72DOAbo, guideur_prixSoir24DONonAbo, guideur_prixSoir48DONonAbo, guideur_prixSoir72DONonAbo, guideur_prixSoir24STAbo, guideur_prixSoir48STAbo, guideur_prixSoir72STAbo, guideur_prixSoir24STNonAbo, guideur_prixSoir48STNonAbo, guideur_prixSoir72STNonAbo, guideur_prixBasculeDOAbo, guideur_prixBasculeDONonAbo, guideur_prixBasculeSTAbo, guideur_prixBasculeSTNonAbo, guideur_prixKmDOAbo, guideur_prixKmDONonAbo, guideur_prixKmSTAbo, guideur_prixKmSTNonAbo, seuilKmGuideur) {
        $('#updateGuideurTarifDegreForm #guideur_prixJour24DOAbo').val(guideur_prixJour24DOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour48DOAbo').val(guideur_prixJour48DOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour72DOAbo').val(guideur_prixJour72DOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour24DONonAbo').val(guideur_prixJour24DONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour48DONonAbo').val(guideur_prixJour48DONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour72DONonAbo').val(guideur_prixJour72DONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour24STAbo').val(guideur_prixJour24STAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour48STAbo').val(guideur_prixJour48STAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour72STAbo').val(guideur_prixJour72STAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour24STNonAbo').val(guideur_prixJour24STNonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour48STNonAbo').val(guideur_prixJour48STNonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixJour72STNonAbo').val(guideur_prixJour72STNonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour24DOAbo').val(guideur_prixDemiJour24DOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour48DOAbo').val(guideur_prixDemiJour48DOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour72DOAbo').val(guideur_prixDemiJour72DOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour24DONonAbo').val(guideur_prixDemiJour24DONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour48DONonAbo').val(guideur_prixDemiJour48DONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour72DONonAbo').val(guideur_prixDemiJour72DONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour24STAbo').val(guideur_prixDemiJour24STAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour48STAbo').val(guideur_prixDemiJour48STAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour72STAbo').val(guideur_prixDemiJour72STAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour24STNonAbo').val(guideur_prixDemiJour24STNonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour48STNonAbo').val(guideur_prixDemiJour48STNonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixDemiJour72STNonAbo').val(guideur_prixDemiJour72STNonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir24DOAbo').val(guideur_prixSoir24DOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir48DOAbo').val(guideur_prixSoir48DOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir72DOAbo').val(guideur_prixSoir72DOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir24DONonAbo').val(guideur_prixSoir24DONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir48DONonAbo').val(guideur_prixSoir48DONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir72DONonAbo').val(guideur_prixSoir72DONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir24STAbo').val(guideur_prixSoir24STAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir48STAbo').val(guideur_prixSoir48STAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir72STAbo').val(guideur_prixSoir72STAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir24STNonAbo').val(guideur_prixSoir24STNonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir48STNonAbo').val(guideur_prixSoir48STNonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixSoir72STNonAbo').val(guideur_prixSoir72STNonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixBasculeDOAbo').val(guideur_prixBasculeDOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixBasculeDONonAbo').val(guideur_prixBasculeDONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixBasculeSTAbo').val(guideur_prixBasculeSTAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixBasculeSTNonAbo').val(guideur_prixBasculeSTNonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixKmDOAbo').val(guideur_prixKmDOAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixKmDONonAbo').val(guideur_prixKmDONonAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixKmSTAbo').val(guideur_prixKmSTAbo);
        $('#updateGuideurTarifDegreForm #guideur_prixKmSTNonAbo').val(guideur_prixKmSTNonAbo);
        $('#updateGuideurTarifDegreForm #seuilKmGuideur').val(seuilKmGuideur);
    },
    fillModQuantiteTabFact: function(auto_tf, quantiteJourDO1, quantiteDemiJourDO1, quantiteSoirDO1, quantiteJourDO2, quantiteDemiJourDO2, quantiteSoirDO2, quantiteJourDO3, quantiteDemiJourDO3, quantiteSoirDO3, quantiteJourST1, quantiteDemiJourST1, quantiteSoirST1, quantiteJourST2, quantiteDemiJourST2, quantiteSoirST2, quantiteJourST3, quantiteDemiJourST3, quantiteSoirST3, prixKmDO1, prixKmDO2, prixKmDO3, prixKmST1, prixKmST2, prixKmST3, prixJourDO, prixDemiJourDO, prixSoirDO, prixJourST, prixDemiJourST, prixSoirST, nbKm1, nbKm2, nbKm3) {
        $('#updateTabFactModal #auto_tf').val(auto_tf);
        $('#updateTabFactModal #quantiteJourDO1').val(quantiteJourDO1);
        $('#updateTabFactModal #quantiteDemiJourDO1').val(quantiteDemiJourDO1);
        $('#updateTabFactModal #quantiteSoirDO1').val(quantiteSoirDO1);
        $('#updateTabFactModal #quantiteJourST1').val(quantiteJourST1);
        $('#updateTabFactModal #quantiteDemiJourST1').val(quantiteDemiJourST1);
        $('#updateTabFactModal #quantiteSoirST1').val(quantiteSoirST1);
        $('#updateTabFactModal #prixKmDO1').val(prixKmDO1);
        $('#updateTabFactModal #prixKmST1').val(prixKmST1);

        $('#updateTabFactModal #quantiteJourDO2').val(quantiteJourDO2);
        $('#updateTabFactModal #quantiteDemiJourDO2').val(quantiteDemiJourDO2);
        $('#updateTabFactModal #quantiteSoirDO2').val(quantiteSoirDO2);
        $('#updateTabFactModal #quantiteJourST2').val(quantiteJourST2);
        $('#updateTabFactModal #quantiteDemiJourST2').val(quantiteDemiJourST2);
        $('#updateTabFactModal #quantiteSoirST2').val(quantiteSoirST2);
        $('#updateTabFactModal #prixKmDO2').val(prixKmDO2);
        $('#updateTabFactModal #prixKmST2').val(prixKmST2);

        $('#updateTabFactModal #quantiteJourDO3').val(quantiteJourDO3);
        $('#updateTabFactModal #quantiteDemiJourDO3').val(quantiteDemiJourDO3);
        $('#updateTabFactModal #quantiteSoirDO3').val(quantiteSoirDO3);
        $('#updateTabFactModal #quantiteJourST3').val(quantiteJourST3);
        $('#updateTabFactModal #quantiteDemiJourST3').val(quantiteDemiJourST3);
        $('#updateTabFactModal #quantiteSoirST3').val(quantiteSoirST3);
        $('#updateTabFactModal #prixKmDO3').val(prixKmDO3);
        $('#updateTabFactModal #prixKmST3').val(prixKmST3);

        $('#updateTabFactModal #prixJourDO').val(prixJourDO);
        $('#updateTabFactModal #prixDemiJourDO').val(prixDemiJourDO);
        $('#updateTabFactModal #prixSoirDO').val(prixSoirDO);
        $('#updateTabFactModal #prixJourST').val(prixJourST);
        $('#updateTabFactModal #prixDemiJourST').val(prixDemiJourST);
        $('#updateTabFactModal #prixSoirST').val(prixSoirST);
        $('#updateTabFactModal #nbKm1').val(nbKm1);
        $('#updateTabFactModal #nbKm2').val(nbKm2);
        $('#updateTabFactModal #nbKm3').val(nbKm3);
    },
    updateTabFactModal: function(myFormDiv) {
        $(myFormDiv + ' #sender').attr("disabled", true);
        let query = $(myFormDiv).serialize();
        let req = 'updateTabFact';
        query = query + "&id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&req=" + req;
        $.post(globals.serverAddress, query, function(data) {
            if (data.ok == 'ok') {
                // alert("les tarifs ont bien été modifié");
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i> Les tarifs ont bien été modifié</b></div>');
                App.tableFact();
                setTimeout(function() {
                    $('#editTabFactModal').modal('hide');
                    App.clearFormFields(myFormDiv);
                }, 3000);
            } else {
                $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> Une erreur s\'est produite</b></div>');
            }
            /*else $(myFormDiv + ' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i> La tranche n\'a pas été modifiée car une erreur s\'est produite ' + $textAlert +'</b></div>');*/
        }, "json").done(function(data) {}).always(function(data) {
            $(myFormDiv + ' #sender').attr("disabled", false);
        });
    },
    banderoleModal: function() {
        $('#BanderoleModal').modal('show');
    },
    generatePdfFactDO: function(lead_id, pilot_id, param, date_previsionnel, date_dep) {
        if (param == "pdfFactDO") {
            alert(date_previsionnel);
            alert(date_dep);
            $.post(globals.serverAddress, { req: 'generatePdfFactDO', lead_id: lead_id, pilot_id: pilot_id, date_previsionnel: date_previsionnel, date_dep: date_dep, id: globals.id, admin: globals.admin, pwd: globals.pwd }, function(data) {
                alert(data.glob);
                if (data.ok == 'ok') {
                    $("#pdfFact").attr("data", data.pdf);
                    $("#generatePdfFactModal").modal('show');
                } else {
                    alert("ko");
                }
            }, "json");
        } else if (param == "pdfFactST") {
            $.post(globals.serverAddress, { req: 'generatePdfFactST', lead_id: lead_id, pilot_id: pilot_id, id: globals.id, admin: globals.admin, pwd: globals.pwd }, function(data) {
                alert(data.glob);
                if (data.ok == 'ok') {
                    $("#pdfFact").attr("data", data.pdf);
                    $("#generatePdfFactModal").modal('show');
                } else {
                    alert("ko");
                }
            }, "json");
        }
    },
    returnBdtPdf: function(lead_id, pilot_id) {
        $.post(globals.serverAddress, { req: 'returnBdtPdf', lead_id: lead_id, pilot_id: pilot_id, id: globals.id, admin: globals.admin, pwd: globals.pwd }, function(data) {
            if (data.ok == 'ok') {
                window.open("https://boursoconvois.com/db/" + data.pdf);
            } else {
                alert("ko");
            }
        }, "json");
    },

    safeJsonParse: function(input) {
        try {
            return JSON.parse(input);
        } catch (e) {
            return undefined;
        }
    },
    urlParam: function(name, url) {
        // Get parameters from an URL
        let results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
        //For current URL
        //let results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        } else {
            return results[1] || 0;
        }
    },
    bindUIActions: function() {
        // Twicking OVH fucked up server...
        const routerContainer = document.getElementById('routerContainer');
        let page2go = App.urlParam('p', document.URL); // Should we pop form
        if(page2go=='bourse') {
            console.warn('IN page2go Bourse !');
            // $('#BanderoleModal').modal('hide');
            fetch('/bourse.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setBoursePage();
                App.dotNavScroll(); // Dot Navigation with Scroll...
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        }
        if(page2go=='manager') {
            console.warn('IN page2go Manager !');
            // $('#BanderoleModal').modal('hide');
            fetch('/manager.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setManagerPage();
                App.dotNavScroll(); // Dot Navigation with Scroll...
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        }
        if(page2go=='agenda') {
            console.warn('IN page2go Agenda !');
            // $('#BanderoleModal').modal('hide');
            fetch('/agenda.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setCalendarPage();
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        }
        App.dotNavScroll(); // Dot Navigation with Scroll...
        App.refreshSmoothScroll(); // Smooth Scroll to div...
        // Activate scrollspy to add active class to navbar items on scroll
        $('body').scrollspy({
            target: '#mainNav',
            offset: 56
        });
        $('.navbar-nav a.dropdown-item').on('click', function() {
            $(this).closest('.dropdown-menu').removeClass('show'); // $('#mainDropdown').removeClass('show');
        });
        // Is it Mobile device
        if (/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent)) isMobile = true;
        if (isMobile) {
            $('#dateDep').prop('type', 'datetime');
            $('#dateFin').prop('type', 'datetime');
        }
        // Connected or not
        if (globals.pass == "OK") {
            $('#header-not-yet-connected').hide();
            $('#header-connected').show();
            if (globals.admin == "true") {
                $('#header-connected-admin').show();
            }
            else {
                switch (globals.type) {
                    case '0':
                        $('#header-connected-transporteurs').show();
                        break;
                    case '1':
                        $('#header-connected-pilotes').show();
                        break;
                    case '2':
                        $('#header-connected-stores').show();
                        break;
                    default:
                        alert("Connecté sans type !!");
                        App.logMeOut();
                }
            }
        } else {
            $('#header-connected').hide();
            $('#header-not-yet-connected').show();
        }
        $('.learn-more[href="#noscroll"]').on('click', function() {
            let link_clicked = $(this);
            let closestHiddenText = $(this).prev().children('.hidden-text');
            closestHiddenText.slideToggle("fast", function() {
                //$('.hidden-text').is(":visible").(this).text("<< Cacher");
                if (closestHiddenText.css('display') == 'none') {
                    link_clicked.text("En savoir plus »");
                } else {
                    link_clicked.text("<< Cacher");
                }
            });
        });
        $('.expends').on('click', function() {
            $(this).next('div').slideToggle('slow');
            //$.mobile.silentScroll($(this).next('div').offset().top);
        });
        $('section, hr').on('click', function() {
            if (window.innerWidth < 991) {
                $('.navbar').slideToggle("slow");
                $('#navbar-collapse').removeClass('show');
                //alert(window.innerWidth);
            }
        });
        $('.nav-item').on('click', function() {
            if (window.innerWidth < 768) {
                //$('.navbar').slideToggle("slow");
                $('#navbar-collapse').removeClass('show');
                //alert(window.innerWidth);
            }
        });
        /*
        // Prevent Main Menu Dropdown to close when inside selectBox is clicked
        $('#dropdownMenuMain').on('click', function(e) {
            $(this).next().toggle();
        });
        */
        $("[data-toggle=tooltip]").tooltip();
        document.addEventListener("scroll", function(event) {
            if (App.getDocHeight() == App.getScrollXY()[1] + window.innerHeight) {
                //$('.go-up-fixed').fadeOut('slow');
                globals.bottomScrolled = true;
            } else {
                globals.bottomScrolled = false;
                if (App.getScrollXY()[1] == 0) {
                    $('.navbar').slideDown("slow");
                    $('#navbar-collapse').removeClass('show');
                    $('.go-up-fixed').fadeOut('slow');
                } else
                    $('.go-up-fixed').fadeIn('slow');
            }
        });
        /*
        // #subFormTransporteurs #subFormPilotes #civil #tva
        $('#subFormPilotes #civil').change(function() {
            if ($(this).val() == 'Micro-entrepreneur') $('#subFormPilotes #tva').attr({ required: false, disabled: true });
            else $('#subFormPilotes #tva').attr({ required: true, disabled: false });
        });
        $('#subFormTransporteurs #civil').change(function() {
            if ($(this).val() == 'Micro-entrepreneur') $('#subFormTransporteurs #tva').attr({ required: false, disabled: true });
            else $('#subFormTransporteurs #tva').attr({ required: true, disabled: false });
        });
        */
    },
	refreshSmoothScroll: function() {
		// Smooth Scroll to div on anchors click...
		$('a[href*="#"]').not('a.noscroll').on('click', function (event) {
			event.preventDefault();
			let offset = 0;
			const target = this.hash;
			if($(this).data('offset') != undefined) offset = $(this).data('offset'); // if set data-offset="pixels"
			if($(target).length) {
				$('html, body').stop().animate({
					'scrollTop': $(target).offset().top - offset
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
			else { // Scrolls to top...
				$('html, body').stop().animate({
					'scrollTop': 0
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
		});
	},
    dotNavScroll: function() {
        $('.awesome-tooltip').tooltip({
            placement: 'left'
        });
        $(window).on('scroll',function(e){
            dotnavigation();
        });
        function dotnavigation(){
            var numSections = $('section').length;
            $('#dot-nav li a').removeClass('active').parent('li').removeClass('active');
            $('section').each(function(i,item){
                let ele = $(item), nextTop, thisTop;
                // console.log(ele.next().html());
                if (typeof ele.next().offset() != "undefined") {
                    nextTop = ele.next().offset().top;
                }
                else {
                    nextTop = $(document).height();
                }
                if (ele.offset() !== null) {
                    thisTop = ele.offset().top - ((nextTop - ele.offset().top) / numSections);
                }
                else {
                    thisTop = 0;
                }
                var docTop = $(document).scrollTop();
                if(docTop >= thisTop && (docTop < nextTop)){
                    $('#dot-nav li').eq(i).addClass('active');
                }
            });
        }
        /* get clicks working */
        $('#dot-nav li').on('click', function(){
            var id = $(this).find('a').attr("href"),
                posi,
                ele,
                padding = 0;
            ele = $(id);
            posi = ($(ele).offset()||0).top - padding;
            $('html, body').animate({scrollTop:posi}, 'slow');
            return false;
        });
    },
    setHomePage: function() {
        /*
        this.displayElement.day = $('#countdown_day');
        this.displayElement.hour = $('#countdown_hour');
        this.displayElement.min = $('#countdown_min');
        this.displayElement.sec = $('#countdown_sec');
        this.tick(); // Premier tick tout de suite
        const tickInterval = setInterval(function() {App.tick();}, 1000);
        let page2go = App.urlParam('p', document.URL); // Should we pop form
        if(page2go!='bourse' && page2go!='manager' && page2go!='agenda') App.banderoleModal();
        */
    },
    setBoursePage: function() {
        // First let's get the fuckers out !!!
        if (globals.pass != "OK") document.location.href = '/';
        // Second let's populate with available Leads...
        App.getLeads('loadEvent', true);
        let popSome = App.urlParam('pop', document.URL); // Should we pop form
        let openlead = App.urlParam('openlead', document.URL); // Should we pop form
        if (popSome == 'go') {
            $.post(globals.serverAddress, { req: 'popModLead', auto_l: openlead, id: globals.id, pwd: globals.pwd }, function(data) {
                if (data.ok == "ok") {
                    App.fillModLeads(openlead, data.addr_l, data.addr_comp_l, data.codePostal_l, data.city_l, data.addr_dest_l, data.addr_dest_comp_l, data.codePostal_dest_l, data.city_dest_l, data.cat_l, data.num_arr_pref, data.name_l, data.tel_l, data.datedeb_l, data.datefin_l, data.long_l, data.large_l, data.height_l, data.weight_l, data.est_time, data.est_km, data.vp_av, data.vp_ar, data.guides, data.imat_tr, data.imat_sr);
                    $('#modLeadModal').modal('show');
                }
            }, "json");
        }
        // Account's type display dependencies, first Transp then pilotes...
        if (globals.type == 0) {
            //$(".account-pilotes").remove();
            // $('.type0').css('display', 'flex');
            $('.type0').show();
            $('.type1').remove();
            let d = new Date();
            let year = d.getFullYear();
            $('#filterYear0').val(year);
            let m = d.getMonth(); // 0 to 11
            let month = m + 1; // 1 to 12
            month = (month < 10) ? "0" + month : month;
            $('#filterMonth0').val(month);
            // Calculate week number
            // Set to nearest Thursday: current date + 4 - current day number
            // Make Sunday's day number 7
            d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
            // Get first day of year
            let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
            // Calculate full weeks to nearest Thursday
            let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
            $('#filterWeek0').val(weekNo);
        } else if (globals.type == 1) {
            //$(".account-transporteurs").remove();
            // $('.type1').css('display', 'flex');
            $('.type1').show();
            $('.type0').remove();
            // Whether to hide the addLeads buttons or not for Pilotes/Guides
            $('a[href="#modal_add_lead"]').hide();
            $('a[href="#modal_add_lead_light"]').hide();
            let jsonPilotesCheck = App.safeJsonParse(globals.pilotes);
            //alert(jsonPilotesCheck.data[0].id+' '+jsonPilotesCheck.data[0].name+' '+jsonPilotesCheck.data[0].imat+' '+jsonPilotesCheck.data[0].type+' '+jsonPilotesCheck.data[0].tel+' '+jsonPilotesCheck.data[0].mail);
            if (jsonPilotesCheck === undefined) {
                alert("Vous n'avez pas encore enregistré de VP ni de Guideur sur notre plateforme, cela vous permettra de vous positionner sur les demandes de convoi.");
                $("#goToDriversPlace").trigger("click");
                $("#addDriversBtn").trigger("click");
            }
            // Call the function to fill the drivers list...
            App.getDriversList();
            let d = new Date();
            let year = d.getFullYear();
            $('#filterYear1').val(year);
            let m = d.getMonth(); // 0 to 11
            let month = m + 1; // 1 to 12
            month = (month < 10) ? "0" + month: month;
            $('#filterMonth1').val(month);
            // Calculate week number
            // Set to nearest Thursday: current date + 4 - current day number
            // Make Sunday's day number 7
            d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
            // Get first day of year
            let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
            // Calculate full weeks to nearest Thursday
            let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
            $('#filterWeek1').val(weekNo);
        } else {
            $('.type0').remove();
            $('.type1').remove();
        }
        // Now we populate the remaining form with account data...
        let dec_nom = $('#name').html(globals.nom).text();
        let dec_addr = $('#address').html(globals.addr).text();
        // console.warn(dec_nom, dec_addr);
        $('#id').val(globals.id);
        $('#name').val(dec_nom);
        $('#address').val(dec_addr);
        $('#area').val(globals.cp);
        $('#city').val(globals.city);
        $('#phone').val(globals.tel);
        $('#mail').val(globals.email);
        $('#siret').val(globals.siret);
        $('#tva').val(globals.tva);
        $('#naf').val(globals.naf);
        let jsonContacts = App.safeJsonParse(globals.contacts);
        if (jsonContacts !== undefined) {
            //alert(jsonContacts.civil_cont+' '+jsonContacts.name_cont+' '+jsonContacts.fname_cont);
            $('#civil_cont').val(jsonContacts.civil_cont);
            $('#name_cont').val(jsonContacts.name_cont);
            $('#fname_cont').val(jsonContacts.fname_cont);
            $('#fct_cont').val(jsonContacts.fct_cont);
            $('#phone_cont').val(jsonContacts.phone_cont);
            $('#mail_cont').val(jsonContacts.mail_cont);
            $('#civil_cont2').val(jsonContacts.civil_cont2);
            $('#name_cont2').val(jsonContacts.name_cont2);
            $('#fname_cont2').val(jsonContacts.fname_cont2);
            $('#fct_cont2').val(jsonContacts.fct_cont2);
            $('#phone_cont2').val(jsonContacts.phone_cont2);
            $('#mail_cont2').val(jsonContacts.mail_cont2);
            $('#civil_cont3').val(jsonContacts.civil_cont3);
            $('#name_cont3').val(jsonContacts.name_cont3);
            $('#fname_cont3').val(jsonContacts.fname_cont3);
            $('#fct_cont3').val(jsonContacts.fct_cont3);
            $('#phone_cont3').val(jsonContacts.phone_cont3);
            $('#mail_cont3').val(jsonContacts.mail_cont3);
        }
        // Check passwords...
        $('#pwd2').on('keyup', function() {
            if ($('#pwd').val() != $('#pwd2').val()) {
                $('.pwdline').empty().append("* Les mots de passes ne correspondent pas !");
            } else {
                $('.pwdline').empty().append("*");
            }
        });
        /*
        */
        // Google Autocomplete stuff for addLeadsForm...
        let input = document.getElementById('addressAuto');
        let options = {
            componentRestrictions: { country: 'fr' },
            types: ['address']
        };
        //let autocomplete = new google.maps.places.Autocomplete(input);
        let autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.setFields(['address_components']);
        // fired when an autocomplete option is selected or the input is submitted
        google.maps.event.addListener(autocomplete, 'place_changed', function() {
            let place = autocomplete.getPlace();
            let address = '';
            let zip = '';
            let city = '';
            if (place.address_components) {
                address = [
                    (place.address_components[0] && place.address_components[0].short_name || ''),
                    (place.address_components[1] && place.address_components[1].short_name || ''),
                    (place.address_components[2] && place.address_components[2].short_name || '')
                ].join(' ');
                for (let i = 0; i < place.address_components.length; i++) {
                    for (let j = 0; j < place.address_components[i].types.length; j++) {
                        if (place.address_components[i].types[j] == "postal_code") {
                            zip = place.address_components[i].long_name;
                            let dep = zip.substring(0, 2);
                            $('#codePostal').val(zip);
                        }
                        if (place.address_components[i].types[j] == "locality") {
                            city = place.address_components[i].long_name;
                            $('#cityDep').val(city);
                        }
                    }
                }
            }
        });
        let dest = document.getElementById('addressDestAuto');
        let autocompleteDest = new google.maps.places.Autocomplete(dest, options);
        autocompleteDest.setFields(['address_components']);
        // fired when an autocomplete option is selected or the input is submitted
        google.maps.event.addListener(autocompleteDest, 'place_changed', function() {
            let placeDest = autocompleteDest.getPlace();
            let addressDest = '';
            let zipDest = '';
            let cityDest = '';
            if (placeDest.address_components) {
                addressDest = [
                    (placeDest.address_components[0] && placeDest.address_components[0].short_name || ''),
                    (placeDest.address_components[1] && placeDest.address_components[1].short_name || ''),
                    (placeDest.address_components[2] && placeDest.address_components[2].short_name || '')
                ].join(' ');
                for (let i = 0; i < placeDest.address_components.length; i++) {
                    for (let j = 0; j < placeDest.address_components[i].types.length; j++) {
                        if (placeDest.address_components[i].types[j] == "postal_code") {
                            zipDest = placeDest.address_components[i].long_name;
                            let dep = zipDest.substring(0, 2);
                            $('#codePostal_dest').val(zipDest);
                        }
                        if (placeDest.address_components[i].types[j] == "locality") {
                            cityDest = placeDest.address_components[i].long_name;
                            $('#cityDest').val(cityDest);
                        }
                    }
                }
            }
        });
        let inputMod = document.getElementById('addressAutoMod');
        //let autocomplete = new google.maps.places.Autocomplete(input);
        let autocompleteMod = new google.maps.places.Autocomplete(inputMod, options);
        autocompleteMod.setFields(['address_components']);
        // fired when an autocomplete option is selected or the input is submitted
        google.maps.event.addListener(autocompleteMod, 'place_changed', function() {
            let placeMod = autocompleteMod.getPlace();
            let address = '';
            let zip = '';
            let city = '';
            if (placeMod.address_components) {
                address = [
                    (placeMod.address_components[0] && placeMod.address_components[0].short_name || ''),
                    (placeMod.address_components[1] && placeMod.address_components[1].short_name || ''),
                    (placeMod.address_components[2] && placeMod.address_components[2].short_name || '')
                ].join(' ');
                for (let i = 0; i < placeMod.address_components.length; i++) {
                    for (let j = 0; j < placeMod.address_components[i].types.length; j++) {
                        if (placeMod.address_components[i].types[j] == "postal_code") {
                            zip = placeMod.address_components[i].long_name;
                            let dep = zip.substring(0, 2);
                            $('#codePostalMod').val(zip);
                        }
                        if (placeMod.address_components[i].types[j] == "locality") {
                            city = placeMod.address_components[i].long_name;
                            $('#cityMod').val(city);
                        }
                    }
                }
            }
        });
        let destMod = document.getElementById('addressDestAutoMod');
        let autocompleteDestMod = new google.maps.places.Autocomplete(destMod, options);
        autocompleteDestMod.setFields(['address_components']);
        // fired when an autocomplete option is selected or the input is submitted
        google.maps.event.addListener(autocompleteDestMod, 'place_changed', function() {
            let placeDestMod = autocompleteDestMod.getPlace();
            let addressDest = '';
            let zipDest = '';
            let cityDest = '';
            if (placeDestMod.address_components) {
                addressDest = [
                    (placeDestMod.address_components[0] && placeDestMod.address_components[0].short_name || ''),
                    (placeDestMod.address_components[1] && placeDestMod.address_components[1].short_name || ''),
                    (placeDestMod.address_components[2] && placeDestMod.address_components[2].short_name || '')
                ].join(' ');
                for (let i = 0; i < placeDestMod.address_components.length; i++) {
                    for (let j = 0; j < placeDestMod.address_components[i].types.length; j++) {
                        if (placeDestMod.address_components[i].types[j] == "postal_code") {
                            zipDest = placeDestMod.address_components[i].long_name;
                            let dep = zipDest.substring(0, 2);
                            $('#codePostalDestMod').val(zipDest);
                        }
                        if (placeDestMod.address_components[i].types[j] == "locality") {
                            cityDest = placeDestMod.address_components[i].long_name;
                            $('#cityDestMod').val(cityDest);
                        }
                    }
                }
            }
        });
        // Here we hide steps in addLeadsForm...
        $('#leadStep2').fadeOut();
        $('#leadStep3').fadeOut();
        $('#leadToStep1').on('click', function() {
            setTimeout(function() { $('#leadStep1').fadeIn(); }, 500);
            $('#leadStep2').fadeOut();
            $('#leadStep3').fadeOut();
        });
        $('#leadToStep2').on('click', function() {
            $('#leadStep1').fadeOut();
            setTimeout(function() { $('#leadStep2').fadeIn(); }, 500);
            $('#leadStep3').fadeOut();
        });
        $('#leadToStep3').on('click', function() {
            $('#leadStep1').fadeOut();
            $('#leadStep2').fadeOut();
            setTimeout(function() { $('#leadStep3').fadeIn(); }, 500);
        });
        $('#backToStep2').on('click', function() {
            $('#leadStep1').fadeOut();
            setTimeout(function() { $('#leadStep2').fadeIn(); }, 500);
            $('#leadStep3').fadeOut();
        });
        // Here we do the same in modLeadsForm...
        $('#modLeadStep2').fadeOut();
        $('#modLeadStep3').fadeOut();
        $('#leadToModStep1').on('click', function() {
            setTimeout(function() { $('#modLeadStep1').fadeIn(); }, 500);
            $('#modLeadStep2').fadeOut();
            $('#modLeadStep3').fadeOut();
        });
        $('#leadToModStep2').on('click', function() {
            $('#modLeadStep1').fadeOut();
            setTimeout(function() { $('#modLeadStep2').fadeIn(); }, 500);
            $('#modLeadStep3').fadeOut();
        });
        $('#leadToModStep3').on('click', function() {
            $('#modLeadStep1').fadeOut();
            $('#modLeadStep2').fadeOut();
            setTimeout(function() { $('#modLeadStep3').fadeIn(); }, 500);
        });
        $('#backToModStep2').on('click', function() {
            $('#modLeadStep1').fadeOut();
            setTimeout(function() { $('#modLeadStep2').fadeIn(); }, 500);
            $('#modLeadStep3').fadeOut();
        });
        // Here we do the same in modLeadsForm...
        $('#modRelaiStep2').fadeOut();
        $('#modRelaiStep3').fadeOut();
        $('#relaiToModStep1').click(function() {
            setTimeout(function() { $('#modRelaiStep1').fadeIn(); }, 500);
            $('#modRelaiStep2').fadeOut();
            $('#modRelaiStep3').fadeOut();
        });
        $('#relaiToModStep2').click(function() {
            $('#modRelaiStep1').fadeOut();
            setTimeout(function() { $('#modRelaiStep2').fadeIn(); }, 500);
            $('#modRelaiStep3').fadeOut();
        });
        $('#relaiToModStep3').click(function() {
            $('#modRelaiStep1').fadeOut();
            $('#modRelaiStep2').fadeOut();
            setTimeout(function() { $('#modRelaiStep3').fadeIn(); }, 500);
        });
        $('#backToRelaiModStep2').click(function() {
            $('#modRelaiStep1').fadeOut();
            setTimeout(function() { $('#modRelaiStep2').fadeIn(); }, 500);
            $('#modRelaiStep3').fadeOut();
        });
        // DateTime Picker...
        $('#ui-datepicker-div').css("z-index", "100000");
        $.datepicker.setDefaults($.datepicker.regional["fr"]);
        /*
        $.timepicker.regional['fr'] = {
            timeOnlyTitle: '',
            timeText: 'RDV &agrave;',
            hourText: 'Heure',
            minuteText: 'Minute',
            secondText: 'Seconde',
            currentText: 'Maintenant',
            closeText: 'Fermer'
        };
        $.timepicker.setDefaults($.timepicker.regional['fr']);
        */
        $("#dateDep").datepicker({
            changeMonth: false,
            changeYear: false,
            /*altField: "",
            timeFormat: "HH':'mm",
            stepMinute: 30,*/
            minDate: new Date()
        });
        $("#dateFin").datepicker({
            changeMonth: false,
            changeYear: false,
            /*altField: "",
            timeFormat: "HH':'mm",
            stepMinute: 30,*/
            minDate: new Date()
        });
        $("#dateDepLight").datepicker({
            changeMonth: false,
            changeYear: false,
            /*altField: "",
            timeFormat: "HH':'mm",
            stepMinute: 30,*/
            minDate: new Date()
        });
        $("#dateFinLight").datepicker({
            changeMonth: false,
            changeYear: false,
            /*altField: "",
            timeFormat: "HH':'mm",
            stepMinute: 30,*/
            minDate: new Date()
        });
        $("#dateDepMod").datepicker({
            changeMonth: false,
            changeYear: false,
            /*altField: "",
            timeFormat: "HH':'mm",
            stepMinute: 30,*/
            minDate: new Date()
        });
        $("#dateFinMod").datepicker({
            changeMonth: false,
            changeYear: false,
            /*altField: "",
            timeFormat: "HH':'mm",
            stepMinute: 30,*/
            minDate: new Date()
        });
    },
    setManagerPage: function() {
        // First let's get the fuckers out !!!
        if (globals.pass!="OK" || globals.admin!="true") document.location.href = '/';
        this.tableFlow();
        this.tableCabs();
        this.tableFact();
        $("#facturation").on('dblclick', function(e) {
            let test = $("#tabFactBody").find("input");
            if (test.length > 0) {
                if (!$(e.target).closest('#tableFact').length) {
                    //Le clic s'est produit en dehors de l'élément "tableFact"
                    $('#triggerChangeCell').trigger('click');
                }
            }
        });
        // modLeadsForm...
        $('#modLeadStep2').fadeOut();
        $('#modLeadStep3').fadeOut();
        $('#leadToModStep1').on('click', function() {
            setTimeout(function() { $('#modLeadStep1').fadeIn(); }, 500);
            $('#modLeadStep2').fadeOut();
            $('#modLeadStep3').fadeOut();
        });
        $('#leadToModStep2').on('click', function() {
            $('#modLeadStep1').fadeOut();
            setTimeout(function() { $('#modLeadStep2').fadeIn(); }, 500);
            $('#modLeadStep3').fadeOut();
        });
        $('#leadToModStep3').on('click', function() {
            $('#modLeadStep1').fadeOut();
            $('#modLeadStep2').fadeOut();
            setTimeout(function() { $('#modLeadStep3').fadeIn(); }, 500);
        });
        $('#backToModStep2').on('click', function() {
            $('#modLeadStep1').fadeOut();
            setTimeout(function() { $('#modLeadStep2').fadeIn(); }, 500);
            $('#modLeadStep3').fadeOut();
        });
    },
    setCalendarPage: function() {
        // First let's get the fuckers out !!!
        if (globals.pass!="OK" || globals.admin!="true") document.location.href = '/';
        var calendarEl = document.getElementById('calendar');
        var calendar = new Calendar(calendarEl, {
            plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
            initialView: 'dayGridMonth',
            // eventRender: 'eventRenderCallback',
            locale: frLocale,
            contentHeight:"auto",
            weekNumbers: true,
            weekNumberCalculation:'ISO',
            themeSystem: 'bootstrap4',
            editable: false,
            headerToolbar: {
                right: 'prev,next today',
                left: 'title',
                center: 'dayGridWeek,dayGridMonth,dayGridDay'
            },
            // events: 'https://boursoconvois.com/dev/test_agenda/events.php',
            eventSources: [
                // your event source
                {
                    // query = query + "&id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&req=" + req;
                    // $.post(globals.serverAddress, query, function(data) {
                    url: globals.serverAddress, // use the `url` property
                    method: 'POST',
                    extraParams: {
                        id: globals.id,
                        pwd: globals.pwd,
                        admin: globals.admin,
                        req: 'getCalendarEvents'
                    },
                    // color: 'yellow',    // an option!
                    // textColor: 'black'  // an option!
                }
                // any other sources...
            ],
            eventColor: '#28a745',
            // eventBackgroundColor:'#717171',
            selectable: true,
            // selectHelper: true,
            eventClick: function(info) {
                $.post(globals.serverAddress, {auto_l: info.event.id, pwd: globals.pwd, req: 'getCalendarEventsInfo'}, function(data) {
                    $('#infoConvModalTitle').empty().append(data.numConvoi);
                    $('#infoConvModalBody').empty().append(data.modalBody);
                    $('#infoConvModal').modal('show');
                }, "json");
            },
        });
        calendar.render();
    },
    popMapFollowLeads: function() {
        $('#popupmax').fadeIn();
        // Map to follow the lead if not already initialized...
		if (map == undefined || map == null) {
			// Defining defaults baseLayer and overlay
			const defaultOverlay = L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
				layers: "SECUROUTE.TE.2TE48",
				format: 'image/png',
				transparent: true
			});
			const defaultBaseLayer = L.tileLayer('https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=b38726e53811414b88dd7dc694d70755', {
				attribution: '&copy; <a href="https://title.thunderfotrest.com/copyright">OpenStreetMap</a> contributors'
			});
			// Instantiate map with defaults base and overlay
			map = L.map('mapLeads', {
				center: globals.defLatLng,
				zoom: globals.defZoom,
				// layers: [defaultBaseLayer, defaultOverlay]
			});
			const overlays = {
				"1ère catégorie": L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
					layers: "SECUROUTE.TE.1TE",
					format: 'image/png',
					transparent: true
				}),
				"2ème catégorie": defaultOverlay,
				"72 tonnes": L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
					layers: "SECUROUTE.TE.TE72",
					format: 'image/png',
					transparent: true
				}),
				"94 tonnes": L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
					layers: "SECUROUTE.TE.TE94",
					format: 'image/png',
					transparent: true
				}),
				"120 tonnes": L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
					layers: "SECUROUTE.TE.TE120",
					format: 'image/png',
					transparent: true
				}),
				"Franchissement": L.tileLayer.wms('https://wxs.ign.fr/transports/geoportail/r/wms?', {
					layers: "SECUROUTE.TE.ALL",
					format: 'image/png',
					transparent: true
				})
			};
			// Création des couches de maps
			const baselayers = {
				Transport: defaultBaseLayer,
				Classique: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
					attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				})
			};
			// Select les fonds de cartes, positionné
			L.control.layers(baselayers, overlays, {position: 'topleft'}).addTo(map);
			defaultBaseLayer.addTo(map);
			defaultOverlay.addTo(map);
		}
		setTimeout(function() {
			map.invalidateSize();
			// Make sure defaults layers are respected
			$('.leaflet-control-layers-overlays .leaflet-control-layers-selector:eq(1)').trigger('click');
			$('.leaflet-control-layers-base .leaflet-control-layers-selector:eq(0)').trigger('click');
		}, 100);
    },
    closeMapPop: function() {
        $('#popupmax').fadeOut();
    },
    showMail: function() {
        $('#conatctForm').show();
        $('#popupmin').fadeIn();
    },
    postMail: function() {
        let body = $('#mailBody').val();
        let type = $('#mailType').val();
        let recipient = "exploitation@boursoconvois.com";
        switch (type) {
            case '0':
                recipient = "exploitation@boursoconvois.com";
            break;
            case '1':
                recipient = "fsimon@snsolutions.fr";
            break;
        }
        //alert(recipient+' WTF '+company+' WTF '+nom+' WTF '+email+' WTF '+tel+' WTF '+body);
        $.post(globals.serverAddress, { recipient: recipient, company: globals.company, name: globals.nom, from: globals.email, tel: globals.tel, body: body, pwd: globals.pwd, req: 'stationMailer' }, function(data) {
            if (!data.ok) {
                alert('Erreur !!');
            } else {
                //alert('MESSAGE ENVOYE... MERCI.');
                $('#conatctForm').hide();
                $('#popupmin').fadeOut();
            }
        }, "json");
    },
    flashPop: function() {
        $('#flashForm').show();
        $('#popupmin').fadeIn();
    },
    flashInfo: function() {
        let infoType = $('#infoType').val();
        let objet = $('#objet').val();
        let msg = $('#msg').val();
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, infoType: infoType, objet: objet, msg: msg, pwd: globals.pwd, req: 'flashInfo' }, function(data) {
            if (!data.ok) {
                alert('Erreur !!');
            } else {
                //alert('MESSAGE ENVOYE.');
                $('#flashForm').hide();
                $('#popupmin').fadeOut();
            }
        }, "json");
    },
    newsPop: function() {
        $('#newsForm').show();
        $('#popupmin').fadeIn();
    },
    newsInfo: function() {
        let newsMsg = $('#newsMsg').val();
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, msg: newsMsg, pwd: globals.pwd, req: 'newsLetter' }, function(data) {
            alert(data);
            $('#newsInfo').hide();
            $('#popupmin').fadeOut();
        });
    },
    addDriverPop: function() {
        $('#mngAddForm').show();
        $('#popupmin').fadeIn();
    },
    addDriverPopSp: function() {
        $('#mngSpForm').show();
        $('#popupmin').fadeIn();
    },
    addDriverPopSt: function() {
        $('#mngStForm').show();
        $('#popupmin').fadeIn();
    },
    closePop: function() {
        $('#popupmin').fadeOut();
        //$('#inPopup div').hide(); // Hides all divs in Popup
        $('#inPopup').children().hide(); // Hides all children (only first degree) divs in Popup
    },
    clearAll: function() {
        //$('#mainForm')[0].reset();
        //$(':input').val('');
        $('#infos :input').each(function() {
            if ($(this).is('select')) {
                $(this).val($(this).find('option[selected]').val());
            } else {
                $(this).val(this.defaultValue);
            }
        });
        $("#slider").slider("value", 1);
        //$('#devis').prop( "checked", false );
    },
    // Table Sorting Shit...
    tableFlow: function() {
        const req = (globals.admin == true || globals.admin == "true") ? 'getDiaryAdmin' : 'getDiary';
        $.post(globals.serverAddress, { id: globals.id, pwd: globals.pwd, admin: globals.admin, req: req }, function(data) {
            $("#diaryCont").empty().append(data);
        }).done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterDiary')
            // initialize dragtable FIRST!
            .dragtable({
                // *** new dragtable mod options ***
                // this option MUST match the tablesorter selectorSort option!
                sortClass: '.sorter',
                // this function is called after everything has been updated
                tablesorterComplete: function(table) {},

                // *** original dragtable settings (non-default) ***
                dragaccept: '.drag-enable', // class name of draggable cols -> default null = all columns draggable

                // *** original dragtable settings (default values) ***
                revert: false, // smooth revert
                dragHandle: '.table-handle', // handle for moving cols, if not exists the whole 'th' is the handle
                maxMovingRows: 40, // 1 -> only header. 40 row should be enough, the rest is usually not in the viewport
                excludeFooter: false, // excludes the footer row(s) while moving other columns. Make sense if there is a footer with a colspan. */
                onlyHeaderThreshold: 100, // TODO:  not implemented yet, switch automatically between entire col moving / only header moving
                persistState: null, // url or function -> plug in your custom persistState function right here. function call is persistState(originalTable)
                restoreState: null, // JSON-Object or function:  some kind of experimental aka Quick-Hack TODO: do it better
                exact: true, // removes pixels, so that the overlay table width fits exactly the original table width
                clickDelay: 10, // ms to wait before rendering sortable list and delegating click event
                containment: null, // @see http://api.jqueryui.com/sortable/#option-containment, use it if you want to move in 2 dimesnions (together with axis: null)
                cursor: 'move', // @see http://api.jqueryui.com/sortable/#option-cursor
                cursorAt: false, // @see http://api.jqueryui.com/sortable/#option-cursorAt
                distance: 0, // @see http://api.jqueryui.com/sortable/#option-distance, for immediate feedback use "0"
                tolerance: 'pointer', // @see http://api.jqueryui.com/sortable/#option-tolerance
                axis: 'x', // @see http://api.jqueryui.com/sortable/#option-axis, Only vertical moving is allowed. Use 'x' or null. Use this in conjunction with the 'containment' setting
                beforeStart: $.noop, // returning FALSE will stop the execution chain.
                beforeMoving: $.noop,
                beforeReorganize: $.noop,
                beforeStop: $.noop
            })
            // initialize tablesorter
            .tablesorter({
                theme: 'dark',
                dateFormat: "ddmmyyyy", // set the default date format
                // hidden filter input/selects will resize the columns, so try to minimize the change
                widthFixed: true,
                // initialize zebra striping and filter widgets
                widgets: ["zebra", "filter"],
                ignoreCase: false,
                selectorSort: '.sorter',
                widgetOptions: {
                    // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                    filter_childRows: false,
                    filter_columnFilters: true,
                    filter_cellFilter: '',
                    filter_cssFilter: '', // or []
                    filter_defaultFilter: {},
                    filter_excludeFilter: {},
                    filter_external: '',
                    filter_filteredRow: 'filtered',
                    filter_formatter: null,
                    filter_functions: null,
                    filter_hideEmpty: true,
                    filter_hideFilters: false,
                    filter_ignoreCase: true,
                    filter_liveSearch: true,
                    filter_onlyAvail: 'filter-onlyAvail',
                    filter_placeholder: { search: 'Filtre', select: '' },
                    filter_reset: 'button.reset',
                    filter_saveFilters: false,
                    filter_searchDelay: 300,
                    filter_searchFiltered: true,
                    filter_selectSource: null,
                    filter_serversideFiltering: false,
                    filter_startsWith: false,
                    filter_useParsedData: false,
                    filter_defaultAttrib: 'data-value',
                    filter_selectSourceSeparator: '|'
                }
            }).tablesorterPager({
                container: $('#diaryPager'),
                cssGoto: ".pagenum",
                size: 10
            });
            //setTimeout('tableFlow()', 60000);
        });
        // App.waterSize();
    },

    tableBooks: function() {
        const req = (globals.admin == true || globals.admin == "true") ? 'getBooksAdmin' : 'getBooks';
        $.post(globals.serverAddress, { id: globals.id, pwd: globals.pwd, admin: globals.admin, req: req }, function(data) {
            $("#diaryCont").empty().append(data);
        }).done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterBooks').tablesorter({
                theme: 'default',
                dateFormat: "ddmmyyyy", // set the default date format
                // hidden filter input/selects will resize the columns, so try to minimize the change
                widthFixed: true,
                // initialize zebra striping and filter widgets
                widgets: ["zebra", "filter"],
                ignoreCase: false,
                widgetOptions: {
                    // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                    filter_childRows: false,
                    filter_columnFilters: true,
                    filter_cellFilter: '',
                    filter_cssFilter: '', // or []
                    filter_defaultFilter: {},
                    filter_excludeFilter: {},
                    filter_external: '',
                    filter_filteredRow: 'filtered',
                    filter_formatter: null,
                    filter_functions: null,
                    filter_hideEmpty: true,
                    filter_hideFilters: false,
                    filter_ignoreCase: true,
                    filter_liveSearch: true,
                    filter_onlyAvail: 'filter-onlyAvail',
                    filter_placeholder: { search: 'Filtre', select: '' },
                    filter_reset: 'button.reset',
                    filter_saveFilters: false,
                    filter_searchDelay: 300,
                    filter_searchFiltered: true,
                    filter_selectSource: null,
                    filter_serversideFiltering: false,
                    filter_startsWith: false,
                    filter_useParsedData: false,
                    filter_defaultAttrib: 'data-value',
                    filter_selectSourceSeparator: '|'
                }
            }).tablesorterPager({
                container: $('#pager'),
                cssGoto: ".pagenum",
                size: 10
            });
        });
    },
    tableUnset: function() {
        const req = (globals.admin == true || globals.admin == "true") ? 'getUnsetAdmin': 'getUnset';
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: req }, function(data) {
            $("#diaryCont").empty().append(data);
        }).done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterUnset')
            // initialize dragtable FIRST!
            .dragtable({
                // *** new dragtable mod options ***
                // this option MUST match the tablesorter selectorSort option!
                sortClass: '.sorter',
                // this function is called after everything has been updated
                tablesorterComplete: function(table) {},

                // *** original dragtable settings (non-default) ***
                dragaccept: '.drag-enable', // class name of draggable cols -> default null = all columns draggable

                // *** original dragtable settings (default values) ***
                revert: false, // smooth revert
                dragHandle: '.table-handle', // handle for moving cols, if not exists the whole 'th' is the handle
                maxMovingRows: 40, // 1 -> only header. 40 row should be enough, the rest is usually not in the viewport
                excludeFooter: false, // excludes the footer row(s) while moving other columns. Make sense if there is a footer with a colspan. */
                onlyHeaderThreshold: 100, // TODO:  not implemented yet, switch automatically between entire col moving / only header moving
                persistState: null, // url or function -> plug in your custom persistState function right here. function call is persistState(originalTable)
                restoreState: null, // JSON-Object or function:  some kind of experimental aka Quick-Hack TODO: do it better
                exact: true, // removes pixels, so that the overlay table width fits exactly the original table width
                clickDelay: 10, // ms to wait before rendering sortable list and delegating click event
                containment: null, // @see http://api.jqueryui.com/sortable/#option-containment, use it if you want to move in 2 dimesnions (together with axis: null)
                cursor: 'move', // @see http://api.jqueryui.com/sortable/#option-cursor
                cursorAt: false, // @see http://api.jqueryui.com/sortable/#option-cursorAt
                distance: 0, // @see http://api.jqueryui.com/sortable/#option-distance, for immediate feedback use "0"
                tolerance: 'pointer', // @see http://api.jqueryui.com/sortable/#option-tolerance
                axis: 'x', // @see http://api.jqueryui.com/sortable/#option-axis, Only vertical moving is allowed. Use 'x' or null. Use this in conjunction with the 'containment' setting
                beforeStart: $.noop, // returning FALSE will stop the execution chain.
                beforeMoving: $.noop,
                beforeReorganize: $.noop,
                beforeStop: $.noop
            })
            // initialize tablesorter
            .tablesorter({
                theme: 'dark',
                dateFormat: "ddmmyyyy", // set the default date format
                // hidden filter input/selects will resize the columns, so try to minimize the change
                widthFixed: true,
                // initialize zebra striping and filter widgets
                widgets: ["zebra", "filter"],
                ignoreCase: false,
                selectorSort: '.sorter',
                widgetOptions: {
                    // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                    filter_childRows: false,
                    filter_columnFilters: true,
                    filter_cellFilter: '',
                    filter_cssFilter: '', // or []
                    filter_defaultFilter: {},
                    filter_excludeFilter: {},
                    filter_external: '',
                    filter_filteredRow: 'filtered',
                    filter_formatter: null,
                    filter_functions: null,
                    filter_hideEmpty: true,
                    filter_hideFilters: false,
                    filter_ignoreCase: true,
                    filter_liveSearch: true,
                    filter_onlyAvail: 'filter-onlyAvail',
                    filter_placeholder: { search: 'Filtre', select: '' },
                    filter_reset: 'button.reset',
                    filter_saveFilters: false,
                    filter_searchDelay: 300,
                    filter_searchFiltered: true,
                    filter_selectSource: null,
                    filter_serversideFiltering: false,
                    filter_startsWith: false,
                    filter_useParsedData: false,
                    filter_defaultAttrib: 'data-value',
                    filter_selectSourceSeparator: '|'
                }
            }).tablesorterPager({
                container: $('#pager'),
                cssGoto: ".pagenum",
                size: 10
            });
        });
    },
    tableRefused: function() {
        const req = (globals.admin == true || globals.admin == "true") ? 'getRefusedAdmin': 'getRefused';
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: req }, function(data) {
            $("#diaryCont").empty().append(data);
        }).done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterRef')
            // initialize dragtable FIRST!
            .dragtable({
                // *** new dragtable mod options ***
                // this option MUST match the tablesorter selectorSort option!
                sortClass: '.sorter',
                // this function is called after everything has been updated
                tablesorterComplete: function(table) {},

                // *** original dragtable settings (non-default) ***
                dragaccept: '.drag-enable', // class name of draggable cols -> default null = all columns draggable

                // *** original dragtable settings (default values) ***
                revert: false, // smooth revert
                dragHandle: '.table-handle', // handle for moving cols, if not exists the whole 'th' is the handle
                maxMovingRows: 40, // 1 -> only header. 40 row should be enough, the rest is usually not in the viewport
                excludeFooter: false, // excludes the footer row(s) while moving other columns. Make sense if there is a footer with a colspan. */
                onlyHeaderThreshold: 100, // TODO:  not implemented yet, switch automatically between entire col moving / only header moving
                persistState: null, // url or function -> plug in your custom persistState function right here. function call is persistState(originalTable)
                restoreState: null, // JSON-Object or function:  some kind of experimental aka Quick-Hack TODO: do it better
                exact: true, // removes pixels, so that the overlay table width fits exactly the original table width
                clickDelay: 10, // ms to wait before rendering sortable list and delegating click event
                containment: null, // @see http://api.jqueryui.com/sortable/#option-containment, use it if you want to move in 2 dimesnions (together with axis: null)
                cursor: 'move', // @see http://api.jqueryui.com/sortable/#option-cursor
                cursorAt: false, // @see http://api.jqueryui.com/sortable/#option-cursorAt
                distance: 0, // @see http://api.jqueryui.com/sortable/#option-distance, for immediate feedback use "0"
                tolerance: 'pointer', // @see http://api.jqueryui.com/sortable/#option-tolerance
                axis: 'x', // @see http://api.jqueryui.com/sortable/#option-axis, Only vertical moving is allowed. Use 'x' or null. Use this in conjunction with the 'containment' setting
                beforeStart: $.noop, // returning FALSE will stop the execution chain.
                beforeMoving: $.noop,
                beforeReorganize: $.noop,
                beforeStop: $.noop
            })
            // initialize tablesorter
            .tablesorter({
                theme: 'dark',
                dateFormat: "ddmmyyyy", // set the default date format
                // hidden filter input/selects will resize the columns, so try to minimize the change
                widthFixed: true,
                // initialize zebra striping and filter widgets
                widgets: ["zebra", "filter"],
                ignoreCase: false,
                selectorSort: '.sorter',
                widgetOptions: {
                    // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                    filter_childRows: false,
                    filter_columnFilters: true,
                    filter_cellFilter: '',
                    filter_cssFilter: '', // or []
                    filter_defaultFilter: {},
                    filter_excludeFilter: {},
                    filter_external: '',
                    filter_filteredRow: 'filtered',
                    filter_formatter: null,
                    filter_functions: null,
                    filter_hideEmpty: true,
                    filter_hideFilters: false,
                    filter_ignoreCase: true,
                    filter_liveSearch: true,
                    filter_onlyAvail: 'filter-onlyAvail',
                    filter_placeholder: { search: 'Filtre', select: '' },
                    filter_reset: 'button.reset',
                    filter_saveFilters: false,
                    filter_searchDelay: 300,
                    filter_searchFiltered: true,
                    filter_selectSource: null,
                    filter_serversideFiltering: false,
                    filter_startsWith: false,
                    filter_useParsedData: false,
                    filter_defaultAttrib: 'data-value',
                    filter_selectSourceSeparator: '|'
                }
            }).tablesorterPager({
                container: $('#pager'),
                cssGoto: ".pagenum",
                size: 10
            });
        });
    },
    tableRelais: function() {
        const req = 'getRelaisAdmin';
        $.post(globals.serverAddress, { id: globals.id, pwd: globals.pwd, admin: globals.admin, req: req }, function(data) {
            $("#diaryCont").empty().append(data);
        }).done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterRelais')
                // initialize dragtable FIRST!
                .dragtable({
                    // *** new dragtable mod options ***
                    // this option MUST match the tablesorter selectorSort option!
                    sortClass: '.sorter',
                    // this function is called after everything has been updated
                    tablesorterComplete: function(table) {},

                    // *** original dragtable settings (non-default) ***
                    dragaccept: '.drag-enable', // class name of draggable cols -> default null = all columns draggable

                    // *** original dragtable settings (default values) ***
                    revert: false, // smooth revert
                    dragHandle: '.table-handle', // handle for moving cols, if not exists the whole 'th' is the handle
                    maxMovingRows: 40, // 1 -> only header. 40 row should be enough, the rest is usually not in the viewport
                    excludeFooter: false, // excludes the footer row(s) while moving other columns. Make sense if there is a footer with a colspan. */
                    onlyHeaderThreshold: 100, // TODO:  not implemented yet, switch automatically between entire col moving / only header moving
                    persistState: null, // url or function -> plug in your custom persistState function right here. function call is persistState(originalTable)
                    restoreState: null, // JSON-Object or function:  some kind of experimental aka Quick-Hack TODO: do it better
                    exact: true, // removes pixels, so that the overlay table width fits exactly the original table width
                    clickDelay: 10, // ms to wait before rendering sortable list and delegating click event
                    containment: null, // @see http://api.jqueryui.com/sortable/#option-containment, use it if you want to move in 2 dimesnions (together with axis: null)
                    cursor: 'move', // @see http://api.jqueryui.com/sortable/#option-cursor
                    cursorAt: false, // @see http://api.jqueryui.com/sortable/#option-cursorAt
                    distance: 0, // @see http://api.jqueryui.com/sortable/#option-distance, for immediate feedback use "0"
                    tolerance: 'pointer', // @see http://api.jqueryui.com/sortable/#option-tolerance
                    axis: 'x', // @see http://api.jqueryui.com/sortable/#option-axis, Only vertical moving is allowed. Use 'x' or null. Use this in conjunction with the 'containment' setting
                    beforeStart: $.noop, // returning FALSE will stop the execution chain.
                    beforeMoving: $.noop,
                    beforeReorganize: $.noop,
                    beforeStop: $.noop
                })
                // initialize tablesorter
                .tablesorter({
                    theme: 'dark',
                    dateFormat: "ddmmyyyy", // set the default date format
                    // hidden filter input/selects will resize the columns, so try to minimize the change
                    widthFixed: true,
                    // initialize zebra striping and filter widgets
                    widgets: ["zebra", "filter"],
                    ignoreCase: false,
                    selectorSort: '.sorter',
                    widgetOptions: {
                        // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                        filter_childRows: false,
                        filter_columnFilters: true,
                        filter_cellFilter: '',
                        filter_cssFilter: '', // or []
                        filter_defaultFilter: {},
                        filter_excludeFilter: {},
                        filter_external: '',
                        filter_filteredRow: 'filtered',
                        filter_formatter: null,
                        filter_functions: null,
                        filter_hideEmpty: true,
                        filter_hideFilters: false,
                        filter_ignoreCase: true,
                        filter_liveSearch: true,
                        filter_onlyAvail: 'filter-onlyAvail',
                        filter_placeholder: { search: 'Filtre', select: '' },
                        filter_reset: 'button.reset',
                        filter_saveFilters: false,
                        filter_searchDelay: 300,
                        filter_searchFiltered: true,
                        filter_selectSource: null,
                        filter_serversideFiltering: false,
                        filter_startsWith: false,
                        filter_useParsedData: false,
                        filter_defaultAttrib: 'data-value',
                        filter_selectSourceSeparator: '|'
                    }
                }).tablesorterPager({
                    container: $('#diaryPager'),
                    cssGoto: ".pagenum",
                    size: 20
                });
            //setTimeout('tableFlow()', 60000);
        });
    },
    tableCabs: function() {
        const req = (globals.admin == true || globals.admin == "true") ? 'getCabsAdmin': 'getCabs';
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: req }, function(data) {
            $("#mngCont").empty().append(data);
        }).done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterCabs')
            // initialize dragtable FIRST!
            .dragtable({
                // *** new dragtable mod options ***
                // this option MUST match the tablesorter selectorSort option!
                sortClass: '.sorter',
                // this function is called after everything has been updated
                tablesorterComplete: function(table) {},

                // *** original dragtable settings (non-default) ***
                dragaccept: '.drag-enable', // class name of draggable cols -> default null = all columns draggable

                // *** original dragtable settings (default values) ***
                revert: false, // smooth revert
                dragHandle: '.table-handle', // handle for moving cols, if not exists the whole 'th' is the handle
                maxMovingRows: 40, // 1 -> only header. 40 row should be enough, the rest is usually not in the viewport
                excludeFooter: false, // excludes the footer row(s) while moving other columns. Make sense if there is a footer with a colspan. */
                onlyHeaderThreshold: 100, // TODO:  not implemented yet, switch automatically between entire col moving / only header moving
                persistState: null, // url or function -> plug in your custom persistState function right here. function call is persistState(originalTable)
                restoreState: null, // JSON-Object or function:  some kind of experimental aka Quick-Hack TODO: do it better
                exact: true, // removes pixels, so that the overlay table width fits exactly the original table width
                clickDelay: 10, // ms to wait before rendering sortable list and delegating click event
                containment: null, // @see http://api.jqueryui.com/sortable/#option-containment, use it if you want to move in 2 dimesnions (together with axis: null)
                cursor: 'move', // @see http://api.jqueryui.com/sortable/#option-cursor
                cursorAt: false, // @see http://api.jqueryui.com/sortable/#option-cursorAt
                distance: 0, // @see http://api.jqueryui.com/sortable/#option-distance, for immediate feedback use "0"
                tolerance: 'pointer', // @see http://api.jqueryui.com/sortable/#option-tolerance
                axis: 'x', // @see http://api.jqueryui.com/sortable/#option-axis, Only vertical moving is allowed. Use 'x' or null. Use this in conjunction with the 'containment' setting
                beforeStart: $.noop, // returning FALSE will stop the execution chain.
                beforeMoving: $.noop,
                beforeReorganize: $.noop,
                beforeStop: $.noop
            })
            // initialize tablesorter
            .tablesorter({
                theme: 'dark',
                dateFormat: "ddmmyyyy", // set the default date format
                // hidden filter input/selects will resize the columns, so try to minimize the change
                widthFixed: true,
                // initialize zebra striping and filter widgets
                widgets: ["zebra", "filter"],
                ignoreCase: false,
                selectorSort: '.sorter',
                widgetOptions: {
                    // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                    filter_childRows: false,
                    filter_columnFilters: true,
                    filter_cellFilter: '',
                    filter_cssFilter: '', // or []
                    filter_defaultFilter: {},
                    filter_excludeFilter: {},
                    filter_external: '',
                    filter_filteredRow: 'filtered',
                    filter_formatter: null,
                    filter_functions: null,
                    filter_hideEmpty: true,
                    filter_hideFilters: false,
                    filter_ignoreCase: true,
                    filter_liveSearch: true,
                    filter_onlyAvail: 'filter-onlyAvail',
                    filter_placeholder: { search: 'Filtre', select: '' },
                    filter_reset: 'button.reset',
                    filter_saveFilters: false,
                    filter_searchDelay: 300,
                    filter_searchFiltered: true,
                    filter_selectSource: null,
                    filter_serversideFiltering: false,
                    filter_startsWith: false,
                    filter_useParsedData: false,
                    filter_defaultAttrib: 'data-value',
                    filter_selectSourceSeparator: '|'
                }
            }).tablesorterPager({
                container: $('#mngPager'),
                cssGoto: ".pagenum",
                size: 10
            });
        });
        App.waterSize();
    },
    tableSp: function() {
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: 'getSp' }, function(data) {
            $("#mngCont").empty().append(data);
        }).done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterSp')
            // initialize dragtable FIRST!
            .dragtable({
                // *** new dragtable mod options ***
                // this option MUST match the tablesorter selectorSort option!
                sortClass: '.sorter',
                // this function is called after everything has been updated
                tablesorterComplete: function(table) {},

                // *** original dragtable settings (non-default) ***
                dragaccept: '.drag-enable', // class name of draggable cols -> default null = all columns draggable

                // *** original dragtable settings (default values) ***
                revert: false, // smooth revert
                dragHandle: '.table-handle', // handle for moving cols, if not exists the whole 'th' is the handle
                maxMovingRows: 40, // 1 -> only header. 40 row should be enough, the rest is usually not in the viewport
                excludeFooter: false, // excludes the footer row(s) while moving other columns. Make sense if there is a footer with a colspan. */
                onlyHeaderThreshold: 100, // TODO:  not implemented yet, switch automatically between entire col moving / only header moving
                persistState: null, // url or function -> plug in your custom persistState function right here. function call is persistState(originalTable)
                restoreState: null, // JSON-Object or function:  some kind of experimental aka Quick-Hack TODO: do it better
                exact: true, // removes pixels, so that the overlay table width fits exactly the original table width
                clickDelay: 10, // ms to wait before rendering sortable list and delegating click event
                containment: null, // @see http://api.jqueryui.com/sortable/#option-containment, use it if you want to move in 2 dimesnions (together with axis: null)
                cursor: 'move', // @see http://api.jqueryui.com/sortable/#option-cursor
                cursorAt: false, // @see http://api.jqueryui.com/sortable/#option-cursorAt
                distance: 0, // @see http://api.jqueryui.com/sortable/#option-distance, for immediate feedback use "0"
                tolerance: 'pointer', // @see http://api.jqueryui.com/sortable/#option-tolerance
                axis: 'x', // @see http://api.jqueryui.com/sortable/#option-axis, Only vertical moving is allowed. Use 'x' or null. Use this in conjunction with the 'containment' setting
                beforeStart: $.noop, // returning FALSE will stop the execution chain.
                beforeMoving: $.noop,
                beforeReorganize: $.noop,
                beforeStop: $.noop
            })
            // initialize tablesorter
            .tablesorter({
                theme: 'dark',
                dateFormat: "ddmmyyyy", // set the default date format
                // hidden filter input/selects will resize the columns, so try to minimize the change
                widthFixed: true,
                // initialize zebra striping and filter widgets
                widgets: ["zebra", "filter"],
                ignoreCase: false,
                selectorSort: '.sorter',
                widgetOptions: {
                    // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                    filter_childRows: false,
                    filter_columnFilters: true,
                    filter_cellFilter: '',
                    filter_cssFilter: '', // or []
                    filter_defaultFilter: {},
                    filter_excludeFilter: {},
                    filter_external: '',
                    filter_filteredRow: 'filtered',
                    filter_formatter: null,
                    filter_functions: null,
                    filter_hideEmpty: true,
                    filter_hideFilters: false,
                    filter_ignoreCase: true,
                    filter_liveSearch: true,
                    filter_onlyAvail: 'filter-onlyAvail',
                    filter_placeholder: { search: 'Filtre', select: '' },
                    filter_reset: 'button.reset',
                    filter_saveFilters: false,
                    filter_searchDelay: 300,
                    filter_searchFiltered: true,
                    filter_selectSource: null,
                    filter_serversideFiltering: false,
                    filter_startsWith: false,
                    filter_useParsedData: false,
                    filter_defaultAttrib: 'data-value',
                    filter_selectSourceSeparator: '|'
                }
            }).tablesorterPager({
                container: $('#mngPager'),
                cssGoto: ".pagenum",
                size: 10
            });
        });
    },
    tableSt: function() {
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: 'getSt' }, function(data) {
            $("#mngCont").empty().append(data);
        }).done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterSt')
            // initialize dragtable FIRST!
            .dragtable({
                // *** new dragtable mod options ***
                // this option MUST match the tablesorter selectorSort option!
                sortClass: '.sorter',
                // this function is called after everything has been updated
                tablesorterComplete: function(table) {},

                // *** original dragtable settings (non-default) ***
                dragaccept: '.drag-enable', // class name of draggable cols -> default null = all columns draggable

                // *** original dragtable settings (default values) ***
                revert: false, // smooth revert
                dragHandle: '.table-handle', // handle for moving cols, if not exists the whole 'th' is the handle
                maxMovingRows: 40, // 1 -> only header. 40 row should be enough, the rest is usually not in the viewport
                excludeFooter: false, // excludes the footer row(s) while moving other columns. Make sense if there is a footer with a colspan. */
                onlyHeaderThreshold: 100, // TODO:  not implemented yet, switch automatically between entire col moving / only header moving
                persistState: null, // url or function -> plug in your custom persistState function right here. function call is persistState(originalTable)
                restoreState: null, // JSON-Object or function:  some kind of experimental aka Quick-Hack TODO: do it better
                exact: true, // removes pixels, so that the overlay table width fits exactly the original table width
                clickDelay: 10, // ms to wait before rendering sortable list and delegating click event
                containment: null, // @see http://api.jqueryui.com/sortable/#option-containment, use it if you want to move in 2 dimesnions (together with axis: null)
                cursor: 'move', // @see http://api.jqueryui.com/sortable/#option-cursor
                cursorAt: false, // @see http://api.jqueryui.com/sortable/#option-cursorAt
                distance: 0, // @see http://api.jqueryui.com/sortable/#option-distance, for immediate feedback use "0"
                tolerance: 'pointer', // @see http://api.jqueryui.com/sortable/#option-tolerance
                axis: 'x', // @see http://api.jqueryui.com/sortable/#option-axis, Only vertical moving is allowed. Use 'x' or null. Use this in conjunction with the 'containment' setting
                beforeStart: $.noop, // returning FALSE will stop the execution chain.
                beforeMoving: $.noop,
                beforeReorganize: $.noop,
                beforeStop: $.noop
            })
            // initialize tablesorter
            .tablesorter({
                theme: 'dark',
                dateFormat: "ddmmyyyy", // set the default date format
                // hidden filter input/selects will resize the columns, so try to minimize the change
                widthFixed: true,
                // initialize zebra striping and filter widgets
                widgets: ["zebra", "filter"],
                ignoreCase: false,
                selectorSort: '.sorter',
                widgetOptions: {
                    // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                    filter_childRows: false,
                    filter_columnFilters: true,
                    filter_cellFilter: '',
                    filter_cssFilter: '', // or []
                    filter_defaultFilter: {},
                    filter_excludeFilter: {},
                    filter_external: '',
                    filter_filteredRow: 'filtered',
                    filter_formatter: null,
                    filter_functions: null,
                    filter_hideEmpty: true,
                    filter_hideFilters: false,
                    filter_ignoreCase: true,
                    filter_liveSearch: true,
                    filter_onlyAvail: 'filter-onlyAvail',
                    filter_placeholder: { search: 'Filtre', select: '' },
                    filter_reset: 'button.reset',
                    filter_saveFilters: false,
                    filter_searchDelay: 300,
                    filter_searchFiltered: true,
                    filter_selectSource: null,
                    filter_serversideFiltering: false,
                    filter_startsWith: false,
                    filter_useParsedData: false,
                    filter_defaultAttrib: 'data-value',
                    filter_selectSourceSeparator: '|'
                }
            }).tablesorterPager({
                container: $('#mngPager'),
                cssGoto: ".pagenum",
                size: 10
            });
        });
    },
    tableJust: function() {
        const req = 'getDriversDocuments';
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: req }, function(data) {
            $("#mngCont").empty().append(data);
        }).done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterCabs')
            // initialize dragtable FIRST!
            .dragtable({
                // *** new dragtable mod options ***
                // this option MUST match the tablesorter selectorSort option!
                sortClass: '.sorter',
                // this function is called after everything has been updated
                tablesorterComplete: function(table) {},

                // *** original dragtable settings (non-default) ***
                dragaccept: '.drag-enable', // class name of draggable cols -> default null = all columns draggable

                // *** original dragtable settings (default values) ***
                revert: false, // smooth revert
                dragHandle: '.table-handle', // handle for moving cols, if not exists the whole 'th' is the handle
                maxMovingRows: 40, // 1 -> only header. 40 row should be enough, the rest is usually not in the viewport
                excludeFooter: false, // excludes the footer row(s) while moving other columns. Make sense if there is a footer with a colspan. */
                onlyHeaderThreshold: 100, // TODO:  not implemented yet, switch automatically between entire col moving / only header moving
                persistState: null, // url or function -> plug in your custom persistState function right here. function call is persistState(originalTable)
                restoreState: null, // JSON-Object or function:  some kind of experimental aka Quick-Hack TODO: do it better
                exact: true, // removes pixels, so that the overlay table width fits exactly the original table width
                clickDelay: 10, // ms to wait before rendering sortable list and delegating click event
                containment: null, // @see http://api.jqueryui.com/sortable/#option-containment, use it if you want to move in 2 dimesnions (together with axis: null)
                cursor: 'move', // @see http://api.jqueryui.com/sortable/#option-cursor
                cursorAt: false, // @see http://api.jqueryui.com/sortable/#option-cursorAt
                distance: 0, // @see http://api.jqueryui.com/sortable/#option-distance, for immediate feedback use "0"
                tolerance: 'pointer', // @see http://api.jqueryui.com/sortable/#option-tolerance
                axis: 'x', // @see http://api.jqueryui.com/sortable/#option-axis, Only vertical moving is allowed. Use 'x' or null. Use this in conjunction with the 'containment' setting
                beforeStart: $.noop, // returning FALSE will stop the execution chain.
                beforeMoving: $.noop,
                beforeReorganize: $.noop,
                beforeStop: $.noop
            })
            // initialize tablesorter
            .tablesorter({
                theme: 'dark',
                dateFormat: "ddmmyyyy", // set the default date format
                // hidden filter input/selects will resize the columns, so try to minimize the change
                widthFixed: true,
                // initialize zebra striping and filter widgets
                widgets: ["zebra", "filter"],
                ignoreCase: false,
                selectorSort: '.sorter',
                widgetOptions: {
                    // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                    filter_childRows: false,
                    filter_columnFilters: true,
                    filter_cellFilter: '',
                    filter_cssFilter: '', // or []
                    filter_defaultFilter: {},
                    filter_excludeFilter: {},
                    filter_external: '',
                    filter_filteredRow: 'filtered',
                    filter_formatter: null,
                    filter_functions: null,
                    filter_hideEmpty: true,
                    filter_hideFilters: false,
                    filter_ignoreCase: true,
                    filter_liveSearch: true,
                    filter_onlyAvail: 'filter-onlyAvail',
                    filter_placeholder: { search: 'Filtre', select: '' },
                    filter_reset: 'button.reset',
                    filter_saveFilters: false,
                    filter_searchDelay: 300,
                    filter_searchFiltered: true,
                    filter_selectSource: null,
                    filter_serversideFiltering: false,
                    filter_startsWith: false,
                    filter_useParsedData: false,
                    filter_defaultAttrib: 'data-value',
                    filter_selectSourceSeparator: '|'
                }
            }).tablesorterPager({
                container: $('#mngPager'),
                cssGoto: ".pagenum",
                size: 10
            });
        });
        App.waterSize();
    },
    tableGroups: function() {
        $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: 'getGroups' }, function(data) {
            $("#mngCont").empty().append(data);
        }).done(function() {
            //$('table').addClass('tablesorter');
            $('.tablesorterGroups').tablesorter({
                theme: 'default',
                dateFormat: "ddmmyyyy", // set the default date format
                // hidden filter input/selects will resize the columns, so try to minimize the change
                widthFixed: true,
                // initialize zebra striping and filter widgets
                widgets: ["zebra", "filter"],
                ignoreCase: false,
                widgetOptions: {
                    // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                    filter_childRows: false,
                    filter_columnFilters: true,
                    filter_cellFilter: '',
                    filter_cssFilter: '', // or []
                    filter_defaultFilter: {},
                    filter_excludeFilter: {},
                    filter_external: '',
                    filter_filteredRow: 'filtered',
                    filter_formatter: null,
                    filter_functions: null,
                    filter_hideEmpty: true,
                    filter_hideFilters: false,
                    filter_ignoreCase: true,
                    filter_liveSearch: true,
                    filter_onlyAvail: 'filter-onlyAvail',
                    filter_placeholder: { search: 'Filtre', select: '' },
                    filter_reset: 'button.reset',
                    filter_saveFilters: false,
                    filter_searchDelay: 300,
                    filter_searchFiltered: true,
                    filter_selectSource: null,
                    filter_serversideFiltering: false,
                    filter_startsWith: false,
                    filter_useParsedData: false,
                    filter_defaultAttrib: 'data-value',
                    filter_selectSourceSeparator: '|'
                }
            }).tablesorterPager({
                container: $('#mngPager'),
                size: 20
            });
        });
    },
    changeCell: function(auto_tf, i, count, lead_id, doCheck, name_st, dptDepartCheck, codePostal_l, villeDepartCheck, city_l, dptArriveCheck, codePostal_dest_l, villeArriveCheck, city_dest_l, adresseDepartCheck, addr_l, adresseArriveCheck, addr_dest_l, date_cvCheck, date_cv, dep_datetime_1Check, dep_datetime_1, horairesArriveCheck, datefin_l, nomConducteurCheck, name_l, telConducteurCheck, tel_l, pilotesCheck, infosPilotes, categoriesCheck, cat_l, nbKm1, nbKm2, nbKm3) {
        //alert(count);
        let notSameCollumn = $("#tabFactBody").find("input");
        if (Object.values(notSameCollumn)[0] == 0) {
            let arrayPilotes = infosPilotes.split("(");
            let pilotes = arrayPilotes[0].substring(0, arrayPilotes[0].length - 1);
            if (doCheck != 'on') doCheck = 'off';
            if (dptDepartCheck != 'on') dptDepartCheck = 'off';
            if (villeDepartCheck != 'on') villeDepartCheck = 'off';
            if (dptArriveCheck != 'on') dptArriveCheck = 'off';
            if (villeArriveCheck != 'on') villeArriveCheck = 'off';
            if (adresseDepartCheck != 'on') adresseDepartCheck = 'off';
            if (adresseArriveCheck != 'on') adresseArriveCheck = 'off';
            if (date_cvCheck != 'on') date_cvCheck = 'off';
            if (dep_datetime_1Check != 'on') dep_datetime_1Check = 'off';
            if (horairesArriveCheck != 'on') horairesArriveCheck = 'off';
            if (nomConducteurCheck != 'on') nomConducteurCheck = 'off';
            if (telConducteurCheck != 'on') telConducteurCheck = 'off';
            if (pilotesCheck != 'on') pilotesCheck = 'off';
            if (categoriesCheck != 'on') categoriesCheck = 'off';
            let button = '<button id="triggerChangeCell" style="display:none" onclick="App.changeCellButton(' + auto_tf + ',' + i + ',' + count + ',\'' + lead_id + '\',\'' + doCheck + '\',\'' + dptDepartCheck + '\',\'' + villeDepartCheck + '\',\'' + dptArriveCheck + '\',\'' + villeArriveCheck + '\',\'' + adresseDepartCheck + '\',\'' + adresseArriveCheck + '\',\'' + date_cvCheck + '\',\'' + dep_datetime_1Check + '\',\'' + horairesArriveCheck + '\',\'' + nomConducteurCheck + '\',\'' + telConducteurCheck + '\',\'' + pilotesCheck + '\',\'' + categoriesCheck + '\',' + nbKm1 + ',' + nbKm2 + ',' + nbKm3 + ')"></button>';

            $('#divChangeCell').empty().append(button);
            if (doCheck == "on" && count > 1) {
                $('#doFact' + i + '').replaceWith('<td rowspan="2" id="doFact' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputDoFact' + i + '"></input></td>');
                $('#inputDoFact' + i + '').val(name_st);
            } else if (count == 1) {
                $('#doFact' + i + '').replaceWith('<td rowspan="2" id="doFact' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputDoFact' + i + '"></input></td>');
                $('#inputDoFact' + i + '').val(name_st);
            }
            if (dptDepartCheck == "on" && count > 1) {
                $('#dptDepart' + i + '').replaceWith('<td rowspan="2" id="dptDepart' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputDptDepart' + i + '"></input></td>');
                $('#inputDptDepart' + i + '').val(codePostal_l);
            } else if (count == 1) {
                $('#dptDepart' + i + '').replaceWith('<td rowspan="2" id="dptDepart' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputDptDepart' + i + '"></input></td>');
                $('#inputDptDepart' + i + '').val(codePostal_l);
            }
            if (villeDepartCheck == "on" && count > 1) {
                $('#villeDepart' + i + '').replaceWith('<td rowspan="2" id="villeDepart' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputVilleDepart' + i + '"></input></td>');
                $('#inputVilleDepart' + i + '').val(city_l);
            } else if (count == 1) {
                $('#villeDepart' + i + '').replaceWith('<td rowspan="2" id="villeDepart' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputVilleDepart' + i + '"></input></td>');
                $('#inputVilleDepart' + i + '').val(city_l);
            }
            if (dptArriveCheck == "on" && count > 1) {
                $('#dptArrive' + i + '').replaceWith('<td rowspan="2" id="dptArrive' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputDptArrive' + i + '"></input></td>');
                $('#inputDptArrive' + i + '').val(codePostal_dest_l);
            } else if (count == 1) {
                $('#dptArrive' + i + '').replaceWith('<td rowspan="2" id="dptArrive' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputDptArrive' + i + '"></input></td>');
                $('#inputDptArrive' + i + '').val(codePostal_dest_l);
            }
            if (villeArriveCheck == "on" && count > 1) {
                $('#villeArrive' + i + '').replaceWith('<td rowspan="2" id="villeArrive' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputVilleArrive' + i + '"></input></td>');
                $('#inputVilleArrive' + i + '').val(city_dest_l);
            } else if (count == 1) {
                $('#villeArrive' + i + '').replaceWith('<td rowspan="2" id="villeArrive' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputVilleArrive' + i + '"></input></td>');
                $('#inputVilleArrive' + i + '').val(city_dest_l);
            }
            if (adresseDepartCheck == "on" && count > 1) {
                $('#adresseDepart' + i + '').replaceWith('<td rowspan="2" id="adresseDepart' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputAdresseDepart' + i + '"></input></td>');
                $('#inputAdresseDepart' + i + '').val(addr_l);
            }
            if (adresseArriveCheck == "on" && count > 1) {
                $('#adresseArrive' + i + '').replaceWith('<td rowspan="2" id="adresseArrive' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputAdresseArrive' + i + '"></input></td>');
                $('#inputAdresseArrive' + i + '').val(addr_dest_l);
            }
            if (date_cvCheck == "on" && count > 1) {
                $('#date_cv' + i + '').replaceWith('<td rowspan="2" id="date_cv' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputDate_cv' + i + '"></input></td>');
                $('#inputDate_cv' + i + '').val(date_cv);
            }
            if (dep_datetime_1Check == "on" && count > 1) {
                $('#dep_datetime_1' + i + '').replaceWith('<td rowspan="2" id="dep_datetime_1' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputDep_datetime_1' + i + '"></input></td>');
                $('#inputDep_datetime_1' + i + '').val(dep_datetime_1);
            }
            if (horairesArriveCheck == "on" && count > 1) {
                $('#horairesArrive' + i + '').replaceWith('<td rowspan="2" id="horairesArrive' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputHorairesArrive' + i + '"></input></td>');
                $('#inputHorairesArrive' + i + '').val(datefin_l);
            } else if (count == 1) {
                $('#horairesArrive' + i + '').replaceWith('<td rowspan="2" id="horairesArrive' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputHorairesArrive' + i + '"></input></td>');
                $('#inputHorairesArrive' + i + '').val(datefin_l);
            }
            if (nomConducteurCheck == "on" && count > 1) {
                $('#nomConducteur' + i + '').replaceWith('<td rowspan="2" id="nomConducteur' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputNomConducteur' + i + '"></input></td>');
                $('#inputNomConducteur' + i + '').val(name_l);
            } else if (count == 1) {
                $('#nomConducteur' + i + '').replaceWith('<td rowspan="2" id="nomConducteur' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputNomConducteur' + i + '"></input></td>');
                $('#inputNomConducteur' + i + '').val(name_l);
            }
            if (telConducteurCheck == "on" && count > 1) {
                $('#telConducteur' + i + '').replaceWith('<td rowspan="2" id="telConducteur' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputTelConducteur' + i + '"></input></td>');
                $('#inputTelConducteur' + i + '').val(tel_l);
            }
            if (pilotesCheck == "on" && count > 1) {
                $('#pilotes' + i + '').replaceWith('<td rowspan="2" id="pilotes' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputPilotes' + i + '"></input></td>');
                $('#inputPilotes' + i + '').val(pilotes);
            } else if (count == 1) {
                $('#pilotes' + i + '').replaceWith('<td rowspan="2" id="pilotes' + i + '"><input type="text" style="height:10vh; width:10vh" id="inputPilotes' + i + '"></input></td>');
                $('#inputPilotes' + i + '').val(pilotes);
            }
            if (categoriesCheck == "on" && count > 1) {
                $('#categories' + i + '').replaceWith('<td rowspan="2" id="categories' + i + '"><input type="number" max="3" min="1" style="height:10vh; width:10vh" id="inputCategories' + i + '"></input></td>');
                $('#inputCategories' + i + '').val(cat_l);
            } else if (count == 1) {
                $('#categories' + i + '').replaceWith('<td rowspan="2" id="categories' + i + '"><input type="number" max="3" min="1" style="height:10vh; width:10vh" id="inputCategories' + i + '"></input></td>');
                $('#inputCategories' + i + '').val(cat_l);
            }
            $('#nbKm1_' + i + '').replaceWith('<td rowspan="2" id="nbKm1_' + i + '"><input type="number" min="0" style="height:10vh; width:10vh" id="inputNbKm1_' + i + '"></input></td>');
            $('#inputNbKm1_' + i + '').val(nbKm1);
            $('#nbKm2_' + i + '').replaceWith('<td rowspan="2" id="nbKm2_' + i + '"><input type="number" min="0"  style="height:10vh; width:10vh" id="inputNbKm2_' + i + '"></input></td>');
            $('#inputNbKm2_' + i + '').val(nbKm2);
            $('#nbKm3_' + i + '').replaceWith('<td rowspan="2" id="nbKm3_' + i + '"><input type="number" min="0"  style="height:10vh; width:10vh" id="inputNbKm3_' + i + '"></input></td>');
            $('#inputNbKm3_' + i + '').val(nbKm3);
        }
    },
    changeCellButton: function(auto_tf, i, count, lead_id, doCheck, dptDepartCheck, villeDepartCheck, dptArriveCheck, villeArriveCheck, adresseDepartCheck, adresseArriveCheck, date_cvCheck, dep_datetime_1Check, horairesArriveCheck, nomConducteurCheck, telConducteurCheck, pilotesCheck, categoriesCheck, nbKm1, nbKm2, nbKm3) {
        let Do = $('#inputDoFact' + i).val();
        let dptDepart = $('#inputDptDepart' + i).val();
        let villeDepart = $('#inputVilleDepart' + i).val();
        let dptArrive = $('#inputDptArrive' + i).val();
        let villeArrive = $('#inputVilleArrive' + i).val();
        let adresseDepart = $('#inputAdresseDepart' + i).val();
        let adresseArrive = $('#inputAdresseArrive' + i).val();
        let date_cv = $('#inputDate_cv' + i).val();
        let dep_datetime_1 = $('#inputDep_datetime_1' + i).val();
        let horairesArrive = $('#inputHorairesArrive' + i).val();
        let nomConducteur = $('#inputNomConducteur' + i).val();
        let telConducteur = $('#inputTelConducteur' + i).val();
        let pilotes = $('#inputPilotes' + i).val();
        let categories = $('#inputCategories' + i).val();
        nbKm1 = $('#inputNbKm1_' + i).val();
        nbKm2 = $('#inputNbKm2_' + i).val();
        nbKm3 = $('#inputNbKm3_' + i).val();
        let returns = '';
        if (auto_tf == 0) {
            // alert("auto_tf = 0");
            $.post(globals.serverAddress, {
                id: globals.id,
                admin: globals.admin,
                pwd: globals.pwd,
                count: count,
                lead_id: lead_id,
                doCheck: doCheck,
                do: Do,
                dptDepartCheck: dptDepartCheck,
                dptDepart: dptDepart,
                villeDepartCheck: villeDepartCheck,
                villeDepart: villeDepart,
                dptArriveCheck: dptArriveCheck,
                dptArrive: dptArrive,
                villeArriveCheck: villeArriveCheck,
                villeArrive: villeArrive,
                adresseDepartCheck: adresseDepartCheck,
                adresseDepart: adresseDepart,
                adresseArriveCheck: adresseArriveCheck,
                adresseArrive: adresseArrive,
                date_cvCheck: date_cvCheck,
                date_cv: date_cv,
                dep_datetime_1Check: dep_datetime_1Check,
                dep_datetime_1: dep_datetime_1,
                horairesArriveCheck: horairesArriveCheck,
                horairesArrive: horairesArrive,
                nomConducteurCheck: nomConducteurCheck,
                nomConducteur: nomConducteur,
                telConducteurCheck: telConducteurCheck,
                telConducteur: telConducteur,
                pilotesCheck: pilotesCheck,
                pilotes: pilotes,
                categoriesCheck: categoriesCheck,
                categories: categories,
                nbKm1: nbKm1,
                nbKm2: nbKm2,
                nbKm3: nbKm3,
                req: 'insertTabFactCell'
            }, function(data) {
                if (data.ok == "ok") {
                    returns = '<div class="alert alert-success" role="alert"><b>Les modifications ont bien été effectués</b></div>';
                } else {
                    returns = '<div class="alert alert-danger" role="alert"><b>Un problème est survenue lors de la modification</b></div>';
                }
                alert(data.ok)
                $("#updateTabFact").empty().append(returns);
            }, "json").done(function() {
                App.tableFact();
            });
        }
        else {
            $.post(globals.serverAddress, {
                id: globals.id,
                admin: globals.admin,
                pwd: globals.pwd,
                count: count,
                lead_id: lead_id,
                doCheck: doCheck,
                do: Do,
                dptDepartCheck: dptDepartCheck,
                dptDepart: dptDepart,
                villeDepartCheck: villeDepartCheck,
                villeDepart: villeDepart,
                dptArriveCheck: dptArriveCheck,
                dptArrive: dptArrive,
                villeArriveCheck: villeArriveCheck,
                villeArrive: villeArrive,
                adresseDepartCheck: adresseDepartCheck,
                adresseDepart: adresseDepart,
                adresseArriveCheck: adresseArriveCheck,
                adresseArrive: adresseArrive,
                date_cvCheck: date_cvCheck,
                date_cv: date_cv,
                dep_datetime_1Check: dep_datetime_1Check,
                dep_datetime_1: dep_datetime_1,
                horairesArriveCheck: horairesArriveCheck,
                horairesArrive: horairesArrive,
                nomConducteurCheck: nomConducteurCheck,
                nomConducteur: nomConducteur,
                telConducteurCheck: telConducteurCheck,
                telConducteur: telConducteur,
                pilotesCheck: pilotesCheck,
                pilotes: pilotes,
                categoriesCheck: categoriesCheck,
                categories: categories,
                nbKm1: nbKm1,
                nbKm2: nbKm2,
                nbKm3: nbKm3,
                req: 'updateTabFactCell'
            }, function(data) {
                if (data.ok == "ok") {
                    returns = '<div class="alert alert-success" role="alert"><b>Les modifications ont bien été effectués</b></div>';
                } else {
                    returns = '<div class="alert alert-danger" role="alert"><b>Un problème est survenue lors de la modification</b></div>';
                }
                alert(data.ok);
                $("#updateTabFact").empty().append(returns);
            }, "json").done(function() {
                App.tableFact();
            });
        }
    },
    checkAll: function(myFormId) {

        $("#chooseTableFact #a").replaceWith('<input type="checkbox" class="custom-control-input" id="a" name="A" checked>')
        $("#chooseTableFact #m").replaceWith('<input type="checkbox" class="custom-control-input" id="m" name="M" checked>')
        $("#chooseTableFact #s").replaceWith('<input type="checkbox" class="custom-control-input" id="s" name="S" checked>')
        $("#chooseTableFact #n").replaceWith('<input type="checkbox" class="custom-control-input" id="n" name="N" checked>')
        $("#chooseTableFact #do").replaceWith('<input type="checkbox" class="custom-control-input" id="do" name="DO" checked>')
        $("#chooseTableFact #villeDepart").replaceWith('<input type="checkbox" class="custom-control-input" id="villeDepart" name="villeDepart" checked>')
        $("#chooseTableFact #dptArrive").replaceWith('<input type="checkbox" class="custom-control-input" id="dptArrive" name="dptArrive" checked>')
        $("#chooseTableFact #villeArrive").replaceWith('<input type="checkbox" class="custom-control-input" id="villeArrive" name="villeArrive" checked>')
        $("#chooseTableFact #adresseDepart").replaceWith('<input type="checkbox" class="custom-control-input" id="adresseDepart" name="adresseDepart" checked>')
        $("#chooseTableFact #adresseArrive").replaceWith('<input type="checkbox" class="custom-control-input" id="adresseArrive" name="adresseArrive" checked>')
        $("#chooseTableFact #date_cvCheck").replaceWith('<input type="checkbox" class="custom-control-input" id="date_cvCheck" name="date_cvCheck" checked>')
        $("#chooseTableFact #dep_datetime_1Check").replaceWith('<input type="checkbox" class="custom-control-input" id="dep_datetime_1Check" checked>')
        $("#chooseTableFact #horairesArrive").replaceWith('<input type="checkbox" class="custom-control-input" id="horairesArrive" name="horairesArrive" checked>')
        $("#chooseTableFact #nomConducteur").replaceWith('<input type="checkbox" class="custom-control-input" id="nomConducteur" name="nomConducteur" checked>')

        $("#chooseTableFact #telConducteur").replaceWith('<input type="checkbox" class="custom-control-input" id="telConducteur" name="telConducteur" checked>')
        $("#chooseTableFact #pilotes").replaceWith('<input type="checkbox" class="custom-control-input" id="pilotes" name="pilotes" checked>')
        $("#chooseTableFact #categories").replaceWith('<input type="checkbox" class="custom-control-input" id="categories" name="categories" checked>')
        $("#chooseTableFact #type").replaceWith('<input type="checkbox" class="custom-control-input" id="type" name="type" checked>')
    },
    chooseTableFact: function(myFormDiv) {
        let query = $(myFormDiv).serialize();
        let req = "getFact";
        console.log(query);
        query = query + "&id=" + globals.id + "&admin=" + globals.admin + "&pwd=" + globals.pwd + "&req=" + req;
        if (globals.admin == "true") {
            $.post(globals.serverAddress, query, function(data) {
                $("#factCont").empty().append(data);
            }).done(function() {
                $('.tablesorterFact')
                // initialize dragtable FIRST!
                .dragtable({
                    // *** new dragtable mod options ***
                    // this option MUST match the tablesorter selectorSort option!
                    sortClass: '.sorter',
                    // this function is called after everything has been updated
                    tablesorterComplete: function(table) {},

                    // *** original dragtable settings (non-default) ***
                    dragaccept: '.drag-enable', // class name of draggable cols -> default null = all columns draggable

                    // *** original dragtable settings (default values) ***
                    revert: false, // smooth revert
                    dragHandle: '.table-handle', // handle for moving cols, if not exists the whole 'th' is the handle
                    maxMovingRows: 40, // 1 -> only header. 40 row should be enough, the rest is usually not in the viewport
                    excludeFooter: false, // excludes the footer row(s) while moving other columns. Make sense if there is a footer with a colspan. */
                    onlyHeaderThreshold: 100, // TODO:  not implemented yet, switch automatically between entire col moving / only header moving
                    persistState: null, // url or function -> plug in your custom persistState function right here. function call is persistState(originalTable)
                    restoreState: null, // JSON-Object or function:  some kind of experimental aka Quick-Hack TODO: do it better
                    exact: true, // removes pixels, so that the overlay table width fits exactly the original table width
                    clickDelay: 10, // ms to wait before rendering sortable list and delegating click event
                    containment: null, // @see http://api.jqueryui.com/sortable/#option-containment, use it if you want to move in 2 dimesnions (together with axis: null)
                    cursor: 'move', // @see http://api.jqueryui.com/sortable/#option-cursor
                    cursorAt: false, // @see http://api.jqueryui.com/sortable/#option-cursorAt
                    distance: 0, // @see http://api.jqueryui.com/sortable/#option-distance, for immediate feedback use "0"
                    tolerance: 'pointer', // @see http://api.jqueryui.com/sortable/#option-tolerance
                    axis: 'x', // @see http://api.jqueryui.com/sortable/#option-axis, Only vertical moving is allowed. Use 'x' or null. Use this in conjunction with the 'containment' setting
                    beforeStart: $.noop, // returning FALSE will stop the execution chain.
                    beforeMoving: $.noop,
                    beforeReorganize: $.noop,
                    beforeStop: $.noop
                })
                // initialize tablesorter
                .tablesorter({
                    theme: 'dark',
                    dateFormat: "ddmmyyyy", // set the default date format
                    // hidden filter input/selects will resize the columns, so try to minimize the change
                    widthFixed: true,
                    // initialize zebra striping and filter widgets
                    widgets: ["zebra", "filter"],
                    ignoreCase: false,
                    selectorSort: '.sorter',
                    widgetOptions: {
                        // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                        filter_childRows: false,
                        filter_columnFilters: true,
                        filter_cellFilter: '',
                        filter_cssFilter: '', // or []
                        filter_defaultFilter: {},
                        filter_excludeFilter: {},
                        filter_external: '',
                        filter_filteredRow: 'filtered',
                        filter_formatter: null,
                        filter_functions: null,
                        filter_hideEmpty: true,
                        filter_hideFilters: false,
                        filter_ignoreCase: true,
                        filter_liveSearch: true,
                        filter_onlyAvail: 'filter-onlyAvail',
                        filter_placeholder: { search: 'Filtre', select: '' },
                        filter_reset: 'button.reset',
                        filter_saveFilters: false,
                        filter_searchDelay: 300,
                        filter_searchFiltered: true,
                        filter_selectSource: null,
                        filter_serversideFiltering: false,
                        filter_startsWith: false,
                        filter_useParsedData: false,
                        filter_defaultAttrib: 'data-value',
                        filter_selectSourceSeparator: '|'
                    }
                }).tablesorterPager({
                    container: $('#mngPager'),
                    cssGoto: ".pagenum",
                    size: 10
                });
            });
        }
    },
    tableFact: function() {
        if (globals.admin == "true") {
            $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: 'getFact' }, function(data) {
                $("#factCont").empty().append(data);
            }).done(function() {
                $('.tablesorterFact')
                // initialize dragtable FIRST!
                .dragtable({
                    // *** new dragtable mod options ***
                    // this option MUST match the tablesorter selectorSort option!
                    sortClass: '.sorter',
                    // this function is called after everything has been updated
                    tablesorterComplete: function(table) {},

                    // *** original dragtable settings (non-default) ***
                    dragaccept: '.drag-enable', // class name of draggable cols -> default null = all columns draggable

                    // *** original dragtable settings (default values) ***
                    revert: false, // smooth revert
                    dragHandle: '.table-handle', // handle for moving cols, if not exists the whole 'th' is the handle
                    maxMovingRows: 40, // 1 -> only header. 40 row should be enough, the rest is usually not in the viewport
                    excludeFooter: false, // excludes the footer row(s) while moving other columns. Make sense if there is a footer with a colspan. */
                    onlyHeaderThreshold: 100, // TODO:  not implemented yet, switch automatically between entire col moving / only header moving
                    persistState: null, // url or function -> plug in your custom persistState function right here. function call is persistState(originalTable)
                    restoreState: null, // JSON-Object or function:  some kind of experimental aka Quick-Hack TODO: do it better
                    exact: true, // removes pixels, so that the overlay table width fits exactly the original table width
                    clickDelay: 10, // ms to wait before rendering sortable list and delegating click event
                    containment: null, // @see http://api.jqueryui.com/sortable/#option-containment, use it if you want to move in 2 dimesnions (together with axis: null)
                    cursor: 'move', // @see http://api.jqueryui.com/sortable/#option-cursor
                    cursorAt: false, // @see http://api.jqueryui.com/sortable/#option-cursorAt
                    distance: 0, // @see http://api.jqueryui.com/sortable/#option-distance, for immediate feedback use "0"
                    tolerance: 'pointer', // @see http://api.jqueryui.com/sortable/#option-tolerance
                    axis: 'x', // @see http://api.jqueryui.com/sortable/#option-axis, Only vertical moving is allowed. Use 'x' or null. Use this in conjunction with the 'containment' setting
                    beforeStart: $.noop, // returning FALSE will stop the execution chain.
                    beforeMoving: $.noop,
                    beforeReorganize: $.noop,
                    beforeStop: $.noop
                })
                // initialize tablesorter
                .tablesorter({
                    theme: 'dark',
                    dateFormat: "ddmmyyyy", // set the default date format
                    // hidden filter input/selects will resize the columns, so try to minimize the change
                    widthFixed: true,
                    // initialize zebra striping and filter widgets
                    widgets: ["zebra", "filter"],
                    ignoreCase: false,
                    selectorSort: '.sorter',
                    widgetOptions: {
                        // See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
                        filter_childRows: false,
                        filter_columnFilters: true,
                        filter_cellFilter: '',
                        filter_cssFilter: '', // or []
                        filter_defaultFilter: {},
                        filter_excludeFilter: {},
                        filter_external: '',
                        filter_filteredRow: 'filtered',
                        filter_formatter: null,
                        filter_functions: null,
                        filter_hideEmpty: true,
                        filter_hideFilters: false,
                        filter_ignoreCase: true,
                        filter_liveSearch: true,
                        filter_onlyAvail: 'filter-onlyAvail',
                        filter_placeholder: { search: 'Filtre', select: '' },
                        filter_reset: 'button.reset',
                        filter_saveFilters: false,
                        filter_searchDelay: 300,
                        filter_searchFiltered: true,
                        filter_selectSource: null,
                        filter_serversideFiltering: false,
                        filter_startsWith: false,
                        filter_useParsedData: false,
                        filter_defaultAttrib: 'data-value',
                        filter_selectSourceSeparator: '|'
                    }
                }).tablesorterPager({
                    container: $('#factPager'),
                    cssGoto: ".pagenum",
                    size: 10
                });
            });

        }
        App.waterSize();
    },
    grilleTarifaireConst: function() {
        if (globals.admin == "true") {
-            $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: 'getGrilleTarifaireConst' }, function(data) {
                $("#factCont").empty().append(data);
            });
        }
    },
    grilleTarifaireDegrePilote: function() {
        if (globals.admin == "true") {
            $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: 'grilleTarifaireDegrePilote' }, function(data) {
                $("#factCont").empty().append(data);
            });
        }
    },
    grilleTarifaireDegreGuideur: function() {
        if (globals.admin == "true") {
            $.post(globals.serverAddress, { id: globals.id, admin: globals.admin, pwd: globals.pwd, req: 'grilleTarifaireDegreGuideur' }, function(data) {
                $("#factCont").empty().append(data);
            });
        }
    },
    subMenuActive: function(id) {
        $('.sub-menu').find('a').filter(".in").removeClass('in');
        $(id).addClass('in');
    },
    waterSize: function() {
        //$( "#infos" ).removeClass('wrap-right').toggle( 'explode', {}, 1000 ).addClass('wrap-top').toggle( 'puff', {}, 1000 );
        $("#infos").removeClass('wrap-right').removeClass('wrap-minimal').addClass('wrap-top');
        let el = $('#infos'),
            curHeight = el.height(),
            autoHeight = el.css('height', 'auto').height();
    },
    toggleThis: function(toToggle) {
        $(toToggle).slideToggle();
    },
    originalSize: function() {
        //$( "#infos" ).removeClass('wrap-top').hide('fast').addClass('wrap-right').show('fast');
        $("#infos").removeClass('wrap-top').removeClass('wrap-minimal').addClass('wrap-right');
        let el = $('#infos'),
            curHeight = el.height(),
            autoHeight = el.css('height', 'auto').height();
    },
    displayAll: function() {
        App.clearMap();
        $.get(globals.serverAddress, { id: globals.id, admin: globals.admin, all: true, req: 'displayAll' }, function(xml) {
            //$('#infos').empty().append(xml);
            let i = 0;
            markers = new L.FeatureGroup();
            $(xml).find('marker').each(function() {
                let name = $(this).attr('name');
                let address = $(this).attr('address');
                let cablat = $(this).attr('lat');
                let cablng = $(this).attr('lng');
                let timestamp = $(this).attr('timestamp');
                let distance = $(this).attr('distance');
                i = $(this).attr('count');
                //$('#infos').empty().append(name+' - '+address+' - '+cablat+' - '+cablng+' - '+timestamp+' - '+distance+' - '+i+' - '+num_req+' - '+latlng);
                let lat = parseFloat(cablat);
                let lng = parseFloat(cablng);
                let latlng = [lat, lng];
                let display_name = name + ' ' + address;
                //map.setView(L.LatLng(lat,lng), globals.defZoom);
                //map.setView(latlng, globals.defZoom);
                markers_id[i] = L.marker(latlng).addTo(map).bindPopup('<p><b>' + display_name + '<br>' + timestamp + '</b></p>', { showOnMouseOver: true });
                if (i == 1) markers_id[i].openPopup();
                markers.addLayer(markers_id[i]);
                App.createTimedOption(display_name, timestamp, i);
            });
            if (i == 0) alert('Aucun pilote disponible !!');
            else {
                map.fitBounds(markers.getBounds(), {
                    padding: [50, 50]
                });
                $('.locsel').show();
                locationSelect.onchange = function() {
                    let markerNum = locationSelect.options[locationSelect.selectedIndex].value;
                    markers_id[markerNum].openPopup();
                };
            }
            $("#dispoCabs").empty().append(i);
        });
    },
    createOption: function(name, distance, num) {
        let option = document.createElement("option");
        option.value = num;
        option.innerHTML = name + "(<b>Est &agrave; " + parseFloat(distance).toFixed(1) + "Km du client</b>)";
        locationSelect.appendChild(option);
    },
    createTimedOption: function(name, time, num) {
        let option = document.createElement("option");
        option.value = num;
        option.innerHTML = name + "(<b>position datant de: " + time + "</b>)";
        locationSelect.appendChild(option);
    },
    //below taken from http://www.howtocreate.co.uk/tutorials/javascript/browserwindow
    getScrollXY: function() {
        let scrOfX = 0,
            scrOfY = 0;
        if (typeof(window.pageYOffset) == 'number') {
            //Netscape compliant
            scrOfY = window.pageYOffset;
            scrOfX = window.pageXOffset;
        } else if (document.body && (document.body.scrollLeft || document.body.scrollTop)) {
            //DOM compliant
            scrOfY = document.body.scrollTop;
            scrOfX = document.body.scrollLeft;
        } else if (document.documentElement && (document.documentElement.scrollLeft || document.documentElement.scrollTop)) {
            //IE6 standards compliant mode
            scrOfY = document.documentElement.scrollTop;
            scrOfX = document.documentElement.scrollLeft;
        }
        return [scrOfX, scrOfY];
    },

    //taken from http://james.padolsey.com/javascript/get-document-height-cross-browser/
    getDocHeight: function() {
        let D = document;
        return Math.max(
            D.body.scrollHeight, D.documentElement.scrollHeight,
            D.body.offsetHeight, D.documentElement.offsetHeight,
            D.body.clientHeight, D.documentElement.clientHeight
        );
    }
};

// Expose App object to window object => need this to use click event in html
window.App = App;
require('./router.js');

(function() {

	// Force https if not in local/dev
    // const myDomain = window.location.origin; // https://my.domain
    // if(myDomain.indexOf('localhost')===-1 && window.location.protocol == 'http:') window.location.href = window.location.href.replace('http:', 'https:');
    if ($.localStorage.getItem('pass') != 'OK' && (document.URL.indexOf('manager') !== -1 || document.URL.indexOf('bourse') !== -1)) {
        document.location.href = '/';
    }
    App.init();
    new WOW().init();

})();